import "./App.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Algo from "./Components/Algo/Algo";
import Copytrade from "./Components/Copytrade/Copytrade";
import Negativebb from "./Components/Negativebb/Negativebb";
import Mam from "./Components/Mam/Mam";
import Pamm from "./Components/Pamm/Pamm";
import Risk from "./Components/Risk/Risk";
import Whitelabel from "./Components/Whitelabel/Whitelabel";
import Aboutus from "./Components/Aboutus/Aboutus";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReactDOM from "react-dom";
import Contactus from "./Components/Contactus/Contactus";
import Ecnomiccal from "./Components/Ecnomiccal/Ecnomiccal";
import Faq from "./Components/Faq/Faq";
import Legaldoc from "./Components/Legaldoc/Legaldoc";
import Whychoose from "./Components/Whychoose/Whychoose";
import Mt5trade from "./Components/Mt5trade/Mt5trade";
import Introbroker from "./Components/Introbroker/Introbroker";
import Marketanalytical from "./Components/Marketanalytical/Marketanalytical";
import Promotions from "./Components/Promotions/Promotions";
import Pipcalc from "./Components/Pipcalc/Pipcalc";
import Affiliate from "./Components/Affiliate/Affiliate";
import Asstes from "./Components/Assets/Assets";
import Bonus from "./Components/Bonus/Bonus";
import Depositwi from "./Components/Deposit-withdraw/Depositwi";
import Liveacc from "./Components/Liveacc/Liveacc";
import Demoacc from "./Components/Demoacc/Demoacc";
import Signin from "./Components/Signin/Signin";
import Segregate from "./Components/Segregate/Segregate";
import Trading from "./Components/Trading/Trading";
import Tradingfees from "./Components/Tradingfees/Tradingfees";
import Faq2 from "./Components/Faq2/Faq2";
import Accountype from "./Components/Accountype/Accountype";
import Cryptocurrencies from "./Components/Cryptocurrencies/Cryptocurrencies";
import Indices from "./Components/Indices/Indices";
import Shares from "./Components/Shares/Shares";
import Currency from "./Components/Currency/Currency";
import Commodities from "./Components/Commodities/Commodities";
import Blog from "./Components/Blog/Blog";
import Blog1 from "./Components/Blog/Blog1";
import Forexcurrency from "../src/Components/Marketanalytical/Forexcurrency";
import Lotsize from "../src/Components/Pipcalc/Lotsize";
import ProfitCalc from "../src/Components/Pipcalc/Profitcalc";
import Notfound from "./Components/Faq/Error404/Errorpage";
import React, { useEffect, useState } from "react";
import Loader from "./Components/Loader/Loader";
import { render } from "react-dom";
import IBoffer from "./Components/IBoffer/IBoffer";
import IBpromotion from "./Components/IBPromotions/IBpromotion";
import RoyaltyProgram from "./Components/RoyaltyProgram/LoyaltyProgram";
import LoyaltyProgram from "./Components/RoyaltyProgram/LoyaltyProgram";
import Products from "./Components/Products/Products";
import Cart from "./Components/Cart/Cart";
import Checkout from "./Components/Checkout/Checkout";
import Refundpolicy from "./Components/Refundpolicy/Refundpolicy";
// const Home = React.lazy(() => import("./Components/Home/Home"));

const Home = React.lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./Components/Home/Home")), 1000);
  });
});

function App() {
  const [errorpage, setError] = useState(true);
  const [loaded,setLoaded] =useState(false)


  useEffect(() => {
    if (window.location.pathname.includes("notfound")) {
      setError(false);
    } else {
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("blog1") || window.location.pathname.includes("blog") ) {
     window.location.href='https://blog.milliva.com/'
     setError(false);
    } else {
      setError(true);
    }
  }, []);
  useEffect(() => {
    if (window.location.pathname.includes("*")) {
      setLoaded(false);
    } else {
      setLoaded(true);
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        {/* <React.Suspense fallback={<Loader />}> */}
         {loaded ? <>{errorpage ? <Header /> : <></>}</> : <></>}
          
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/algo" element={<Algo />} />
            <Route path="/copytrade" element={<Copytrade />} />
            <Route path="/negativebb" element={<Negativebb />} />
            <Route path="/mam" element={<Mam />} />
            <Route path="/pamm" element={<Pamm />} />
            <Route path="/risk" element={<Risk />} />
            <Route path="/whitelabel" element={<Whitelabel />} />
            <Route path="/about" element={<Aboutus />} />
            <Route path="/contact" element={<Contactus />} />
            <Route path="/ecnomic" element={<Ecnomiccal />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/legal" element={<Legaldoc />} />
            <Route path="/whychoose" element={<Whychoose />} />
            <Route path="/mt5" element={<Mt5trade />} />
            <Route path="/introbroker" element={<Introbroker />} />
            <Route path="/market" element={<Marketanalytical />} />
            <Route path="/promotions" element={<Promotions />} />
            <Route path="/pipcalc" element={<Pipcalc />} />
            <Route path="/pipcalc/:lotsize" element={<Pipcalc />} />
            <Route path="/affiliate" element={<Affiliate />} />
            <Route path="/assets" element={<Asstes />} />
            <Route path="/bonus" element={<Bonus />} />
            <Route path="/deposit" element={<Depositwi />} />
            <Route path="/liveacc" element={<Liveacc />} />
            <Route path="/demoacc" element={<Demoacc />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/segregate" element={<Segregate />} />
            <Route path="/trading" element={<Trading />} />
            <Route path="/tradingfee" element={<Tradingfees />} />
            <Route path="/faq2" element={<Faq2 />} />
            <Route path="/account" element={<Accountype />} />
            <Route path="/crypto" element={<Cryptocurrencies />} />
            <Route path="/indices" element={<Indices />} />
            <Route path="/shares" element={<Shares />} />
            <Route exact path="/currency" element={<Currency />} />
            <Route path="/commodities" element={<Commodities />} />
            <Route path="/blog" element={''} />
            <Route path="/forexcurrency" element={<Forexcurrency />} />
            <Route path="/blog1" element={''} />
            <Route path="/lotsize" element={<Lotsize />} />
            <Route path="/profit" element={<ProfitCalc />} />
            {/* <Route path="/notfound" element={<Notfound />} /> */}
            <Route path="/iboffer" element={<IBoffer />} />
            <Route path="/ibpromotion" element={<IBpromotion />} />
            <Route path="/loyal" element={<LoyaltyProgram />} />
            {/* <Route path="/products" element={<Products />} /> */}
            <Route path="/cart" element={<Cart />} />
            <Route path="/cart/:id" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout/:id" element={<Checkout />} />
          <Route path="/refund" element={<Refundpolicy />} />
            <Route path="*" element={<Navigate to={"/"} />} />

          </Routes>

          {loaded ? <>{errorpage ? <Footer /> : <></>}</> : <></>}
        {/* </React.Suspense> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
