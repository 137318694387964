import React, { useEffect, useState } from 'react'
import '../../assets/css/checkout.css'
import { useForm } from 'react-hook-form';
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useParams } from 'react-router-dom';
import Common from '../../Helper/Encrypt';
import ProductsList from '../../assets/documents/ProductsList';


const Checkout = () => {
    const { id } = useParams()
    // const [decryptData, SetdecryptData] = useState(null)
    const [Data, SetData] = useState(null)
    const [CartData, SetCartData] = useState(null)

    useEffect(() => {
        decryptFunc()
        CartLoad()
    }, [id])

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = (data) => {
        if (data) {
            NotificationManager.success('Comming Soon', "Success");
            reset()
        }
    };
    async function decryptFunc() {
        let productParamId = await Common.DecodeText(id)
        let ProductData = ProductsList.find(e => e.productId == productParamId)
        SetData(ProductData)

    }

    async function CartLoad() {
        let CartDetils = await localStorage.getItem('cartDetails')
        SetCartData(JSON.parse(CartDetils))
        // console.log(JSON.parse(CartDetils))
    }
    return (
        <div className='checkout-section'>
            <NotificationContainer />
            <div className="container">
                <div className="py-5 text-center">
                    <h2>Checkout form</h2>
                    <p>
                        Below is an example form built entirely with Bootstrap’s form controls.
                        Each required form group has a validation state that can be triggered by
                        attempting to submit the form without completing it.
                    </p>
                </div>
                <div className="row">
                    <div className="col-md-4 order-md-2 mb-3">
                        <h4 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-muted">Your cart</span>
                            <span className="badge badge-secondary badge-pill">{CartData?.length}</span>
                        </h4>

                        <ul className="list-group mb-3">
                            <li className="list-group-item d-flex justify-content-between lh-condensed">
                                <div>
                                    <h6 className="my-0">Product name</h6>
                                    <small className="text-muted">{Data?.descrption}</small>
                                </div>
                                <span className="text-muted">{Data?.tittle}</span>
                            </li>
                            {/* <li className="list-group-item d-flex justify-content-between lh-condensed">
                                <div>
                                    <h6 className="my-0">Second product</h6>
                                    <small className="text-muted">Brief description</small>
                                </div>
                                <span className="text-muted">$8</span>
                            </li> */}
                            {/* <li className="list-group-item d-flex justify-content-between lh-condensed">
                                <div>
                                    <h6 className="my-0">Third item</h6>
                                    <small className="text-muted">Brief description</small>
                                </div>
                                <span className="text-muted">$5</span>
                            </li> */}
                            {/* <li className="list-group-item d-flex justify-content-between bg-light">
                                <div className="text-success">
                                    <h6 className="my-0">Promo code</h6>
                                    <small>EXAMPLECODE</small>
                                </div>
                                <span className="text-success">-$5</span>
                            </li> */}
                            <li className="list-group-item d-flex justify-content-between">
                                <span>Total (USD)</span>
                                <strong>{(Data?.price)}.00</strong>
                            </li>
                        </ul>

                        {/* <form className="card p-2">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Promo code"
                                />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-secondary">
                                        Redeem
                                    </button>
                                </div>
                            </div>
                        </form> */}
                        <button className="btn btn-primary btn-lg btn-block placebtn" type="button" onClick={handleSubmit(onSubmit)}>
                            PlaceOrder
                        </button>
                    </div>


                    <div className="col-md-8 order-md-1">
                        <h4 className="mb-3">Billing address</h4>
                        <form className="needs-validation" noValidate="" >
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="firstName">First name</label>
                                    <input
                                        type="text"
                                        className={'form-control'}
                                        placeholder=""
                                        defaultValue=""
                                        required=""
                                        {...register("firstName", {
                                            required: {
                                                value: true,
                                                message: "Valid first name is required.",
                                            },
                                        })}
                                    />
                                    <p className="text-danger">
                                        {errors.firstName && (
                                            <span role="alert">{errors.firstName.message}</span>
                                        )}
                                    </p>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <label htmlFor="lastName">Last name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="lastName"
                                        placeholder=""
                                        defaultValue=""
                                        required=""
                                        {...register("lastName", {
                                            required: {
                                                value: true,
                                                message: "Valid last name is required.",
                                            },
                                        })}
                                    />
                                    <p className="text-danger">
                                        {errors.lastName && (
                                            <span role="alert">{errors.lastName.message}</span>
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="username">Username</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">@</span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="username"
                                        placeholder="Username"
                                        required=""
                                        {...register("username", {
                                            required: {
                                                value: true,
                                                message: "Username is required.",
                                            },
                                        })}
                                    />
                                    <div className="text-danger" style={{ width: "100%" }}>
                                        {errors.username && (
                                            <span role="alert">{errors.username.message}</span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="email">
                                    Email <span className="text-muted">(Optional)</span>
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="you@example.com"
                                    {...register("email", {
                                        pattern: {
                                            value: /^\S+@\S+$/i,
                                            message: "Invalid email address",
                                        },
                                    })}
                                />
                                <div className="text-danger">
                                    {errors.email && (
                                        <span role="alert">{errors.email.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address">Address</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    placeholder="1234 Main St"
                                    required=""
                                    {...register("address", {
                                        required: {
                                            value: true,
                                            message: "Address is required.",
                                        },
                                    })}
                                />
                                <div className="text-danger">
                                    {errors.address && (
                                        <span role="alert">{errors.address.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="address2">
                                    Address 2 <span className="text-muted">(Optional)</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address2"
                                    placeholder="Apartment or suite"
                                    {...register("address2", {
                                        required: {
                                            value: true,
                                            message: "Address2 is required.",
                                        },
                                    })}
                                />
                                <div className="text-danger">
                                    {errors.address2 && (
                                        <span role="alert">{errors.address2.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5 mb-3">
                                    <label htmlFor="country">Country</label>
                                    <select
                                        className="custom-select d-block w-100"
                                        id="country"
                                        required=""
                                        {...register("country", {
                                            required: {
                                                value: true,
                                                message: "Country is required.",
                                            },
                                        })}
                                    >
                                        <option value="">Choose...</option>
                                        <option>United States</option>
                                    </select>
                                    <div className="text-danger">
                                        {errors.country && (
                                            <span role="alert">{errors.country.message}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="state">State</label>
                                    <select
                                        className="custom-select d-block w-100"
                                        id="state"
                                        required=""
                                        {...register("state", {
                                            required: {
                                                value: true,
                                                message: "State is required.",
                                            },
                                        })}
                                    >
                                        <option value="">Choose...</option>
                                        <option>California</option>
                                    </select>
                                    <div className="text-danger">
                                        {errors.state && (
                                            <span role="alert">{errors.state.message}</span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="zip">Zip</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="zip"
                                        placeholder=""
                                        required=""
                                        {...register("zip", {
                                            required: {
                                                value: true,
                                                message: "Zip code is required.",
                                            },
                                        })}
                                    />
                                    <div className="text-danger">
                                        {errors.zip && (
                                            <span role="alert">{errors.zip.message}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr className="mb-3" />
                            <div className="custom-control custom-checkbox mb-2">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="same-address"
                                />
                                <label className="custom-control-label mx-2" htmlFor="same-address">
                                    Shipping address is the same as my billing address
                                </label>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="save-info"
                                />
                                <label className="custom-control-label mx-2" htmlFor="save-info">
                                    Save this information for next time
                                </label>
                            </div>
                            <hr className="mb-3" />
                            <h4 className="mb-2">Payment</h4>
                            <div className="d-block my-2">
                                <div className="custom-control custom-radio">
                                    <input
                                        id="credit"
                                        name="paymentMethod"
                                        type="radio"
                                        className="custom-control-input"
                                        required=""
                                        {...register("paymentMethod", {
                                            required: "Please select a payment method.",
                                        })}
                                    />
                                    <label className="custom-control-label mx-2" htmlFor="credit">
                                        Credit card
                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input
                                        id="debit"
                                        name="paymentMethod"
                                        type="radio"
                                        className="custom-control-input"
                                        required=""
                                        {...register("paymentMethod", {
                                            required: "Please select a payment method.",
                                        })}
                                    />
                                    <label className="custom-control-label mx-2" htmlFor="debit">
                                        Debit card
                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input
                                        id="paypal"
                                        name="paymentMethod"
                                        type="radio"
                                        className="custom-control-input"
                                        required=""
                                        {...register("paymentMethod", {
                                            required: "Please select a payment method.",
                                        })}
                                    />
                                    <label className="custom-control-label mx-2" htmlFor="paypal">
                                        PayPal
                                    </label>
                                </div>
                                {errors.paymentMethod && (
                                    <p className="text-danger">{errors.paymentMethod.message}</p>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="cc-name">Name on card</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cc-name"
                                        placeholder=""
                                        required=""
                                        {...register("ccName", {
                                            required: "Name on card is required.",
                                        })}
                                    />
                                    <small className="text-muted">Full name as displayed on card</small>
                                    {errors.ccName && (
                                        <p className="text-danger">{errors.ccName.message}</p>
                                    )}
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor="cc-number">Credit card number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cc-number"
                                        placeholder=""
                                        required=""
                                        {...register("ccNumber", {
                                            required: "Credit card number is required.",
                                        })}
                                    />
                                    {errors.ccNumber && (
                                        <p className="text-danger">{errors.ccNumber.message}</p>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="cc-expiration">Expiration</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cc-expiration"
                                        placeholder=""
                                        required=""
                                        {...register("ccExpiration", {
                                            required: "Expiration date is required.",
                                        })}
                                    />
                                    {errors.ccExpiration && (
                                        <p className="text-danger">{errors.ccExpiration.message}</p>
                                    )}
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="cc-cvv">CVV</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="cc-cvv"
                                        placeholder=""
                                        required=""
                                        {...register("ccCvv", {
                                            required: "CVV is required.",
                                        })}
                                    />
                                    {errors.ccCvv && (
                                        <p className="text-danger">{errors.ccCvv.message}</p>
                                    )}
                                </div>
                            </div>
                            <hr className="mb-4" />
                            {/* <button className="btn btn-primary btn-lg btn-block placebtn" type="submit">
                                PlaceOrder
                            </button> */}
                        </form>
                    </div>
                </div>
                <footer className="my-5 pt-5 text-muted text-center text-small">
                    <p className="mb-1">© 2017-2024 Company Name</p>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a href="#">Privacy</a>
                        </li>
                        <li className="list-inline-item">
                            <a href="#">Terms</a>
                        </li>
                        <li className="list-inline-item">
                            <a href="#">Support</a>
                        </li>
                    </ul>
                </footer>
            </div>

        </div>
    )
}

export default Checkout