import React,{useEffect} from "react";
import "../../assets/css/bonus.css";
import { FaBars } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaHistory } from "react-icons/fa";
const Bonus = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
            top: 0,
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
    <>
      <div className="bonus-value">
        <section className="mt5trade">
          <div className="mt5cont">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 ">
                  <h1 className=" mb-2">Benefits</h1>
                  <p className=" mb-3">
                    Trading makes you cool with rewards
                  </p>
                </div>
                <div className="col-md-12 col-lg-6 ">
                  <img
                    src={require("../../assets/images/mani/bonustrade4.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bonus-content mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                <div className="bonus-content1 mt-5 text-center d-flex align-items-center newbonus">
                  <img
                    src={require("../../assets/images/mani/bonus1.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <p>Bonus On First Deposit</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                <div className="bonus-content1 mt-5 text-center d-flex align-items-center">
                  <img
                    src={require("../../assets/images/mani/bonus2.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <p>Bonus Starting From Second Deposit</p>
                </div>
              </div>
              <div className="bonus-creation mt-2">
                <p className="text-center">
                  The finest FX broker offering a welcome bonus is Milliva.
                  Customers of Milliva will receive a number of complimentary
                  bonuses, including a 50% welcome bonus on the first deposit
                  and a 30% incentive on subsequent deposits as a deposit
                  bonus.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bonus-steps mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <div className="bonus-step-new  text-center">
                  <h2>How To Get Started</h2>
                  <h4 className="mt-3">
                    Only 4 Easy Steps Needed to Earn 50% Bonus.
                  </h4>
                </div>
              </div>
            </div>
            <div className="bonus-step1 mt-4 py-5">
              <div className="row">
                <div className="col-lg-3 col-md-12 col-12 col-sm-12">
                  <div className="bonus-image px-5">
                    <img
                      src={require("../../assets/images/mani/step1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 col-sm-12 ">
                  <div className="bonus-content1">
                    <h4 className="mb-3">Open an Account</h4>
                    <p className="mx-0 px-0">
                      Select an account type that complies with your trading style
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-12 col-sm-12 ">
                  <div className="bonus-content2">
                    <img
                      src={require("../../assets/images/mani/bn.png")}
                      alt="logo"
                      className="img-fluid"
                    />

                    <button type="button" className="btn btn-dark">
                      Register Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="bonus-step1 mt-4 py-3">
              <div className="row">
                <div className="col-lg-3 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="bonus-content2 px-5">
                    <img
                      src={require("../../assets/images/mani/bn2.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <button type="button" className="btn btn-dark">
                      Top up account
                    </button>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="bonus-content1">
                    <h4 className="px-0">Post Money Into Your Account</h4>
                    <p>
                      {" "}
                      <img
                        src={require("../../assets/images/mani/bs5.png")}
                        alt="logo"
                        className="img-fluid crnw mx-3"
                      />
                      use any payment method you prefer to deposit at least
                      $100 into your trading account.
                    </p>
                    <p>
                      <img
                        src={require("../../assets/images/mani/bs4.png")}
                        alt="logo"
                        className="img-fluid mx-3"
                      />
                      free margin increase (open higher volume positions)
                    </p>
                    <p>
                      <img
                        src={require("../../assets/images/mani/bs3.png")}
                        alt="logo"
                        className="img-fluid mx-3"
                      />
                      withdrawable (withdraw bonus funds)
                    </p>
                    <p>
                      <img
                        src={require("../../assets/images/mani/bs2.png")}
                        alt="logo"
                        className="img-fluid mx-3"
                      />
                      automatic payout (get your bonus instantly)
                    </p>
                    <p>
                      <img
                        src={require("../../assets/images/mani/bs1.png")}
                        alt="logo"
                        className="img-fluid mx-3"
                      />
                      Milliva one of the many forex brokers, provides bonus at
                      the greatest price. You will receive a 50% Deposit Bonus
                      - Trading upon your first deposit as soon as you sign up
                      with Milliva.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="bonus-image px-5">
                    <img
                      src={require("../../assets/images/mani/step2.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bonus-step1 mt-4 py-5">
              <div className="row">
                <div className="col-lg-3 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="bonus-image px-5">
                    <img
                      src={require("../../assets/images/mani/step3.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="bonus-content1">
                    <h4 className="mb-3">
                      Use Our Bonus to Double Your Deposit.
                    </h4>
                    <p className="bns-con2">
                      Activate your complimentary 50% bonus on your deposit.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="bonus-content2">
                    <img
                      src={require("../../assets/images/mani/bn1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <button type="button" className="btn btn-dark">
                      Register Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bonus-profit-page mt-5">
          <div className="container">
            <div className="row mt-4">
              <div className="bonus-profit-content text-center">
                <h2>Trade More And Profit More From Our Bonus</h2>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                <div className="bonus-profit1 mt-3">
                  <div className="text-center p-3">
                    <h4>WITHOUT BONUS</h4>
                  </div>
                  <div className="bonus-profits-box">
                    <div className="bonus-profit-box1 d-flex">
                      <div className="bonus-icons d-flex align-items-center">
                        <i className="menu">
                          <FaBars />
                        </i>
                      </div>
                      <div className="bonus-box3 mt-2">
                        <span>Trade</span>
                        <p>1000.00 USD</p>
                      </div>
                      <div className="ms-auto d-flex align-items-center">
                        <div className="px-3">
                          <i className="menu">
                            <FaArrowDown />
                          </i>
                          <i className="menu">
                            <FaArrowUp />
                          </i>
                        </div>
                        <div className="px-2">
                          <img
                            src={require("../../assets/images/mani/openfile.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Balance</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        3 000.00
                      </span>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Equity</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        2 998.00
                      </span>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Margin</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        1 050.62
                      </span>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Free Margin</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        1 947.38
                      </span>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Margin Level(%)</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        285.36
                      </span>
                    </div>
                    <div className="bonus-profit2 c1">
                      <span>position</span>
                    </div>
                    <div className="bonus-profit2 c2 d-flex">
                      <div className="bonus-c3">
                        <h6>
                          EURUSD,<span>buy 1.00</span>
                        </h6>
                        <p>
                          1.05062
                          <i className="menu">
                            <FaArrowRight />
                          </i>
                          1.06060
                        </p>
                      </div>
                      <div className="ms-auto d-flex align-items-center">
                        <span
                          className=""
                          style={{ fontSize: 20, color: "#4285f4" }}
                        >
                          1000.00
                        </span>
                      </div>
                    </div>
                    <div className="bonus-profit3 mt-5 pt-5 d-flex justify-content-between text-center">
                      <div>
                        <i className="menu">
                          <FaArrowDown />
                        </i>
                        <i className="menu">
                          <FaArrowUp />
                        </i>
                        <p>Quotes</p>
                      </div>
                      <div className="">
                        <img
                          src={require("../../assets/images/mani/trades.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        <p>Charts</p>
                      </div>
                      <div>
                        <img
                          src={require("../../assets/images/mani/charts1.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        <p>Trade</p>
                      </div>
                      <div>
                        <i className="menu">
                          <FaHistory />
                        </i>
                        <p>History</p>
                      </div>
                      <div>
                        <img
                          src={require("../../assets/images/mani/message.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        <p>Messages</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                <div className="bonus-profit1 mt-3 py-3">
                  <div className="text-center p-3">
                    <h4>WITH 50% BONUS</h4>
                  </div>
                  <div className="bonus-profits-box">
                    <div className="bonus-profit-box1 d-flex">
                      <div className="bonus-icons d-flex align-items-center">
                        <i className="menu">
                          <FaBars />
                        </i>
                      </div>
                      <div className="bonus-box3 mt-2">
                        <span>Trade</span>
                        <p>1500.00 USD</p>
                      </div>
                      <div className="ms-auto d-flex align-items-center">
                        <div className="px-3">
                          <i className="menu">
                            <FaArrowDown />
                          </i>
                          <i className="menu">
                            <FaArrowUp />
                          </i>
                        </div>
                        <div className="px-2">
                          <img
                            src={require("../../assets/images/mani/openfile.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Balance</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        3 000.00
                      </span>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Equity</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        2 998.00
                      </span>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Bonus</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        1 500.00
                      </span>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Margin</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        1 810.10
                      </span>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Free Margin</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        2 427.38
                      </span>
                    </div>
                    <div className="bonus-profit2 d-flex">
                      <span>Margin Level(%)</span>
                      <span
                        className="ms-auto"
                        style={{ color: "rgb(22, 199, 244)" }}
                      >
                        383.36
                      </span>
                    </div>
                    <div className="bonus-profit2 c1">
                      <span>position</span>
                    </div>
                    <div className="bonus-profit2 c2 d-flex">
                      <div className="bonus-c3">
                        <h6>
                          EURUSD,<span>buy 1.50</span>
                        </h6>
                        <p>
                          1.05062
                          <i className="menu">
                            <FaArrowRight />
                          </i>
                          1.06060
                        </p>
                      </div>
                      <div className="ms-auto d-flex align-items-center">
                        <span
                          className=""
                          style={{ fontSize: 20, color: "#4285f4" }}
                        >
                          1500.00
                        </span>
                      </div>
                    </div>
                    <div className="bonus-profit3 mt-5 pt-5 d-flex justify-content-between text-center">
                      <div>
                        <i className="menu">
                          <FaArrowDown />
                        </i>
                        <i className="menu">
                          <FaArrowUp />
                        </i>
                        <p>Quotes</p>
                      </div>
                      <div className="">
                        <img
                          src={require("../../assets/images/mani/trades.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        <p>Charts</p>
                      </div>
                      <div>
                        <img
                          src={require("../../assets/images/mani/charts1.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        <p>Trade</p>
                      </div>
                      <div>
                        <i className="menu">
                          <FaHistory />
                        </i>
                        <p>History</p>
                      </div>
                      <div>
                        <img
                          src={require("../../assets/images/mani/message.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        <p>Messages</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="start-trade-bonus">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                <div className="trade-bonus-1 text-center mt-4 py-3">
                  <h2>Start Trading With Milliva Today</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="bonus-waves1 col-lg-3 col-md-12 col-12 col-sm-12">
                <div className="bonus-earn text-center">
                  <img
                    src={require("../../assets/images/mani/bonusicon1.png")}
                    alt="logo"
                    className="img-fluid mb-2"
                  />
                  <h5>Open a Live Account</h5>
                  <p>Open a live account if you haven't already got one.</p>
                </div>
              </div>
              <div className="bonus-waves1 col-lg-3 col-md-12 col-12 col-sm-12">
                <div className="bonus-earn text-center">
                  <img
                    src={require("../../assets/images/mani/bonusicon2.png")}
                    alt="logo"
                    className="img-fluid mb-2"
                  />
                  <h5>Fund Your Account</h5>
                  <p>Make a deposit into your trading account.</p>
                </div>
              </div>
              <div className="bonus-waves1  col-lg-3 col-md-12 col-12 col-sm-12">
                <div className="bonus-earn text-center">
                  <img
                    src={require("../../assets/images/mani/bonusicon3.png")}
                    alt="logo"
                    className="img-fluid mb-2"
                  />
                  <h5>Claim Your Bonus</h5>
                  <p>
                    Your bonus will be credited to your account right after
                    your deposit arrives
                  </p>
                </div>
              </div>
              <div className="bonus-waves1  col-lg-3 col-md-12 col-12 col-sm-12">
                <div className="bonus-earn text-center">
                  <img
                    src={require("../../assets/images/mani/bonusicon4.png")}
                    alt="logo"
                    className="img-fluid mb-2"
                  />
                  <h5>Start Trading</h5>
                  <p>All done! enjoy your extra 50% trading power!</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  </div>
  );
};

export default Bonus;
