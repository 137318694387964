import React,{useEffect} from "react";
import "../../assets/css/faq2.css";

import { AiOutlineUserAdd } from "react-icons/ai";
import { AiOutlineFileProtect } from "react-icons/ai";
import { AiOutlineUserSwitch } from "react-icons/ai";
import { AiOutlineBank } from "react-icons/ai";
import { MdOutlineAccountBalanceWallet, MdAllInclusive } from "react-icons/md";
import { RiLuggageDepositLine } from "react-icons/ri";
import { BiDollarCircle } from "react-icons/bi";
import { IoMdSwap } from "react-icons/io";
import { MdSignalCellularAlt } from "react-icons/md";
import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { BsCashCoin } from "react-icons/bs";
import { RiSecurePaymentLine } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { Link } from "react-router-dom";
import { FcNeutralTrading } from "react-icons/fc";
import { MdOutlineCalculate } from "react-icons/md";
import { FaUniversity } from "react-icons/fa";
import { TfiWallet } from "react-icons/tfi";
import { RiHandCoinLine } from "react-icons/ri";
const Faqm2 = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
            top: 0,
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <div className="faqm2">
        <section className="faq-banner">
          <div className="faqcont">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center mt-5">
                  <h1 className="mt-5">FAQ</h1>
                  <p className=" mb-3">
                    There are plenty of ways to get in touch with us, wherever
                    you are in the world. We have an international team of
                    dedicated support specialists ready to help you.
                  </p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/faq/faqbanner.png")}
                    alt="logo"
                    className="img-fluid mt-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="faqsidebar px-0">
          <div className="container-fluid">
            <div className="row flex-nowrap">
              <div className="col-auto px-0">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div
                    id="sidebar"
                    className="collapse collapse-horizontal show "
                  >
                    <div
                      id="sidebar-nav"
                      className="list-group border-0 rounded-0 text-sm-start min-vh-100"
                    >
                      <ul
                        className="nav  nav-pills p-0 "
                        id="pills-tab"
                        role="tablist"
                      >
                        <li
                          class="nav-link active"
                          id="pills-1-tab"
                          data-bs-toggle="pill"
                          href="#pills-1"
                          role="tab"
                          aria-controls="pills-1"
                          aria-selected="true"
                        >
                          <span class=" name ">
                            <h2>Get Started</h2>
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse1"
                              aria-expanded="true"
                              aria-controls="collapse1"
                            >
                              <span className="icon">
                                <AiOutlineUserAdd />
                              </span>
                              General
                            </h6>
                            <ul
                              id="collapse1"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading1"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>

                        {/* accrodion one --------------end */}

                        <li
                          class="nav-link"
                          id="pills-2-tab"
                          data-bs-toggle="pill"
                          href="#pills-2"
                          role="tab"
                          aria-controls="pills-2"
                          aria-selected="false"
                        >
                          <span class="name  ">
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse2"
                              aria-expanded="true"
                              aria-controls="collapse2"
                            >
                              {" "}
                              <span className="icon">
                                <AiOutlineFileProtect />
                              </span>
                              Registration
                            </h6>
                            <ul
                              id="collapse2"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading2"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>
                        {/* accrodion two --------------end */}

                        <li
                          class="nav-link"
                          id="pills-3-tab"
                          data-bs-toggle="pill"
                          href="#pills-3"
                          role="tab"
                          aria-controls="pills-3"
                          aria-selected="false"
                        >
                          <span class=" name">
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse3"
                              aria-expanded="true"
                              aria-controls="collapse3"
                            >
                              <span className="icon">
                                <AiOutlineUserSwitch />
                              </span>
                              Trading
                            </h6>
                            <ul
                              id="collapse3"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading3"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>

                        {/* accrodion three --------------end */}

                        <li
                          class="nav-link"
                          id="pills-4-tab"
                          data-bs-toggle="pill"
                          href="#pills-4"
                          role="tab"
                          aria-controls="pills-4"
                          aria-selected="false"
                        >
                          <span class=" name">
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse4"
                              aria-expanded="true"
                              aria-controls="collapse4"
                            >
                              <span className="icon">
                                <AiOutlineBank />
                              </span>
                              Trading Service
                            </h6>
                            <ul
                              id="collapse4"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading4"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>

                        {/* accrodion four --------------end */}

                        <li
                          class="nav-link"
                          id="pills-5-tab"
                          data-bs-toggle="pill"
                          href="#pills-5"
                          role="tab"
                          aria-controls="pills-5"
                          aria-selected="false"
                        >
                          <span class=" name">
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse5"
                              aria-expanded="true"
                              aria-controls="collapse5"
                            >
                              {" "}
                              <span className="icon">
                                <MdOutlineAccountBalanceWallet />
                              </span>
                              Placing Trade
                            </h6>
                            <ul
                              id="collapse5"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading5"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>

                        {/* accrodion five --------------end */}

                        <li
                          class="nav-link"
                          id="pills-6-tab"
                          data-bs-toggle="pill"
                          href="#pills-6"
                          role="tab"
                          aria-controls="pills-6"
                          aria-selected="false"
                        >
                          <span class="name">
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse6"
                              aria-expanded="true"
                              aria-controls="collapse6"
                            >
                              <span className="icon">
                                <RiLuggageDepositLine />
                              </span>
                              Trading Account
                            </h6>
                            <ul
                              id="collapse6"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading6"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>

                        {/* accrodion six --------------end */}

                        <li
                          class="nav-link"
                          id="pills-7-tab"
                          data-bs-toggle="pill"
                          href="#pills-7"
                          role="tab"
                          aria-controls="pills-7"
                          aria-selected="false"
                        >
                          <span class=" name">
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse6"
                              aria-expanded="true"
                              aria-controls="collapse6"
                            >
                              {" "}
                              <span className="icon">
                                <FcNeutralTrading />
                              </span>
                              Trading Symbol
                            </h6>
                            <ul
                              id="collapse6"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading6"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>
                        {/* accrodion seven --------------end */}
                        <li
                          class="nav-link"
                          id="pills-8-tab"
                          data-bs-toggle="pill"
                          href="#pills-8"
                          role="tab"
                          aria-controls="pills-8"
                          aria-selected="false"
                        >
                          <span class="name  ">
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse8"
                              aria-expanded="true"
                              aria-controls="collapse8"
                            >
                              {" "}
                              <span className="icon">
                                <FaUniversity />
                              </span>{" "}
                              Trading account information
                            </h6>
                            <ul
                              id="collapse8"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading8"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>

                        {/* accrodion eight --------------end */}
                        <li
                          class="nav-link"
                          id="pills-9-tab"
                          data-bs-toggle="pill"
                          href="#pills-9"
                          role="tab"
                          aria-controls="pills-9"
                          aria-selected="false"
                        >
                          <span class="name  ">
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse9"
                              aria-expanded="true"
                              aria-controls="collapse9"
                            >
                              {" "}
                              <span className="icon">
                                <MdOutlineCalculate />
                              </span>{" "}
                              Calculations
                            </h6>
                            <ul
                              id="collapse9"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading9"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>
                        {/* accrodion nine --------------end */}
                        <li
                          class="nav-link"
                          id="pills-10-tab"
                          data-bs-toggle="pill"
                          href="#pills-10"
                          role="tab"
                          aria-controls="pills-10"
                          aria-selected="false"
                        >
                          <span class="name  ">
                            <h2>Deposits and Withdrawals</h2>
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse10"
                              aria-expanded="true"
                              aria-controls="collapse10"
                            >
                              {" "}
                              <span className="icon">
                                <AiOutlineUserAdd />
                              </span>{" "}
                              General
                            </h6>
                            <ul
                              id="collapse10"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading10"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>
                        {/* accrodion ten --------------end */}
                        <li
                          class="nav-link"
                          id="pills-11-tab"
                          data-bs-toggle="pill"
                          href="#pills-11"
                          role="tab"
                          aria-controls="pills-11"
                          aria-selected="false"
                        >
                          <span class="name  ">
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse11"
                              aria-expanded="true"
                              aria-controls="collapse11"
                            >
                              {" "}
                              <span className="icon">
                                <TfiWallet />
                              </span>
                              Making Deposit
                            </h6>
                            <ul
                              id="collapse11"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading11"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>
                        {/* accrodion ten --------------end */}
                        <li
                          class="nav-link"
                          id="pills-12-tab"
                          data-bs-toggle="pill"
                          href="#pills-12"
                          role="tab"
                          aria-controls="pills-12"
                          aria-selected="false"
                        >
                          <span class="name  ">
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse12"
                              aria-expanded="true"
                              aria-controls="collapse12"
                            >
                              {" "}
                              <span className="icon">
                                <RiHandCoinLine />
                              </span>
                              Withdrawals
                            </h6>
                            <ul
                              id="collapse12"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading12"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>

                        {/* accrodion 11 --------------end */}
                        <li
                          class="nav-link"
                          id="pills-13-tab"
                          data-bs-toggle="pill"
                          href="#pills-13"
                          role="tab"
                          aria-controls="pills-13"
                          aria-selected="false"
                        >
                          <span class="name  ">
                            <h2>Partnership Program</h2>
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse13"
                              aria-expanded="true"
                              aria-controls="collapse13"
                            >
                              {" "}
                              <span className="icon">
                                <AiOutlineUserAdd />
                              </span>{" "}
                              General
                            </h6>
                            <ul
                              id="collapse13"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading13"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>

                        <li
                          class="nav-link"
                          id="pills-14-tab"
                          data-bs-toggle="pill"
                          href="#pills-14"
                          role="tab"
                          aria-controls="pills-14"
                          aria-selected="false"
                        >
                          <span class="name  ">
                            <h2>Platform</h2>
                            <h6
                              class="accordion-button"
                              type=""
                              data-bs-toggle="collapse"
                              data-bs-target="#collapse14"
                              aria-expanded="true"
                              aria-controls="collapse14"
                            >
                              {" "}
                              <span className="icon">
                                <AiOutlineUserAdd />
                              </span>{" "}
                              General
                            </h6>
                            <ul
                              id="collapse14"
                              class="accordion-collapse collapse hide  "
                              aria-labelledby="heading14"
                              data-bs-parent="#accordionExample"
                            ></ul>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <main className="col ps-md-2 pt-2">
                <a
                  href="#"
                  data-bs-target="#sidebar"
                  data-bs-toggle="collapse"
                  className=" p-1 text-decoration-none sidemenuicon"
                >
                  <GiHamburgerMenu />{" "}
                </a>

                <div class="tab-content" id="pills-tabContent p-3">
                  {/* register sub start------------------- */}
                  <div
                    class="tab-pane fade show active"
                    id="pills-1"
                    role="tabpanel"
                    aria-labelledby="pills-1-tab"
                  >
                    <div className="faqsub">
                      <div className="card mt-3">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingOneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOneF"
                                      >
                                        How is the Forex market affected?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseOneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingOneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The Forex market depends on people's
                                          future expectations, and many factors
                                          shape them. Some companies fence
                                          currency exposure to protect the
                                          company, some primary traders
                                          concentrate on factors that affect
                                          whole economies' strength, and other
                                          technical traders seek price patterns
                                          to initiate their trades aimed at each
                                          market participant. There are also
                                          hedge funds, central banks, and
                                          financial institutions, all of which
                                          offer different trade goals and
                                          interpretations.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingtwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsetwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwoF"
                                      >
                                        Are my money and personal data
                                        protected?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsetwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The world’s best brokerage firm,
                                          Milliva applies a strict regulatory
                                          framework. All clients' funds are kept
                                          in accounts of top-tier banks,
                                          ensuring that your investments are
                                          safe and secure at all times.
                                        </p>

                                        <p>
                                          {" "}
                                          We also ensure that your personal
                                          information is secure as well.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThreeF"
                                      >
                                        Where can I find your Customer
                                        Agreement, Terms, Conditions, etc.?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can find it on our Account opening
                                          Agreements page. You can refer to all
                                          the documents related to our business.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsefourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsefourF"
                                      >
                                        What can I do if none of my bills are in
                                        my name?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsefourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p className="strong">
                                          You've got the following options:
                                        </p>
                                        <p>
                                          If the ID document you have already
                                          uploaded indicates your current
                                          address, no other record is required.
                                        </p>
                                        <p>
                                          You can submit a bank/card statement,
                                          residence certificate, and utility
                                          bills such as gas, mobile phone, or
                                          tenancy contracts.
                                        </p>
                                        <p>
                                          You can provide an additional ID
                                          document to indicate your current
                                          address. You can also provide us with
                                          a proof of residence document issued
                                          on behalf of your landlord, parent, or
                                          spouse. Please specify the document's
                                          name, Proof of Residence when
                                          uploading the document to Milliva.
                                        </p>
                                        <p>
                                          If you have any queries, please
                                          contact at{" "}
                                          <span> <a href="mailto:support@milliva.com" target="_blank" style={{color:"inherit"}}>
                                                support@milliva.com
                                                </a>.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiveF"
                                      >
                                        How long does it take to check my
                                        profile?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Once you upload all your documents,
                                          checking your profile during working
                                          hours may take up to 10 minutes to 24
                                          hours.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixF"
                                      >
                                        What is Fundamental Analysis, And Why Is
                                        So Important?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Fundamental analysis is a form of
                                          research that looks into the
                                          political, economic, and social
                                          factors that influence a country's
                                          currency. Fundamentals examine the
                                          supply and demand forces that affect
                                          currencies, goods, and stocks, which
                                          can be affected by economic releases,
                                          geopolitical conflicts, seasonality,
                                          interest rate shifts, central bank
                                          news, and other factors.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSevenF"
                                      >
                                        What Is Trade Management? What is Trade
                                        Management?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          This refers to open position
                                          management. This includes reducing
                                          risk when trade is profitable by
                                          bringing the loss stop closer to the
                                          market price or lowering trade losses.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightF"
                                      >
                                        Do you permit trading in the news?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Oh, yes</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNineF"
                                      >
                                        Is it possible to earn as I study?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Oh yes, your success directly links
                                          with this. Always begin with a small,
                                          realistic purpose and practice.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTenF"
                                      >
                                        Do you accept customers from the United
                                        States?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No, please accept our apologies for
                                          the inconvenience.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="modal-footer" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div class="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-1-tab">
                  <div className='faqsub'>
                    <div className='card'>
                      <div className='container-fluid'>
                        <div className='row'>
                          <div className='col-12 col-lg-6'>
                         






                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                </div> */}

                  <div
                    class="tab-pane "
                    id="pills-2"
                    role="tabpanel"
                    aria-labelledby="pills-2-tab"
                  >
                    <div className="faqsub">
                      <div className="card">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingLevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseLevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseLevenF"
                                      >
                                        How do I become a member of Milliva?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseLevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingLevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Follow this link
                                          https://www.milliva.com Enter your
                                          information required field. Click
                                          'Register Now. Check the your mail to
                                          receive the access by click ‘Confirm
                                          mail’.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTweleF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTweleF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTweleF"
                                      >
                                        How can I reset my Milliva password?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTweleF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTweleF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <span>
                                          <p className="stong">
                                            {" "}
                                            You can reset your password in
                                            simple steps:
                                          </p>
                                        </span>
                                        <p>
                                          {" "}
                                          Enter your email. Enter your new
                                          password.
                                        </p>
                                        <p>
                                          Please keep your password highly
                                          confidential and do not disclose it to
                                          any third parties.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtinF"
                                      >
                                        How can I change the details of my home
                                        address?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can modify your residence address
                                          by sending us a copy of your proof of
                                          residence document. We'll update your
                                          profile afterward. Details about
                                          acceptable residence documents are
                                          available here.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFourtinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFourtinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFourtinF"
                                      >
                                        Where can I locate my market login
                                        details for Milliva?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFourtinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFourtinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Shortly after registration, you
                                          received the email. Please use the
                                          reset password service if you did not
                                          receive this email.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftinF"
                                      >
                                        What to do if I get an error while
                                        uploading my papers?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <span>
                                          <p className="stong">
                                            {" "}
                                            Please check the following before
                                            you upload:
                                          </p>
                                        </span>
                                        <p>
                                          The size of your document should not
                                          exceed 2MB.
                                        </p>
                                        <p>
                                          The file should be in one of the
                                          following formats: gif, jpg, tiff,
                                          png, doc, Docx, and pdf.
                                        </p>
                                        <p>
                                          If an error message persists, you can
                                          send your documents to{" "}
                                            <span> 
                                                 <a href="mailto:support@milliva.com" target="_blank" style={{color:"inherit"}}>
                                                    support@milliva.com
                                                 </a>
                                            </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtinF"
                                      >
                                        How can I become a verified customer?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Once you become a verified client, you
                                          can access the best options trading
                                          platform of Milliva's products and
                                          services. Verify your profile by
                                          uploading clear color copies of the
                                          following documents:
                                        </p>
                                        <p>
                                          Proof of identity – national identity
                                          card, passport, or driving license; if
                                          your identification document also
                                          states your correct residential
                                          address, additional proof of address
                                          document may not be required.
                                        </p>
                                        <p>
                                          Proof of address – bank/card statement
                                          or utility bill issued no longer than
                                          six months ago.{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventinF"
                                      >
                                        How can my email or telephone number be
                                        changed?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          {" "}
                                          Please click on 'Change' under the
                                          'Actions' column and follow the steps
                                          to change your email address or phone
                                          number in Milliva on your user profile
                                          page.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightinF"
                                      >
                                        What is Milliva Dashboard, and how does
                                        it work?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The <b>Best Trading Website</b>, the
                                          Milliva dashboard is your gateway to
                                          Milliva. You can open trading
                                          accounts, download trading platforms,
                                          upload verification documents, deposit
                                          and withdraw funds, sign up for
                                          webinars, and more.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNinetinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNinetinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNinetinF"
                                      >
                                        What does the error 'This number/email
                                        has already been registered' mean?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNinetinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNinetinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          {" "}
                                          If you receive this, it means Milliva
                                          has already registered your phone
                                          number or email address.
                                        </p>
                                        <p>
                                          If you forgot your password, you could
                                          generate a new one by following the
                                          steps below. It's important to
                                          remember that you can only have one My
                                          Milliva account. In My Milliva, you
                                          can build additional trading accounts
                                          and access other products and
                                          services.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTwentyF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwentyF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwentyF"
                                      >
                                        Is it possible to change my account
                                        type?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTwentyF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwentyF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No, but you can easily open an
                                          additional account in the MT5 Area at
                                          any time by selecting the account type
                                          of your choice.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTwentyoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwentyoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwentyoneF"
                                      >
                                        Is it possible to change my account's
                                        base currency?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTwentyoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwentyoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You cannot change the base currency of
                                          your current trading account. You can,
                                          however, create a new account in the
                                          Members Area at any time and choose
                                          the base currency of your choice.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-3"
                    role="tabpanel"
                    aria-labelledby="pills-3-tab"
                  >
                    <div className="faqsub">
                      <div className="card">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTwentytwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwentytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwentytwoF"
                                      >
                                        How can I start trading with Forex?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTwentytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwentytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Open a trading account with the{" "}
                                          <b>largest stock broker</b> , Milliva.
                                          Then you can start trading currencies
                                          with the help of our full-fledged
                                          services. To complete this task, you
                                          only need a few minutes!
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTwentythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwentythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwentythreeF"
                                      >
                                        Do you offer to trade with binary
                                        options?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTwentythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwentythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>No, we don't.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTwentyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwentyfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwentyfourF"
                                      >
                                        Is it possible to transfer cash from one
                                        trading account to another?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTwentyfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwentyfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Yes, it's possible. You may request an
                                          internal transfer between two trading
                                          accounts, but only if both accounts
                                          have been opened under your name and
                                          the two trading accounts have been
                                          validated. If the base currency is
                                          different, the amount will be
                                          converted. Internal transfer can be
                                          requested in the transfer Area and is
                                          processed immediately.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTwentyfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwentyfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwentyfiveF"
                                      >
                                        What is trading with one click? How can
                                        I make it simple?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTwentyfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwentyfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          One-click trading allows you to open
                                          positions with a single click.
                                          However, if you want to close a
                                          position, one click will not work, and
                                          you must complete it manually. You
                                          will find an arrow enabling one-click
                                          trading on your chart's left corner.
                                          By clicking that arrow, you enable
                                          one-click trading, and a window
                                          appears in the left corner of the
                                          chart.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTwentysixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwentysixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwentysixF"
                                      >
                                        How to get into trading?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTwentysixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwentysixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Suppose you've already created a
                                          trading account, got your login
                                          information via email, submitted your
                                          identification documents for account
                                          confirmation, and made a deposit. In
                                          that case, the next move is to
                                          download your chosen trading platform.{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTwentysevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwentysevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwentysevenF"
                                      >
                                        What is the execution model for you?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTwentysevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwentysevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The Company shall act as the principal
                                          (therefore, the Company shall be the
                                          sole place of execution for the
                                          execution of the Customer's orders).
                                          In addition, when the Company executes
                                          orders from Clients, it may, in turn,
                                          route its orders to regulated EU and
                                          non-EU financial institutions.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTwentyeightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwentyeightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwentyeightF"
                                      >
                                        For how long can I use a demo account?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTwentyeightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwentyeightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          There is no expiry date for demo
                                          accounts in the{" "}
                                          <b>best trading platform</b> Milliva,
                                          so you can use them as long as you
                                          want. Demo accounts inactive for more
                                          than ninety days from the last login
                                          will be closed. However, at any time,
                                          you can open a new demo account.
                                          Please note that a maximum of five
                                          active demo accounts is allowed.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingTwentynineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwentynineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwentynineF"
                                      >
                                        Can a friend/family member make a
                                        business/trade for me?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseTwentynineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwentynineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No, only the individual who registered
                                          can trade for the account. Unless and
                                          until he/she submits the POA from the
                                          client to trade on behalf of the
                                          registered client to the Company.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtyF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtyF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtyF"
                                      >
                                        If I lose the bonus, can I refund it?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtyF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtyF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Since the bonus amount is part of your
                                          equity and can be used for trading,
                                          you can lose it. In addition,
                                          according to the Bonus Terms and
                                          Conditions, you may receive a new
                                          bonus on your new deposit.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtyoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtyoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtyoneF"
                                      >
                                        If I trade in FOREX, will I lose my
                                        assets?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtyoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtyoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No, no. You won't lose all that you
                                          own. The under-educated are more than
                                          likely to lose their margin account.
                                          The educated will likely capture the
                                          money on the loser's margin account.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-4"
                    role="tabpanel"
                    aria-labelledby="pills-4-tab"
                  >
                    <div className="faqsub">
                      <div className="card">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtytwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtytwoF"
                                      >
                                        Where is Forex, and who owns it?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Nobody owns Forex. Forex is a
                                          decentralized market. Forex is an
                                          interbank market with only two
                                          players: the seller and the buyer.
                                          Forex will be here as long as the
                                          existing banking system exists. It is
                                          not associated with any particular
                                          country or organization of government.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtythreeF"
                                      >
                                        Where can you trade Forex?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          {" "}
                                          You can trade from any part of the
                                          world.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtyfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtyfourF"
                                      >
                                        Can I acquire other stocks from Milliva
                                        internationally?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtyfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtyfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Yes, Bonds (Tokyo Stock Exchange,
                                          London Stock Exchange (LSE), and Hong
                                          Kong Stock Exchange) are very famous
                                          in the US (NYSE, NASDAQ, NYMEX,
                                          GLOBEX, and others) (HKEX).
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtyfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtyfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtyfiveF"
                                      >
                                        What currencies can you trade with
                                        Milliva?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtyfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtyfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can invest in many currencies with
                                          the{" "}
                                          <b>best brokerage firm in India</b>,
                                          Milliva– there are indeed more than
                                          60+ pairs in Milliva that are
                                          available.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtysixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtysixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtysixF"
                                      >
                                        How do I see the history of my trade?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtysixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtysixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Press Ctrl + T on your keyboard to
                                          open the terminal window and select
                                          the History Account. Right-click
                                          allows you to save your business
                                          history as a .html file so that you
                                          can view it later when you log out
                                          from your trading platform.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtysevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtysevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtysevenF"
                                      >
                                        What is the difference between real and
                                        demo accounts?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtysevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtysevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          While a demo account can also provide
                                          all features and functions of a real
                                          account, keep in mind that simulation
                                          cannot replicate actual trading
                                          conditions. One important difference
                                          is that the volume carried out by
                                          simulation does not affect the market.
                                          In contrast, the market is affected by
                                          actual trading volumes, especially
                                          where the deal size is large. For real
                                          trading accounts, the speed of
                                          implementation is the same as for the
                                          Milliva demo accounts. In addition,
                                          the psychological profile of users is
                                          very different, depending on whether
                                          they trade with demo or actual
                                          accounts. The evaluation conducted
                                          with the demo account could affect
                                          this aspect. We advise you to be
                                          cautious and not to indulge in
                                          findings from a demo account. More
                                          information on demo accounts is
                                          available here.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtyeightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtyeightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtyeightF"
                                      >
                                        What are the emotions that have to do
                                        with trading?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtyeightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtyeightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Emotions are where money is involved.
                                          A lot of people are quite
                                          knowledgeable about trading, but they
                                          can't handle emotions. Your emotions
                                          are going to be your biggest obstacle
                                          to successful trading. It's not
                                          techniques. You can't trade
                                          emotionally to be a successful trader.
                                          You have to trade logically. Our egos
                                          drive us to succeed 100 percent of the
                                          time, but in reality, no one succeeds
                                          100 percent of the time. Not even the
                                          professionals, either. Successful
                                          professional traders clearly
                                          understand that the market is about
                                          logic, not emotion. They trade
                                          logically, not emotionally.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingThirtynineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThirtynineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThirtynineF"
                                      >
                                        What is good judgment trading?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseThirtynineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingThirtynineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Good judgment trading is exactly the
                                          opposite of the Black Box system. It
                                          is a complete understanding of the
                                          market and its ever-changing
                                          environment. It is a clear trading
                                          methodology that uses high
                                          probability. When a trader is
                                          educated, he no longer makes a shotgun
                                          approach to the market. It takes a
                                          very focused 'rifle and target'
                                          approach.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-5"
                    role="tabpanel"
                    aria-labelledby="pills-5-tab"
                  >
                    <div className="faqsub">
                      <div className="card">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFortyF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFortyF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFortyF"
                                      >
                                        What time does the Forex market open and
                                        close?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFortyF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFortyF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The forex market is open from 22:00
                                          GMT Sunday (the start of the
                                          Australian trading session) to 22:00
                                          GMT Friday (the end of the trading
                                          session) (the closing of the US
                                          trading session).
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFortyoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFortyoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFortyoneF"
                                      >
                                        Do you offer corporate accounts?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFortyoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFortyoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          {" "}
                                          Yes, we do offer corporate accounts.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFortytwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFortytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFortytwoF"
                                      >
                                        How to buy and sell currency pairs?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFortytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFortytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can also easily trade currencies
                                          from your tablet or smartphone. With
                                          the Meta Trader 5 mobile platform, you
                                          can trade at anytime from anywhere on
                                          the Forex market. Download your mobile
                                          app for free, and take your trading
                                          account wherever you go!
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFortythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFortythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFortythreeF"
                                      >
                                        Why do I fail to log in to my trading
                                        account?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFortythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFortythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <span>
                                          <p>
                                            Message:no connection means that you
                                            have chosen the wrong server.
                                          </p>
                                        </span>
                                        <span>
                                          <p>
                                            Message: Invalid account means that
                                            you entered the wrong ID/password.
                                          </p>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFortyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFortyfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFortyfourF"
                                      >
                                        How can I change the password of my
                                        trading account?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFortyfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFortyfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Go to the 'Dash Board' section of
                                          Milliva.
                                        </p>
                                        <p>
                                          Click the 'Deposit' button on the
                                          account you want to change the
                                          password and select{" "}
                                        </p>
                                        <p>'Change Password Trade.'</p>
                                        <p>
                                          Click 'Next' and enter the PIN sent to
                                          the required fields by e-mail or SMS.
                                        </p>
                                        <p>
                                          Create your new password and click the
                                          'Submit' button.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFortyfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFortyfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFortyfiveF"
                                      >
                                        Does slippage happen with Milliva?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFortyfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFortyfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          If you trade with us, slippages rarely
                                          happen. However, sometimes you may
                                          fill your order at a different rate
                                          than you asked, mainly when news is
                                          released due to a sharp rise/decline
                                          in the market price.{" "}
                                        </p>
                                        <p>
                                          <b>World’s biggest stock broker</b>,
                                          Milliva fulfills your orders at the
                                          best market price, which is in value.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFortysixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFortysixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFortysixF"
                                      >
                                        What is a stop loss, and how does it
                                        work?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFortysixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFortysixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          A stop-loss order closes a previously
                                          opened position at a less profitable
                                          price for the client than when the
                                          stop-loss is placed. A stop loss is a
                                          cap you put on your order. Your order
                                          will be closed until this limit is
                                          reached. When placing stop/limit
                                          orders, keep in mind that you must
                                          leave some distance from the current
                                          market price.{" "}
                                        </p>
                                        <p>
                                          If the market goes against you, using
                                          a stop loss will help you limit your
                                          losses. On BUY, stop loss points are
                                          always set below the current BID
                                          price, and on SELL, they are always
                                          set above the current ASK price.{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFortysevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFortysevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFortysevenF"
                                      >
                                        Why don't we hear more of FOREX?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFortysevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFortysevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Most of the volume has historically
                                          been generated by major investors,
                                          banks, financial institutions, and
                                          governments. Thanks to the Internet,
                                          more and more people like us are
                                          starting to learn about opportunities
                                          and get involved.{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFortyeightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFortyeightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFortyeightF"
                                      >
                                        What is techno-sensitive Analysis?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFortyeightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFortyeightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It refers to the Analysis of
                                          historical price actions to determine
                                          future pricing patterns in the market.
                                          This can be done using technical
                                          indicators and other analytical tools.
                                          It often looks for resistance and
                                          support and identifies trends and
                                          changes.{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFortynineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFortynineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFortynineF"
                                      >
                                        What can the Market Traders Institute do
                                        for me?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFortynineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFortynineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You will begin your involvement with a
                                          reputable company and successful
                                          professional traders. We're going to
                                          teach you all the processes to be
                                          successful. We will provide you with
                                          ongoing personal support, research and
                                          education. We offer advanced trading
                                          courses as you progress and guarantee
                                          during any period; if it's not up to
                                          your expectations, we will refund the
                                          course cost.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-6"
                    role="tabpanel"
                    aria-labelledby="pills-6-tab"
                  >
                    <div className="faqsub">
                      <div className="card">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftyF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftyF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftyF"
                                      >
                                        What kind of instruments are available?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftyF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftyF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can find a full list of all the
                                          trading instruments that we offer
                                          here.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftyoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftyoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftyoneF"
                                      >
                                        Is there scalping?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftyoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftyoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Yes.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftytwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftytwoF"
                                      >
                                        Is there hedging?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Yes.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftythreeF"
                                      >
                                        How do I open a new account with
                                        Live/Demo?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          In the My Milliva, select the 'MT5'
                                          tab.
                                        </p>
                                        <p>
                                          Choose the account type you want to
                                          open and click Open New Account.
                                        </p>
                                        <p>
                                          Complete the required details and
                                          click on 'Open Account.'
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftyfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftyfourF"
                                      >
                                        What are your spreads?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftyfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftyfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          We offer floating distributions
                                          throughout our accounts. Please visit
                                          our contract specification page for
                                          information.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftyfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftyfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftyfiveF"
                                      >
                                        What kind of lots do you offer?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftyfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftyfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Standard, Mini, Micro.</p>
                                        <p>
                                          You can visit our contract
                                          specification page for more
                                          information.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftysixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftysixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftysixF"
                                      >
                                        How many trading accounts can I open up?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftysixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftysixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          {" "}
                                          No limits. You can open as many
                                          accounts as you want.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftysevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftysevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftysevenF"
                                      >
                                        What kind of trading account can I open?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftysevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftysevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          {" "}
                                          You can see all your demo, live, and
                                          investment accounts and wallets in the
                                          Accounts Overview section of My
                                          Milliva.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftyeightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftyeightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftyeightF"
                                      >
                                        What kind of leverage are you offering?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftyeightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftyeightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The maximum amount available is 1:100.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingFiftynineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFiftynineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFiftynineF"
                                      >
                                        What kind of leverage is there on
                                        precious metals (Gold &amp; Silver)?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseFiftynineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingFiftynineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>1:100</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtyF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtyF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtyF"
                                      >
                                        What is the maximum volume that I can
                                        trade?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtyF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtyF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          100Lots, it's based on your
                                          investment.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtyoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtyoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtyoneF"
                                      >
                                        What's your Limit &amp; Stop Level?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtyoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtyoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          One spread out. That's 20% of stopping
                                          out.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtytwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtytwoF"
                                      >
                                        What is the password for read-only?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It is a public password that you can
                                          use to show your investor results and
                                          have no trading permissions.
                                        </p>
                                        <p>
                                          Please note: The read-only password
                                          should be different from the password
                                          of your trading account.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-7"
                    role="tabpanel"
                    aria-labelledby="pills-7-tab"
                  >
                    <div className="faqsub">
                      <div className="card">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtythreeF"
                                      >
                                        What's XAU?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The ticker symbol refers to Gold:
                                          XAU/USD
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtyfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtyfourF"
                                      >
                                        What's FOMO?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtyfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtyfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Fear of Missing Out. It is one of the
                                          great enemies of inexperienced
                                          traders.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtyfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtyfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtyfiveF"
                                      >
                                        What's WTI?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtyfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtyfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The ticker symbol refers to West Texas
                                          Intermediate or Crude Oil.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtysixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtysixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtysixF"
                                      >
                                        What's the US30 or the DJ30?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtysixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtysixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          These ticker symbols refer to the Dow
                                          Jones symbol.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtysevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtysevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtysevenF"
                                      >
                                        What's NFP?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtysevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtysevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Non-Farm Payment.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtyeightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtyeightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtyeightF"
                                      >
                                        What's RR?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtyeightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtyeightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>It's a short form of Reward/Risk.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSixtynineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSixtynineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSixtynineF"
                                      >
                                        What's FOMC?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSixtynineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSixtynineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          {" "}
                                          U.S.A-Federal Open Market Committee.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventyF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventyF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventyF"
                                      >
                                        What Are correlated Pairs?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventyF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventyF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Correlated pairs are two currency
                                          pairs that tend to move together. As
                                          in the case of EUR/USD and GBP/USD,
                                          NZD/USD and AUD/USD generally move in
                                          tandem.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventyoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventyoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventyoneF"
                                      >
                                        What's INSIDE DAY?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventyoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventyoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It refers to a daily candle with a
                                          lower high and lower than the previous
                                          daily candle and usually signals a
                                          continuation of the trend.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventytwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventytwoF"
                                      >
                                        Can I trade in volatile markets?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Yes. But trading can be risky at that
                                          time.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventythreeF"
                                      >
                                        Do you change your spreads during news
                                        releases?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Spreads are known to increase under
                                          volatile conditions, not by our side,
                                          but by our liquidity providers.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventyfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventyfourF"
                                      >
                                        Do you provide news on your platform?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventyfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventyfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Yes, we do.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventyfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventyfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventyfiveF"
                                      >
                                        Can I use bots or expert consultants?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventyfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventyfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Yes, that's possible. EAs are
                                          supported by all our trading
                                          platforms.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventysixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventysixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventysixF"
                                      >
                                        How can I add an expert advisor?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventysixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventysixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The first option to open the MT5
                                          Client Terminal is to click File at
                                          the top of the navigation menu. Click
                                          on MQL5 and Experts in the open data
                                          folder. You can add the expert
                                          advisers in the expert folder (EAs).
                                          In the Experts folder, paste the.mq5
                                          or.ex5 EA file. After this is
                                          completed, restart MT5 by closing and
                                          reopening it.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventysevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventysevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventysevenF"
                                      >
                                        Can I use the Expert Advisors (EAs)
                                        system?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventysevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventysevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Yes.</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventyeightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventyeightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventyeightF"
                                      >
                                        How can I open up a new trade/position?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventyeightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventyeightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Select 'Tools' and then 'New Order'
                                          from your trading platform. You can
                                          find the required data in the new
                                          window.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingSeventynineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeventynineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeventynineF"
                                      >
                                        How can an open position be closed?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseSeventynineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingSeventynineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Click on the trade to end with the
                                          right/double click.
                                        </p>
                                        <p>
                                          Click Close and then click Ok when the
                                          order window is opened.
                                        </p>
                                        <p>
                                          In the 'Account History' tab, you can
                                          view all closed positions.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightyF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightyF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightyF"
                                      >
                                        Can I get stop orders for trailing?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightyF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightyF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Oh Yes.</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightyoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightyoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightyoneF"
                                      >
                                        Simultaneously can I use a tool long and
                                        short?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightyoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightyoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Yes, the hedging is named.</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightytwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightytwoF"
                                      >
                                        How can I check my trading methods?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can test your business strategy by
                                          opening a demo trading account in
                                          order to see what kind of performance
                                          you can achieve using this specific
                                          strategy. You can also check back on
                                          past price data and see how many times
                                          you've won and lost based on your
                                          trade strategy and the settings. You
                                          can also check back.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightythreeF"
                                      >
                                        How can I build a successful trading
                                        strategy?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You must first try out various trading
                                          types and techniques to see which ones
                                          work best for you and then backtest
                                          before you find one that reliably
                                          works. Since everybody has a different
                                          trading personality, it does not need
                                          to work for everyone else except
                                          yourself.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightyfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightyfourF"
                                      >
                                        What is the formula for measuring my
                                        free margin?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightyfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightyfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Free Margin= Equity – Used Margin</p>
                                        <p>
                                          Free Margin = (Equity*Exposure) - Used
                                          margin
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-8"
                    role="tabpanel"
                    aria-labelledby="pills-8-tab"
                  >
                    <div className="faqsub">
                      <div className="card mt-3">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightyfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightyfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightyfiveF"
                                      >
                                        Where can I find the login information
                                        for my trading account?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightyfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightyfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          All your login account information
                                          will be sent to your registered email
                                          address. You can also reset your
                                          password at any time.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightysixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightysixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightysixF"
                                      >
                                        Where can I get news updates on my
                                        platform?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightysixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightysixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          After signing in, go to the 'Tools' of
                                          the platform.
                                        </p>
                                        <p>
                                          Select 'Server' from the 'Options'
                                          menu.
                                        </p>
                                        <p>
                                          Select 'OK' after clicking the 'Enable
                                          News' box.
                                        </p>
                                        <p>
                                          Please be aware of heavy economic
                                          announcements; the news tab catches
                                          additional CPU use, which may cause
                                          your platform to slow down.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightysevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightysevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightysevenF"
                                      >
                                        Why do I receive an error message that
                                        says Invalid parameters?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightysevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightysevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It means the Lot Size and Stop
                                          Loss/Takes profit settings need to be
                                          corrected.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightyeightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightyeightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightyeightF"
                                      >
                                        What is Day Trading?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightyeightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightyeightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p className="strong">
                                          Day Trading is when a trader buys and
                                          sells on the same day. It means he
                                          doesn't hold his position overnight or
                                          for a week.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingEightynineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEightynineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEightynineF"
                                      >
                                        Can I use indicators on my mobile
                                        trading platform?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseEightynineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingEightynineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Yes. To select the display, press the
                                          'f' symbol on the chart window.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintyF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintyF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintyF"
                                      >
                                        What does 'Authentication Fail' mean?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintyF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintyF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It means your user ID and password
                                          need to be corrected.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintyoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintyoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintyoneF"
                                      >
                                        What kinds of spreads do you have?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintyoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintyoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Variable spreads as low as 0.9 pips
                                          are available. Our clients get the
                                          market price that our system gets
                                          right away.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintytwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintytwoF"
                                      >
                                        What are short and long positions?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          A long position is a purchase/buying
                                          position, which means that this
                                          position will be in profit if the rate
                                          of exchange rises. A short position is
                                          a selling position, which means that
                                          this position will be profit-driven if
                                          the currency rate goes down.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintytwoF"
                                      >
                                        What's the margin?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Margin is the amount of money your
                                          broker account needs to secure an open
                                          spot. To keep your positions
                                          disclosed, different brokers need
                                          different amounts of margin money.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintythreeF"
                                      >
                                        Do you permit hedging?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Yes. You can secure your trading
                                          account positions. When you open a
                                          LONG and a SHORT on the same
                                          instrument, hedging occurs
                                          simultaneously.
                                        </p>
                                        <p>
                                          Position can be opened when covering
                                          Forex, Gold, and Silver, even when the
                                          margin level is under 100%, because
                                          the margin requirement is zero.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintythreeF"
                                      >
                                        Do you allow scalping?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>Yes, we do.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintyfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintyfourF"
                                      >
                                        What are Swing High and Swing Low mean?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintyfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintyfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          We refer to a swing high as a
                                          three-bar high, where the middle
                                          candle creates a high point, and the
                                          two adjacent candles close at a lower
                                          height. Swing down is the opposite.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintyfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintyfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintyfiveF"
                                      >
                                        What's a trading day?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintyfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintyfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It refers to a business entered and
                                          sold on the same trading day.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintysixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintysixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintysixF"
                                      >
                                        What's The Up Candle?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintysixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintysixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It is another term for a Bullish
                                          Candle.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintysevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintysevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintysevenF"
                                      >
                                        Which Is a Candle Down?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintysevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintysevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It is another term for bearish Candle
                                          reflection.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-9"
                    role="tabpanel"
                    aria-labelledby="pills-9-tab"
                  >
                    <div className="faqsub">
                      <div className="card mt-3">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintyeightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintyeightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintyeightF"
                                      >
                                        What are 'LOTS'?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintyeightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintyeightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lots refer to the specific units
                                          traded with currency pairs,
                                          essentially the number of currency
                                          units you wish to buy or sell. There
                                          are three types of lots: Micro Lot
                                          Size = 0.01 = Controls 1,000 currency
                                          units. Mini Lot Size = 0.10 = Controls
                                          10,000 currency units Standard Lot
                                          Size = 1.00 Lot = Controls 100 000
                                          currency units
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintynineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintynineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintynineF"
                                      >
                                        What's the WICK?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintynineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintynineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The WICK is the vertical line above
                                          and below the candle's body.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNinetyF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNinetyF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNinetyF"
                                      >
                                        What's a CANDLESTICK?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNinetyF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNinetyF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Candlesticks (or Japanese
                                          candlesticks) is a chart style used to
                                          represent currency price movements.
                                          Each candlestick is a specific amount
                                          of time, depending on the timeframe.
                                          If you have a timeframe of 5 minutes,
                                          each candle is equivalent to 5 minutes
                                          (M5). If you are on the Daily (D1)
                                          timeframe, each candle is 24 hours.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintyoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintyoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintyoneF"
                                      >
                                        What's the value of a PIP?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintyoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintyoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p className="strong">
                                          If your account is in US Dollars and
                                          you are trading a pair of USDs as a
                                          base currency (e.g., INR/USD), then
                                          the following is true: One pip trading
                                          0.01 (Micro Lot) equals USD 0.10. One
                                          pip trading at 0.10 (Mini Lot) equals
                                          USD 1.00. One pip trading with 1.00
                                          (Standard Lot) is equivalent to USD
                                          10.00
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintytwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintytwoF"
                                      >
                                        What's BULLISH?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Say, being a bull or having a bullish
                                          market means that you believe a
                                          currency pair will increase in value
                                          or go up. For example, saying bullish
                                          on USD/INR implies the US dollar price
                                          will rise against the Indian INR.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintythreeF"
                                      >
                                        What Is BEARISH?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Being a bear or having a bearish time
                                          means that you believe a currency pair
                                          will fall in value or go down. For
                                          example, saying it's bearish on
                                          EUR/USD means that he considers the
                                          euro price will fall against the US
                                          dollar.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintyfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintyfourF"
                                      >
                                        What is LIMIT ORDER mean?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintyfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintyfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Limit orders are placed if you are
                                          only willing to enter a new position
                                          at a specific price. It will only fill
                                          if the market trades at that price. A
                                          purchase-limit order is an order to
                                          purchase a currency pair at a market
                                          price once the market has reached or
                                          lowered your specified price; that
                                          price must be lower than the current
                                          market price. A limit order is an
                                          order to sell a currency pair at a
                                          market price when the market reaches
                                          or exceeds your specified price; that
                                          price must be higher than the current
                                          market price.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintyfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintyfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintyfiveF"
                                      >
                                        What's the MARKET ORDER?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintyfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintyfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          A market order is a direct entry into
                                          markets with the option of buying or
                                          selling at the current market price.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintysixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintysixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintysixF"
                                      >
                                        What's Leverage?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintysixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintysixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Leverage allows you to manage vast
                                          amounts of money by using a limited
                                          amount of your own. For example, with
                                          a $1,000 account trading on a 100:1
                                          leverage, you'll be able to control a
                                          $100,000 position.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintysevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintysevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintysevenF"
                                      >
                                        What's the STOP ORDER?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintysevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintysevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          A stop order will only become a market
                                          order once the specified price has
                                          been reached. Buy-stop order is an
                                          order to purchase a currency pair at a
                                          market price when the market reaches
                                          or exceeds your fixed price; that
                                          purchase price must be higher than the
                                          current market price. A sales stop is
                                          an order to sell a currency pair at a
                                          market price once the market reaches
                                          your set price or lower price. That
                                          selling price must be lower than the
                                          current market price.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintyeightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintyeightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintyeightF"
                                      >
                                        What is Margin?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintyeightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintyeightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Margin is the amount you have to pay
                                          to open a business with your account
                                          in a good faith deposit. For example,
                                          the $1,000 margin traded at 100:1
                                          leverage is your margin.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingNintynineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseNintynineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseNintynineF"
                                      >
                                        Which is a margin level?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseNintynineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingNintynineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It is the threshold at which your
                                          broker will notify you before they
                                          cancel or close your positions.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredF"
                                      >
                                        What's a MARGIN CALL?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It means that you are over-leveraged
                                          on your account or need more margin,
                                          and your broker liquidates your
                                          position forcibly, thereby closing
                                          your trade(s) at market price.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredoneF"
                                      >
                                        Which is the tool for the FIB or the
                                        FIBONACCI?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          To measure two swing points in price
                                          (elevated and low), the Fibonacci is a
                                          commonly used technical trade tool
                                          dividing the vertical range into
                                          percentages. It covers the retracing
                                          and extension of Fibonacci.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredtwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredtwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredtwoF"
                                      >
                                        What is your leverage?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredtwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredtwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>The leverage depends on equity.</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredthreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredthreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredthreeF"
                                      >
                                        What's the S&R?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredthreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredthreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Support refers to significant levels
                                          below current market prices, and
                                          Resistance refers to significant
                                          levels above market prices. These
                                          levels are remarkable areas in which
                                          prices have reacted in the past and
                                          may do so again.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredfourF"
                                      >
                                        What Is the Retracement Fibonacci?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It refers to retracements as price
                                          percentages.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredfiveF"
                                      >
                                        What Is Risk Management and How Does It
                                        Work?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It applies to how you manage your
                                          trading account's risks. The general
                                          thumb rule is never to risk more than
                                          2% per exchange.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredsixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredsixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredsixF"
                                      >
                                        What's the swap-free fee?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredsixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredsixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It is a fee for keeping each
                                          instrument's position open over a
                                          certain period. You can find full
                                          details of each tool on our Contract
                                          Specifications page under the Swap
                                          Free Fee and Swap Free Day Allowance
                                          sections.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-10"
                    role="tabpanel"
                    aria-labelledby="pills-10-tab"
                  >
                    <div className="faqsub">
                      <div className="card mt-3">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredsevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredsevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredsevenF"
                                      >
                                        How much capital do I need to start
                                        forex trading?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredsevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredsevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can begin forex trading with as
                                          little as $10.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredeightF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredeightF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredeightF"
                                      >
                                        How can I withdraw the money from my
                                        account?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredeightF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredeightF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Go to the withdrawal section in the
                                          'Wallet' section of My Milliva. Select
                                          the payment method and click the
                                          'Draw' button. Select the wallet from
                                          which you wish to withdraw the funds
                                          and complete the necessary fields.
                                          Select the reason for withdrawal,
                                          enter the PIN you received via email
                                          or SMS, and click 'Submit.' Please
                                          note that your profile must be fully
                                          verified to request a withdrawal.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundrednineF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundrednineF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundrednineF"
                                      >
                                        What should I do if the card is lost?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundrednineF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundrednineF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You will need to send us an official
                                          document from the card issuer stating
                                          that the card has been canceled or
                                          lost, and we will inform you how to
                                          proceed.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredtenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredtenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredtenF"
                                      >
                                        Are there any additional steps I should
                                        take if I withdraw via E-Wallet?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredtenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredtenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p className="strong">
                                          Your withdrawal will be processed
                                          automatically if your e-wallet has
                                          already been checked.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredlevenF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredlevenF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredlevenF"
                                      >
                                        Can I still withdraw if I have an open
                                        position? How long does it take to
                                        process my withdrawal?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredlevenF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredlevenF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Our Back Office Department will
                                          process all withdrawal requests within
                                          24 hours, but the time required for
                                          the funds to be transferred to your
                                          account will depend on the payment
                                          method.
                                        </p>
                                        <p>
                                          You will have sufficient free margin
                                          on your account to cover the
                                          withdrawal and any additional fees
                                          that may arise.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredtwelveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredtwelveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredtwelveF"
                                      >
                                        Should I follow other steps while
                                        withdrawing through a local payment
                                        solution?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredtwelveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredtwelveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You must check whether you used this
                                          form to make a deposit.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredthirtinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredthirtinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredthirtinF"
                                      >
                                        How can I transfer money between my
                                        commercial accounts?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredthirtinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredthirtinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Go to the 'Internal Transfer' page in
                                          My Milliva 'Wallet.'
                                        </p>
                                        <p>
                                          Choose the amount from the account you
                                          want to transfer funds to and click
                                          'Confirm.'
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredfourtinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredfourtinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredfourtinF"
                                      >
                                        Are internal transfers
                                        restricted/limited?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredfourtinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredfourtinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can make internal transfers
                                          between your business accounts And
                                          these accounts can make transfers in
                                          currency. Internal transfers have no
                                          minimum/maximum limit. We will email
                                          you to confirm that you would like
                                          your request to proceed.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredfiftinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredfiftinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredfiftinF"
                                      >
                                        What are the minimum/maximum
                                        deposit/withdrawal limits?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredfiftinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredfiftinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Limits are set individually by payment
                                          providers. The minimum/maximum limit
                                          for each method can be found on
                                          Milliva.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredsixtinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredsixtinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredsixtinF"
                                      >
                                        Do you charge internal transfers?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredsixtinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredsixtinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No, all internal transfers are free.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredseventinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredseventinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredseventinF"
                                      >
                                        How much time does it take for an
                                        internal transfer to be processed?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredseventinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredseventinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Internal transfers shall be processed
                                          within minutes. If you have any
                                          delays, don't hesitate to contact our
                                          Back Office department.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredeightinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredeightinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredeightinF"
                                      >
                                        My deposit/withdrawal has been refused;
                                        what should I do?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredeightinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredeightinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          If your deposit has been refused,
                                          there should be an email in your inbox
                                          explaining the decline.{" "}
                                        </p>
                                        <p>
                                          You can also check your
                                          deposit/withdrawal status in Milliva.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundrednintinF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundrednintinF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundrednintinF"
                                      >
                                        Why do internal transfers decline?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundrednintinF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundrednintinF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It might be due to the need for more
                                          free margin.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredtwentyF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredtwentyF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredtwentyF"
                                      >
                                        What is card decline mean?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredtwentyF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredtwentyF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p className="strong">
                                          Your request may have been rejected
                                          for one of the reasons given below:
                                        </p>
                                        <p>
                                          1. It may be due to insufficient
                                          balance.
                                        </p>
                                        <p>
                                          2.No trading activity from the last
                                          deposit
                                        </p>
                                        <p>3. Details need to be corrected. </p>
                                        <p>
                                          4. The withdrawal procedure differs
                                          from the payment method used for the
                                          deposit.{" "}
                                        </p>
                                        <p>
                                          5. Credit/Debit card withdrawal
                                          exceeds the amount of your deposit.{" "}
                                        </p>
                                        <p>
                                          6. No further requested information
                                          was submitted.{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredtwentyoneF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredtwentyoneF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredtwentyoneF"
                                      >
                                        What should I do if my e-wallet /Local
                                        payment cancellation is not received?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredtwentyoneF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredtwentyoneF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Please get in touch with our back
                                          office for more information if you do
                                          not receive your withdrawal.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredtwentytwoF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredtwentytwoF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredtwentytwoF"
                                      >
                                        What methods of deposit/withdrawal do
                                        you offer?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredtwentytwoF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredtwentytwoF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Milliva offers various payment
                                          methods, including credit and debit
                                          cards, bank wire transfers, e-wallets,
                                          and local payment solutions.{" "}
                                        </p>
                                        <p>
                                          You should note that we do not accept
                                          cash deposits.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredtwentythreeF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredtwentythreeF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredtwentythreeF"
                                      >
                                        Are there any steps to take while
                                        depositing via e-wallet?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredtwentythreeF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredtwentythreeF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          After completing the steps outlined in
                                          'How do I deposit funds into my
                                          account?' You will be redirected to
                                          your selected e-wallet's website to
                                          approve your transaction. Please note
                                          that some e-wallets may require
                                          additional information.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-11"
                    role="tabpanel"
                    aria-labelledby="pills-11-tab"
                  >
                    <div className="faqsub">
                      <div className="card mt-3">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredtwentyfourF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredtwentyfourF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredtwentyfourF"
                                      >
                                        Why is my account balance different from
                                        what I have deposited?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredtwentyfourF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredtwentyfourF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It may be due to additional charges
                                          incurred by your payment
                                          provider/bank. Please contact our
                                          payment processor for further
                                          clarification if this is the case, as
                                          we do not have access to third-party
                                          service systems.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredtwentyfiveF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredtwentyfiveF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredtwentyfiveF"
                                      >
                                        How to make deposits using local payment
                                        solutions?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredtwentyfiveF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredtwentyfiveF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Contact our dedicated account service
                                          manager for step-by-step information
                                          on depositing using local payment
                                          solutions.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingHundredtwentysixF"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseHundredtwentysixF"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseHundredtwentysixF"
                                      >
                                        Why is my request for a deposit get
                                        refused?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseHundredtwentysixF"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingHundredtwentysixF"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p className="strong">
                                          Your request may have been rejected
                                          for one of the reasons given below:{" "}
                                        </p>
                                        <p>
                                          - Card hasn't been active for online
                                          activities for some time.{" "}
                                        </p>
                                        <p>
                                          - Payment declined by card issuer-3D
                                          security (Credit/Debit Card).{" "}
                                        </p>
                                        <p>- Incorrect card details. </p>
                                        <p>- System Error. </p>
                                        <p>- Invalid email of the user. </p>
                                        <p>
                                          - Closed Account-Miscellaneous account
                                          number.{" "}
                                        </p>
                                        <p>
                                          - Miscellaneous purpose of payment
                                          (bank – wire).{" "}
                                        </p>
                                        <p>
                                          - Information of invalid recipients.{" "}
                                        </p>
                                        <p>
                                          Don't hesitate to contact your Account
                                          Service Manager, Customer Support, or
                                          Back Office if you need further
                                          clarification.{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingfourXX"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsefourXX"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsefourXX"
                                      >
                                        Can I deposit using a method that isn't
                                        in my name?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsefourXX"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingfourXX"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p className="strong">
                                          No, we do not accept transfers from
                                          third parties. The sender's name must
                                          match the name used to register with
                                          Milliva.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingthreeXX"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsethreeXX"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsethreeXX"
                                      >
                                        Who's allowed to use wallets?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsethreeXX"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingthreeXX"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>All Milliva registered customers.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingfiveXX"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsefiveXX"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsefiveXX"
                                      >
                                        Can I deposit if the current balance is
                                        negative?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsefiveXX"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingfiveXX"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Yes, you can. Please get in touch with
                                          Customer Support or our Back Office
                                          Department to correct the negative
                                          balance on your account.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingsixXX"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsesixXX"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsesixXX"
                                      >
                                        Can I use a corporate account to deposit
                                        to my trading account?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsesixXX"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingsixXX"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The deposit may be processed if you
                                          are the sole shareholder, but the
                                          withdrawal must be returned to the
                                          same bank account as the initial
                                          deposit.
                                        </p>
                                        <p>
                                          If there are several shareholders: we
                                          need the written consent of all
                                          shareholders. Once written permission
                                          has been received, the funds will be
                                          processed. If a withdrawal is
                                          requested, the funds will be returned
                                          to the same bank account as the
                                          initial deposit.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingsevenXX"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsesevenXX"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsesevenXX"
                                      >
                                        Which countries do you permit local
                                        payment solutions in?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsesevenXX"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingsevenXX"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          We offer a variety of local payment
                                          solutions for different countries; you
                                          can find a complete list of these
                                          options on Milliva.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingeightXX"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseeightXX"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseeightXX"
                                      >
                                        What are wallets?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseeightXX"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingeightXX"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Wallets are created so that when you
                                          open your MY Milliva account. Wallets
                                          can be used to make deposits,
                                          withdrawals, and internal transfers to
                                          and from your trading account in
                                          different currencies or to invest.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingnineXX"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsenineXX"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsenineXX"
                                      >
                                        Where can I find the status of my
                                        deposit/ withdrawal of internal
                                        transfer?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsenineXX"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingnineXX"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can find full details of your
                                          transactions in the History section by
                                          following the steps below.
                                        </p>
                                        <p>
                                          Go to the Transaction History section.
                                        </p>
                                        <p>Find the transaction in question.</p>
                                        <p>
                                          Click on the number of the transaction
                                          ID.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingtenXX"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsetenXX"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsetenXX"
                                      >
                                        How can I deposit the money into my
                                        account?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsetenXX"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingtenXX"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Open the Deposit page in the My
                                          Milliva Deposit section.
                                        </p>
                                        <p>
                                          Select the deposit method you prefer
                                          and click the Deposit button.
                                        </p>
                                        <p>
                                          Choose your wallet and fill out the
                                          fields you want to deposit money.
                                        </p>
                                        <p>
                                          Confirm on the next page your deposit
                                          information. You can ask your payment
                                          provider for some additional steps.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingelevenXX"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseelevenXX"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseelevenXX"
                                      >
                                        How long does it take to process the
                                        deposit?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseelevenXX"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingelevenXX"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Details about the processing time for
                                          each payment method can be found on
                                          the Deposits and Withdrawals page{" "}
                                        </p>
                                        <p>
                                          The processing time may take a while
                                          if we need to verify additional
                                          information. Please note that Milliva
                                          is not liable for any delays caused by
                                          the third-party payment processor.{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingtwelveXX"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsetwelveXX"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsetwelveXX"
                                      >
                                        When will it be processed if I deposit
                                        on the weekend or bank holiday?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsetwelveXX"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingtwelveXX"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The deposits made during the
                                          weekend/bank holidays shall be
                                          processed according to the standard
                                          processing times for each payment
                                          method.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-12"
                    role="tabpanel"
                    aria-labelledby="pills-12-tab"
                  >
                    <div className="faqsub">
                      <div className="card mt-3">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingOneXY"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOneXY"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOneXY"
                                      >
                                        Can I withdraw money if I have an open
                                        position?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseOneXY"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingOneXY"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Yes, you can do that. However, some
                                          restrictions apply to ensure the
                                          safety of our client’s business.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingtwoXY"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsetwoXY"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwoXY"
                                      >
                                        What payment options are there to
                                        deposit/withdraw money?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsetwoXY"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwoXY"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          <b>
                                            Milliva offers a wide range of
                                            payment options for
                                            deposits/Withdrawals:
                                          </b>
                                        </p>
                                        <li>Multiple credit cards</li>
                                        <li>
                                          Multiple electronic payment methods
                                        </li>
                                        <li>Bank wire transfer</li>
                                        <li>Local bank transfer</li>
                                        <li>Crypto</li>
                                        <li>Other payment methods</li>
                                        <p>
                                          The payment mode should be the same
                                          for both deposit and withdrawal. After
                                          opening a trading account, you can log
                                          in to our wallet area. Choose the
                                          payment method on your preferences on
                                          the Deposits/Withdrawal pages, and
                                          follow the instructions provided.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingtenXY"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsetenXY"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsetenXY"
                                      >
                                        Are you going to inform the tax
                                        authority if I make a profit?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsetenXY"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingtenXY"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Yes, only if regional legislation/
                                          regulations need them.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingfourXY"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsefourXY"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsefourXY"
                                      >
                                        Do I need to pay taxes on my profits?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsefourXY"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingfourXY"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p className="strong">
                                          Paying taxes is your responsibility.
                                          Please refer to the legislation
                                          governing your region or seek
                                          independent advice.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingthreeXY"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsethreeXY"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsethreeXY"
                                      >
                                        Do we lose the deposited money?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsethreeXY"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingthreeXY"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No, you won’t lose the amount you have
                                          deposited. If the slippage of a
                                          currency pair causes a negative
                                          balance, it will be reset
                                          automatically with your next deposit.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="modal-footer" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-13"
                    role="tabpanel"
                    aria-labelledby="pills-13-tab"
                  >
                    <div className="faqsub">
                      <div className="card mt-3">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingOneXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOneXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOneXYZ"
                                      >
                                        What is the procedure to become a member
                                        of your partnership?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseOneXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingOneXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can register as a broker or
                                          affiliate by visiting the Milliva
                                          Partners website.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingtwoXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsetwoXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwoXYZ"
                                      >
                                        Is there any registration fee to join?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsetwoXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwoXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No, there is no registration fee for
                                          joining.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingtenXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsetenXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsetenXYZ"
                                      >
                                        What's a link to a referral?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsetenXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingtenXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It is the unique connection between
                                          all affiliates and the introductory
                                          brokers. Once a client registers with
                                          your link, the client will be assigned
                                          to you.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingfourXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsefourXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsefourXYZ"
                                      >
                                        Can I follow up on my referrals?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsefourXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingfourXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p className="strong">
                                          By clicking 'My Reports,' you can get
                                          a full report of your referrals to
                                          your IB/Affiliate panel.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingthreeXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsethreeXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsethreeXYZ"
                                      >
                                        Can I be both an Introducing Broker and
                                        an Affiliate simultaneously?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsethreeXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingthreeXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No, you can only choose one program.
                                          For benefits and more details on each
                                          program, please refer to the
                                          Affiliates and Introductory Broker
                                          pages.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingfourXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsefourXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsefourXYZ"
                                      >
                                        What are the options for withdrawing my
                                        commissions/rebates?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsefourXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingfourXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Go to your Affiliate/IB panel and
                                          click 'Fund Withdrawal.'
                                        </p>
                                        <p>
                                          Select a withdrawal method and
                                          complete the necessary fields.
                                        </p>
                                        <p>
                                          Click the 'Confirm' button to submit
                                          your submission.
                                        </p>
                                        <p>
                                          Please keep in mind that your
                                          affiliate/IB profile must be checked
                                          to make a withdrawal.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingfiveXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsefiveXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsefiveXYZ"
                                      >
                                        Are there any limitations on how much
                                        money I can make in a month?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsefiveXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingfiveXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Your monthly earnings can be
                                          unlimited. You make more money if your
                                          clients are more involved.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingsixXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsesixXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsesixXYZ"
                                      >
                                        Does the payout be minimal?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsesixXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingsixXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No, you will still be paid
                                          accordingly, even if your customers
                                          earned you a couple of cents.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingsevenXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsesevenXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsesevenXYZ"
                                      >
                                        Are there any limitations on how long a
                                        client can work for me?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsesevenXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingsevenXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          There is no limit. You can continue to
                                          earn as long as your client trades
                                          with Milliva.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingeightXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseeightXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseeightXYZ"
                                      >
                                        Who is qualified to work as an
                                        Introducing Broker?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseeightXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingeightXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The IB software is for people who seek
                                          to expand their company and have a
                                          vast network of customers, such as
                                          strategy suppliers, coaches, forex
                                          instructors, or regional
                                          representatives.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingnineXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsenineXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsenineXYZ"
                                      >
                                        When can I start referring clients?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsenineXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingnineXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          As soon as you register, you can begin
                                          sharing your Referral connection with
                                          clients.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingtenXYZ"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsetenXYZ"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsetenXYZ"
                                      >
                                        What should I do need to become a
                                        verified IB?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsetenXYZ"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingtenXYZ"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Fill out the form in your IB panel's
                                          'Personal Details' section.
                                        </p>
                                        <p>
                                          Upload a valid photo ID, such as a
                                          passport, ID card, driver's license,
                                          and Proof of Residence document (POR).
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="modal-footer" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane"
                    id="pills-14"
                    role="tabpanel"
                    aria-labelledby="pills-14-tab"
                  >
                    <div className="faqsub">
                      <div className="card mt-3">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 col-lg-12">
                              <div className="modal-body">
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingOne123"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne123"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne123"
                                      >
                                        What instruments can I trade for the
                                        MT5?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseOne123"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingOne123"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          You can trade all the instruments
                                          available at Milliva on the MT5
                                          platform, including Stock, Forex,
                                          Stock Indices, Precious Metals,
                                          Crypto, and Energies.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingtwo123"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsetwo123"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwo123"
                                      >
                                        How can I see all the pairs?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsetwo123"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingTwo123"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          <b>Log in to your MT5 platform</b>{" "}
                                        </p>
                                        <li> Market watch </li>
                                        <li> Right click </li>
                                        <li> Show all </li>
                                        <li>
                                          {" "}
                                          Scroll down, and you'll see all the
                                          pairs and tools available for trading.{" "}
                                        </li>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingten123"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseten123"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseten123"
                                      >
                                        For the MT5, what operating system (OS)
                                        do I need?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapseten123"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingten123"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p> From Windows 7 onwards.</p>
                                        <p>Mac: From Mac OS X onwards.</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="flush-headingfour123"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapsefour123"
                                        aria-expanded="false"
                                        aria-controls="flush-collapsefour123"
                                      >
                                        What trading sites are there to choose
                                        from?
                                      </button>
                                    </h2>
                                    <div
                                      id="flush-collapsefour123"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingfour123"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          {" "}
                                          MT5 is available on a range of desktop
                                          and mobile computers, as well as on
                                          iOS.
                                        </p>
                                        <p>
                                          {" "}
                                          You can check for more details on our
                                          trading platform website.
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="modal-footer" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* register sub end------------------- */}

                  {/* tab-table 5 end------------------------------------------ */}
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqm2;
