import React, { useEffect, useRef } from "react";
import "../../assets/css/trading.css";
import { FaBtc } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const Trading = () => {
  const exchangerate = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js";

    script.async = false;

    script.innerHTML = JSON.stringify({
      width: "100%",
      height: 400,
      currencies: ["EUR", "USD", "JPY", "GBP", "CHF", "AUD", "CAD", "NZD"],
      isTransparent: false,
      colorTheme: "light",
      locale: "in",
    });
    exchangerate.current.appendChild(script);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);

  return (
    <div>
      <>
        <div className="trading-value">
          <section className="mt5trade">
            <div className="mt5cont">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6 ">
                    <h1 className=" mb-2">Learn Forex With Us!</h1>
                    <p className=" mb-3">
                      With us, you will find all the trading basics you need to
                      know to start your journey by learning the global market
                      with Milliva
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/mani/forex-trade.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trading-conclusion mt-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-10 col-md-12 col-12 col-sm-12">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                      <div className="trade-conclusion1 d-flex justify-content-between mt-4">
                        <a href="#milliva">
                          <img
                            src={require("../../assets/images/mani/tr1.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <span>How does Milliva work?</span>
                        </a>
                        <a href="#trade">
                          <img
                            src={require("../../assets/images/mani/tr2.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <span>What assets are traded?</span>
                        </a>
                        <a href="#earn" className="Milliva1">
                          <img
                            src={require("../../assets/images/mani/tr3.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <span>How to earn on Milliva?</span>
                        </a>
                        <a href="#stragy">
                          <img
                            src={require("../../assets/images/mani/tr4.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <span>What strategy to choose?</span>
                        </a>
                        <a href="#begin">
                          <img
                            src={require("../../assets/images/mani/tr5.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <span>How to begin?</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <section className="milliva-trading-content mt-5">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                          <div className="milli-trade1 mt-5">
                            <h2 className="px-3 py-2">
                              What Is Milliva Trading?
                            </h2>
                            <p>
                              <i className="altick">
                                <FaCheck />
                              </i>
                              Forex Trading is the process of buying and selling
                              currencies. This combination of selling and buying
                              currency is known as a currency pair. Each
                              currency in a pair is represented as a unique
                              three-letter code separated by a slash. The first
                              two letters are represented as the country name
                              and the third as the currency name.
                            </p>
                            <p>
                              <i className="altick">
                                <FaCheck />
                              </i>
                              For example, Euro-Dollar is quoted as EUR/USD or
                              simply EURUSD. The currency quoted on the left
                              side is called the base currency which is to be
                              bought and the currency placed on the right side
                              is called quote currency Which is to be sold. In
                              this currency pair EUR/USD, EUR- base currency,
                              and USD-quote currency. To buy 1 Euro, you have to
                              pay 1.23700 US dollars.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                          <div className="milli-trade-image mt-3">
                            <img
                              src={require("../../assets/images/mani/millivatrade.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="milliva-trading-work mt-5" id="milliva">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                          <h2 className="px-3">How Does Forex Work?</h2>
                          <p>
                            <i className="altick">
                              <FaCheck />
                            </i>
                            Forex is the largest financial market that is linked
                            together by a shared ECN.Its players include huge
                            banks, investment funds, and retail traders who
                            influence currency rates but not small businesses
                            and retail traders, instead, they can get access
                            through a third party, such as a brokerage firm or
                            dealing center. Retail traders are drawn to Currency
                            trading because the currency market is a convenient
                            source of income. To back up this claim, Milliva
                            recommends various types of trading accounts.
                          </p>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                          <div className="work-image">
                            <img
                              src={require("../../assets/images/mani/pic4.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                          <div className="work-content1">
                            <p>
                              <i className="altick">
                                <FaCheck />
                              </i>
                              Milliva is a global financial market united by the
                              common communication network. Its participants are
                              large banks, investment funds, and individual
                              traders who also make an impact on exchange rates.
                              Small firms and retail traders are not financially
                              sound to enter the currency market on their own.
                              Nevertheless, they can afford to gain access
                              through a third party such as a brokerage firm or
                              dealing center.
                            </p>
                            <p>
                              <i className="altick">
                                <FaCheck />
                              </i>
                              Trading on Milliva appeals to individual traders
                              because the currency market is an easily available
                              source of income. To confirm this statement,
                              Milliva suggests different types of trading
                              accounts. Each of them stipulates its own size of
                              a start-up deposit and a kit of particular
                              services. All in all, Milliva is the right market
                              for traders who can spare even a modest amount to
                              get started. A retail investor or trader alone can
                              hardly change market sentiment on Milliva.
                              Nevertheless, a multitude of private traders is
                              one of the crucial forces to influence exchange
                              rates of assets alongside large market players.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="col-lg-2 col-md-12 col-12 col-sm-12">
                  <div className="trade-product3">
                    <div className=" marketanlysec4 mt-4">
                      <div className="d-flex exchnage justify-content-between">
                        <div className="exchnage1">
                          <h6>Exchange rates</h6>
                        </div>
                      </div>

                      <div ref={exchangerate}></div>
                    </div>
                    <div className=" tradingins mt-3">
                      <h6 className="text-center">Trading Instruments</h6>
                      <p className="text-center">
                        The best instruments for successful online Forex trading
                      </p>
                      <ul className="traditems d-block justify-content-center align-items-center">
                        <a href="https://crm.milliva.com/opendemoaccount">
                          <li>
                            <img
                              src={require("../../assets/images/mtdemoicon1.png")}
                              alt="logo"
                              className="img-fluid mx-2"
                            />
                            Open Demo Account
                          </li>
                        </a>
                        <Link to="/mt5">
                          <li>
                            <img
                              src={require("../../assets/images/metatradeicon1.png")}
                              alt="logo"
                              className="img-fluid mx-2"
                            />
                            Trading Platform
                          </li>
                        </Link>
                        <Link to="/pipcalc">
                          <li>
                            <img
                              src={require("../../assets/images/tradecalc.png")}
                              alt="logo"
                              className="img-fluid mx-2"
                            />
                            Trade calculator
                          </li>
                        </Link>
                        <Link to="/currency">
                          <li>
                            <img
                              src={require("../../assets/images/mtanalyicon1.png")}
                              alt="logo"
                              className="img-fluid mx-2"
                            />
                            Assets
                          </li>
                        </Link>
                        <Link to="/tradingfee">
                          <li>
                            <img
                              src={require("../../assets/images/mtindicatoricon1.png")}
                              alt="logo"
                              className="img-fluid mx-2"
                            />
                            Trading Fees
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="milliva-trading-work-box px-3" id="trade">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="work-path mt-5 pt-5">
                    <h2 className="px-3">What Assets Are Traded</h2>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex ">
                  <div className="work-content2 mt-5">
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      Currency pairs are the best trading assets in Forex
                      trading. Milliva includes the Major currency pairs as
                      EUR/USD, USD/JPY, USD/CHF, and GBP/USD.Cross-currency
                      pairs in Milliva are GPP/JPY, EUR/JPY, EUR/AUD, and much
                      more.
                    </p>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      The full list of currency pairs offered for trading with
                      Milliva is provided on the appropriate web page. Besides,
                      any Milliva instrument is also available for CFD trading
                      under beneficial conditions.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex justify-content-center">
                  <div className="working-image1 ">
                    <img
                      src={require("../../assets/images/mani/pair.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="exchange-page  px-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="exchange-rates mt-3">
                    <h2>
                      What are exchange rates and market quotes on Milliva
                    </h2>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      The price of any asset in a financial market fluctuates
                      constantly. A currency pair trades at a price quote of the
                      exchange rate for the base currency to the quoted
                      currency. Such a price quote is marked by a point in a
                      technical chart. Any point is automatically connected to a
                      previous asset’s quote. This is the principle of how
                      Milliva charts are plotted online for a particular trading
                      instrument.
                    </p>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      The screen of a trading platform presents two values of an
                      asset’s quote. The lower value is called the bid price,
                      the upper one is the ask price. Speaking about the chart
                      of the EUR/USD pair, the bid value is the price at which
                      the euro is sold and a certain amount of bucks are bought.
                      Conversely, the ask value is the price to buy the euro and
                      sell some US dollars.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="exchange-rates1 mt-3 py-3 text-center">
                    <strong>Bid</strong>
                    <h6>Cost of sell</h6>
                    <p>
                      1.19<small className="text-danger">18</small>
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="exchange-rates1 mt-3 text-center">
                    <div className="rates2 d-flex">
                      <img
                        src={require("../../assets/images/mani/USA.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                      <img
                        src={require("../../assets/images/mani/EUR.png")}
                        alt="logo"
                        className="img-fluid ms-auto"
                      />
                    </div>
                    <div className="rates3 d-flex">
                      <h2>EUR</h2>
                      <h2 className="ms-auto">USD</h2>
                    </div>
                    <div className="rates4 d-flex">
                      <span>1.1918</span>
                      <span className="ms-auto">1.1920</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="exchange-rates1 mt-3 py-3 text-center">
                    <strong>Ask</strong>
                    <h6>Cost of buying</h6>
                    <p>
                      1.19<small className="text-danger">20</small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="rate-market mt-3">
                    <strong>Let’s find out the most significant ones:</strong>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="rate-market2 d-flex">
                    <div className="rate1 d-flex align-items-center">
                      <img
                        src={require("../../assets/images/mani/money.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                    <div className="rate-2 mt-3 px-3">
                      <strong> 1. Money issuance.</strong>
                      <p className="mt-2">
                        If a government actively prints out banknotes, this
                        expands supply of its national currency. At some point,
                        supply exceeds demand that drags down the Milliva rate
                        of this national currency.
                      </p>
                    </div>
                  </div>
                  <div className="rate-market2 d-flex">
                    <div className="rate3 d-flex align-items-center">
                      <img
                        src={require("../../assets/images/mani/foreign.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                    <div className="rate-4 mt-3 px-3">
                      <strong> 2. Foreign trade balance.</strong>
                      <p className="mt-2">
                        A country with robust exports ensures high demand for
                        its national currency. On the contrary, imports account
                        for currency supply, so an increase in imports makes a
                        national currency fall in value.
                      </p>
                    </div>
                  </div>
                  <div className="rate-market2 d-flex">
                    <div className="rate5 d-flex align-items-center">
                      <img
                        src={require("../../assets/images/mani/reliable.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                    <div className="rate-5 mt-3">
                    <strong>
                      An exchange rate in the currency market depends on a
                      variety of factors.
                          
                    </strong> 
                      <p className="mt-2">
                        A country with robust exports ensures high demand for
                        its national currency. On the contrary, imports account
                        for currency supply, so an increase in imports makes a
                        national currency fall in value.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="more-currency">
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      Moreover, exchange rates of currency pairs are sensitive
                      to a number of factors such as natural disasters, verbal
                      interventions of influential policymakers, macroeconomic
                      data, and others. To predict a further trajectory of a
                      financial instrument, a trader should be well aware of the
                      particular factors which make an impact on the asset
                      picked for trading.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12" id="earn">
                  <div className="miliva-earn">
                    <h2>Is it possible to Earn on Milliva?</h2>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      Milliva offers a service for copying transactions of
                      successful traders in real time. Every user has access to
                      trading information from successful and experienced
                      traders who share their expertise and skills, allowing all
                      players to profit.
                    </p>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      In fact, anyone is capable of earning in the currency
                      market. The key to making steady profits is nonstop
                      learning. It is not good for a trader to click at random
                      buttons on a trading platform. A trader should gain
                      essential skills to make market reviews and accurate
                      forecasts of a further market situation. The inability to
                      predict market developments is the main reason why
                      beginners lose 3-4 times bigger money than they actually
                      earn. To make highly accurate forecasts that will bring
                      profits, a trader should bear in mind a great deal of
                      factors that could act as market catalysts.
                    </p>
                  </div>
                </div>
              </div>
              <div className="condiprofit mt-4">
                <h2 className="text-center">Conditions for Making Profit</h2>
                <div className="row mt-4">
                  <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                    <div className="earn-2  py-3 text-center">
                      <img
                        src={require("../../assets/images/mani/support.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                      <p>Support from reliable licensed broker</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                    <div className="earn-2  py-3 text-center">
                      <img
                        src={require("../../assets/images/mani/learning.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                      <p>Regular learning</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                    <div className="earn-2  py-3 text-center">
                      <img
                        src={require("../../assets/images/mani/book.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                      <p>Attention to theory</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4 pb-5">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="earn-1 text-center mt-4">
                    <h2>Risk Factors</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12 mt-2">
                  <div className="earn-2 mt-3 py-3 text-center cn1">
                    <img
                      src={require("../../assets/images/mani/country.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                    <p>
                      Political and economic situation in the world and in
                      particular countries
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12 mt-2">
                  <div className="earn-2 mt-3 py-3 text-center cn1">
                    <img
                      src={require("../../assets/images/mani/news.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                    <p>Geopolitics, news, rumors</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12 mt-2">
                  <div className="earn-2 mt-3 py-3 text-center cn1">
                    <img
                      src={require("../../assets/images/mani/bank.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                    <p>
                      Rhetoric and policy of influential central banks, lending
                      institutions, and top companies
                    </p>
                  </div>
                </div>
              </div>
              <div className="efficiency">
                <p className="">
                  <i className="altick mx-2">
                    <FaCheck />
                  </i>
                  To maximize your trading efficiency on Milliva, place a high
                  priority on learning the theory. A special educational section
                  available on Milliva website will develop your understanding
                  of the matter.
                </p>
              </div>
              <div className="row mt-4">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="real-deposit">
                    <h4>
                      Before investing a real deposit, a trader should grasp the
                      following key concepts:
                    </h4>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="real-deposit1 d-flex">
                    <div className="real1 d-flex align-items-center">
                      <img
                        src={require("../../assets/images/mani/issues1.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                    <div className="real2 mt-3 px-3">
                      <strong>
                        1. The principles of how the international currency
                        market operates.
                      </strong>
                      <p className="mt-2">
                        With this knowledge, a trader understands what is going
                        on in an exchange floor.
                      </p>
                    </div>
                  </div>
                  <div className="real-deposit1 d-flex">
                    <div className="real1 d-flex align-items-center">
                      <img
                        src={require("../../assets/images/mani/issues2.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                    <div className="real2 mt-3 px-3">
                      <strong>
                        2. Methods of fundamental and technical analysis.
                      </strong>
                      <p className="mt-2">
                        These methods greatly improve the accuracy of forecasts
                        for any asset.
                      </p>
                    </div>
                  </div>
                  <div className="real-deposit1 d-flex">
                    <div className="real d-flex align-items-center">
                      <img
                        src={require("../../assets/images/mani/issues3.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                    <div className="real2 mt-3 px-3">
                      <strong>
                        3. Special features of different assets and derivative
                        financial instruments.
                      </strong>
                      <p className="mt-2">
                        This knowledge will help trader to choose correctly a
                        trading instrument, time to work and the best strategy
                        for this.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="trade-knowledge">
                <p className="mt-4">
                  <i className="altick mx-2">
                    <FaCheck />
                  </i>
                  This knowledge simplifies a choice of a suitable trading
                  instrument, the right trading session, and an appropriate
                  trading strategy. To practice trading skills, a good solution
                  is a demo account. Every client can open this type of account
                  without any risks to his/her savings. This is a free-of-charge
                  training account that enables a trader to find out how a
                  trading platform suggested by the broker operates, to employ
                  theory in practice, and to check a preferable trading strategy
                  for efficiency.
                </p>
              </div>
              <div className="row mt-4 align-items-center" id="stragy">
                <div className="col-lg-7 col-md-12 col-12 col-sm-12 ">
                  <div className="strategy-loss">
                    <h4>What strategy to choose not to incur losses</h4>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      A strategy in Currency trading is a collection of
                      guidelines that a trader follows when speculating in the
                      market. This code specifies the conditions that must be
                      met in order to close a transaction. A strategy also
                      involves money management guidelines, establishing a stop
                      loss/take profit, and other criteria. Milliva can greatly
                      assist you in developing an effective trading strategy.
                    </p>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      Milliva analytics is of great help to you to design an
                      effective trading strategy. However, as a beginner, you
                      may lack any algorithm for a while. So, take into account
                      the following rules of trading in a currency market.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-12 col-sm-12">
                  <div className="strategy-image mt-3">
                    <h4 className="px-3">Trading Code (set of rules)</h4>
                    <div className="strategy-1 d-flex align-items-center  p-2">
                      <img
                        src={require("../../assets/images/mani/wt3.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                      <p className="px-3">Rules Of Money Management</p>
                    </div>
                    <div className="strategy-1 d-flex align-items-center  p-2">
                      <img
                        src={require("../../assets/images/mani/wt2.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                      <p className="px-3">Managing Stop Orders</p>
                    </div>
                    <div className="strategy-1 d-flex align-items-center  p-2">
                      <img
                        src={require("../../assets/images/mani/wt1.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                      <p className="px-3">Take Profit And Other Terms</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="novoice-traders mt-4 py-3">
                    <h4>
                      This is a bad idea to trade with big leverage, especially
                      for novice traders.
                    </h4>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      It is common knowledge that market quotes on Milliva
                      fluctuate with different volatility. During the same
                      trading day, an asset’s price could make minor
                      fluctuations of a few decimal points of a percent. At the
                      same time, a price change could be more than 5-10%. To
                      boost would-be profits, Milliva offers its clients the
                      opportunity to trade with leverage that means virtual
                      borrowed funds. In essence, a trader’s deposit is not
                      credited with real money. However, a trader can manage a
                      deal which is a few times bigger than his own investment.
                    </p>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      Importantly, trading with leveraged funds increases both
                      profits and risks. If you make the right forecast of a
                      price change, you will yield an extra profit proportionate
                      to your leverage. Otherwise, if your forecast fails, you
                      will incur losses in the appropriate proportion. To
                      minimize risks and maximize would-be profits, novice
                      traders are not recommended to open positions with
                      leverage more than 1:5 – 1:15.
                    </p>
                  </div>
                  <div className="novoice-trader1 text-center mt-4">
                    <h2>How leverage works</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="novoice-trader2">
                    <div className="card text-center mt-4 p-3">
                      <img
                        src={require("../../assets/images/mani/coin1.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                      <div className="card-body">
                        <p className="card-text">
                          You have <strong>$100</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="novoice-trader2">
                    <div className="card text-center mt-4 p-3">
                      <img
                        src={require("../../assets/images/mani/milliva-logo.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                      <div className="card-body">
                        <p className="card-text">
                          The broker provides you with leverage of 1:10
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="novoice-trader2">
                    <div className="card text-center mt-4 p-3">
                      <img
                        src={require("../../assets/images/mani/dolor.png")}
                        alt="logo"
                        className="img-fluid "
                      />
                      <div className="card-body">
                        <p className="card-text mt-2">
                          You can open a trade worth <strong>$1000</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="limit-deposit mt-4 py-3">
                    <h4>
                      Impose some limitations on your deposit. Do not deposit
                      your account with all your savings.
                    </h4>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      The global currency market is hard to predict with 100%
                      accuracy. Thus, do not downplay the scenario that you
                      might drain your deposit. Successful trading means that a
                      percentage of winning trades is higher than a percentage
                      of losing ones. Unfortunately, trading is always
                      accompanied by losses and no one is insured against this.
                      Another advantage with Milliva is that the broker does not
                      allow its clients to end up in the red. Hence, all losing
                      trades will be closed automatically.
                    </p>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      In other words, a trader will never lose more money than
                      the amount available on his/her deposit. If a trader
                      invests part of his/her savings, s/he will not go broke if
                      a deposit is lost in the end. Another tip is to restrict
                      investment per each trade. One of the money management
                      rules reads that no more than 1-5% of the deposit size
                      should be invested per trade with a view to curbing risks.
                    </p>
                  </div>
                </div>
                <div className="mt-3">
                  <h4>Stop loss is must-do!</h4>
                </div>
                <div className="col-lg-5 col-md-12 col-12 col-sm-12">
                  <div className="limit-deposit-image mt-3">
                    <img
                      src={require("../../assets/images/mani/loss.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="limit-content ">
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      A stop loss is a special limit order which allows a trader
                      to cut losses on positions. Placing a stop loss, a
                      speculator will never lose the whole deposit in a single
                      trade. Setting a stop loss, a trader specifies a price at
                      which a position will be automatically closed once this
                      level is reached. For example, a trader wants a position
                      to be closed automatically when a loss stands at 100
                      currency units. Initially, there were 1,000 currency units
                      in his deposit. If a stop loss is activated, the trader
                      will not lose the whole deposit and 900 units will be
                      left.
                    </p>
                    <p className="">
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      For some reasons, a trader may be unwilling to trade on
                      one’s own. However, s/he wants to take the opportunity of
                      earning decent money that the currency market opens up. It
                      makes sense to try a popular and convenient strategy of
                      mirror trading. This service called MillivaCopy with
                      Milliva allows traders to copy positions of successful
                      investors and execute the same trades in almost real time.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-lg-12 col-md-12 col-12 col-sm-12"
                  id="begin"
                >
                  <div className="trade-works mt-3 pt-3">
                    <h2>Must-do steps before you begin trading on Milliva</h2>
                    <div className="trad-works">
                      <p>
                        <i className="altick mx-2">
                          <FaCheck />
                        </i>
                        Open a demo account and trade in real-world settings
                        without fear or risk. Once you can gain information,
                        experience, and skills by trading on a demo account. If
                        you are confident in your trading abilities and
                        understand how the market works, it is time to begin
                        trading with real money. If you earn a consistent income
                        from interest rate differentials, the next level in your
                        evolution is professional trading with strategies,
                        advisors, and indicators.
                      </p>
                    </div>
                    <h4>Some details should not escape your attention:</h4>
                    <div className="trade-work1 d-flex">
                      <div className="trade-work2 d-flex align-items-center">
                        <img
                          src={require("../../assets/images/mani/nuances1.png")}
                          alt="logo"
                          className="img-fluid work-image1 "
                        />
                      </div>
                      <div className="trade-work3 mt-3 px-3">
                        <strong> 1. License.</strong>
                        <p className="mt-2">
                          A regulator's license is a guarantee of safety and
                          reliability of a brokerage company. Trading at
                          financial markets with Milliva you can be sure in
                          transparency of cooperation conditions and high
                          quality of deals execution. Independent and reputable
                          regulators monitor it.
                        </p>
                      </div>
                    </div>
                    <div className="trade-work1 d-flex">
                      <div className="trade-work2 d-flex align-items-center">
                        <img
                          src={require("../../assets/images/mani/nuances2.png")}
                          alt="logo"
                          className="img-fluid work-image2 "
                        />
                      </div>
                      <div className="trade-work3 mt-3 px-3">
                        <strong> 2. Training.</strong>
                        <p className="mt-2">
                          A reliable broker offers free training for
                          beginners.There are many articles, useful advices,
                          recommendations and free tools for beginners.
                        </p>
                      </div>
                    </div>
                    <div className="trade-work1 d-flex">
                      <div className="trade-work2 d-flex align-items-center">
                        <img
                          src={require("../../assets/images/mani/nuances3.png")}
                          alt="logo"
                          className="img-fluid work-image3 "
                        />
                      </div>
                      <div className="trade-work3 mt-3 px-3">
                        <strong> 3. Trading Platform.</strong>
                        <p className="mt-2">
                          It is essential to turn your hand to a trading
                          platform.First, you should understand how to install
                          it, how to adjust it, and then how to manage trades on
                          it. A sheer advantage of a broker is a mobile version
                          of a trading platform.
                        </p>
                      </div>
                    </div>
                    <div className="trade-work1 d-flex">
                      <div className="trade-work2 d-flex align-items-center">
                        <img
                          src={require("../../assets/images/mani/nuances4.png")}
                          alt="logo"
                          className="img-fluid work-image4 "
                        />
                      </div>
                      <div className="trade-work3 mt-3 px-3">
                        <strong> 4. Demo Account.</strong>
                        <p className="mt-2">
                          With a demo account, traders can test their knowledge
                          in practice, get to know a trading platform, and check
                          a preferable trading strategy for efficiency.
                        </p>
                      </div>
                    </div>
                    <div className="trade-wrok1 d-flex">
                      <div className="trade-work2 d-flex align-items-center">
                        <img
                          src={require("../../assets/images/mani/nuances5.png")}
                          alt="logo"
                          className="img-fluid work-image5 "
                        />
                      </div>
                      <div className="trade-work3 mt-3 px-3">
                        <strong> 5. Technical Support.</strong>
                        <p className="mt-2">
                          A broker must specify various ways of contacting
                          customer support. Managers must respond instantly to
                          questions which clients ask not only by phone but also
                          by email, online chats or by another messenger. A
                          broker of an international scale must ensure support
                          in several languages which are widely spoken in those
                          regions where a broker is present.
                        </p>
                      </div>
                    </div>
                    <div className="trade-work1 d-flex">
                      <div className="trade-work2 d-flex align-items-center">
                        <img
                          src={require("../../assets/images/mani/nuances6.png")}
                          alt="logo"
                          className="img-fluid work-image6 "
                        />
                      </div>
                      <div className="trade-work3 mt-3 px-3">
                        <strong> 6. Size of minimum start-up deposit.</strong>
                        <p className="mt-2">
                          A broker must not constrain its clients, setting an
                          elevated threshold for their entry to the market. They
                          should have a choice of trading accounts to suit the
                          needs of traders with a different size of wallet.
                        </p>
                      </div>
                    </div>
                    <div className="trade-theory">
                      <p className="mt-3">
                        <i className="altick mx-2">
                          <FaCheck />
                        </i>
                        In theory, you are able to predict accurately moves of
                        currency pairs, but in practice, you find it complicated
                        to foresee further market behavior. Trading on a demo
                        account gives you confidence so that you can select a
                        trading strategy to suit you fine. Then, welcome to
                        trade under real market conditions! You need to open a
                        live account and deposit it with some money. Do not
                        forget there are a few types of accounts with Milliva to
                        choose from. You decide on that one which is tailor-made
                        for you in terms of trading conditions and the size of a
                        start-up deposit.
                      </p>
                      <p>
                        <i className="altick mx-2">
                          <FaCheck />
                        </i>
                        If you are a rookie trader, make your first steps on
                        Milliva with a deposit which you are not afraid to lose.
                        Let us warn you that a strategy which has been tested on
                        a demo account might turn out to be not as efficient as
                        expected. The real market always brings about revision
                        of any algorithm. In this case, you will have to spend
                        more time and money to fine-tune your strategy and
                        develop your own trading style. To curb risks, it would
                        be a good idea to use not just one but two-three
                        strategies in parallel.
                      </p>
                    </div>
                    <div className="trade-conclusion mt-4">
                      <h2>Conclusion</h2>
                      <p>
                        <i className="altick mx-2">
                          <FaCheck />
                        </i>
                        Most beginners are wondering whether it is simple to
                        trade on Milliva. The straightforward answer is no.
                        Trading in the global currency market has nothing in
                        common with gambling at a casino. In fact, this activity
                        implies grueling work and nonstop self-development.
                        However, if the underlying reason for your venture is
                        easy money, you should be braced for a losing streak. On
                        the other hand, if you consider the currency market a
                        source of your regular income, make sure you learn,
                        train, adopt someone’s successful experience, and
                        sharpen your strategy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trade getmt5 mt-4 pb-5">
            <div className="getsmt5">
              <div className="container">
                <h2 className="text-center">Get Started</h2>
                <p class="getapara">Get start trade anywhere, anytime. We have everything you need: friendly customer support, the latest technology, and multiple account types to find the best trades.</p>

                <div className="row mt-5 align-items-center">
                  <div className="col-md-12 col-lg-9">
                    <div className="row">
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images//algoimg/singupicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Signup</h4>
                          <p className="mb-0 font-13">
                            Enter your details and setup an account
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/verifyicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Verify</h4>
                          <p className="mb-0 font-13">
                            Upload your documents and get verified instantly
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/depositicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Deposit</h4>
                          <p className="mb-0 font-13">
                            Deposit Minimum of $100
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/tradeicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Trade</h4>
                          <p className="mb-0 font-13">
                            Start trading any instrument of your choice and make
                            profits
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center getsbtn mt-5">
                        <div className="openlivebtn">
                          <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                            <button type="button" className="btn btn-primary">
                              Open Live Account
                            </button>
                          </a>
                        </div>
                        <div className="opendemobtn">
                          <a href="https://crm.milliva.com/opendemoaccount">
                            <button type="button" className="btn btn-primary">
                              Open Demo Account
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3">
                    <div className="getimg">
                      <img
                        src={require("../../assets/images/algoimg/getstartimg.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </div>
  );
};

export default Trading;
