import React,{useEffect} from "react";
import "../../assets/css/tradingfees.css";
import { FaCheck } from "react-icons/fa";
const Tradingfees = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
          top: 0,
           
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <>
        <div className="trading-fees-value">
          <section className="mt5trade">
            <div className="mt5cont">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6 ">
                    <h1 className=" mb-2">Trading Fees</h1>
                    <p className=" mb-3">
                    Reap the benefits of working with a multi-award winning brokerage team and receive raw spreads and low commissions.


                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/mani/tradefees1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trade-fees-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                  <div className="trading-image p-4 px-5 ">
                    <img
                      src={require("../../assets/images/mani/traded1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="trading-content2">
                    <h2>Trading Commissions And Fees</h2>
                    <p>
                      Before you make that first trade, you’ll want to know how
                      much will it cost you.
                    </p>
                    <p>
                      We’re proud to offer great pricing and we’re honest about
                      the fees we charge. Have a read through the below and let
                      us know if anything isn’t clear.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trade-spreads-page">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="trade-spread-content1  px-5">
                    <h2>Spreads</h2>
                    <p>
                      The spread, often known as the "cost" of your trade, is
                      the difference between the ask and the bid price for each
                      deal you make. This is one of the ways brokers, like us,
                      make money, but we constantly work to make our spreads
                      extremely tight to ensure that you spend the least amount
                      possible.
                    </p>
                    <p>
                      Our Advantage account offers spreads on FX pairs that can
                      be as low as zero and are normally modest.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-12 col-sm-12">
                  <div className="trade-spread-image mt-3">
                    <img
                      src={require("../../assets/images/mani/spread1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-12 col-sm-12">
                  <div className="trade-spread1-image">
                    <img
                      src={require("../../assets/images/mani/commision1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="trade-spread-content1  px-5">
                    <h2>Commisions</h2>
                    <p>
                      Your payment to us for managing your transactions will be
                      in the form of commissions. We keep them low; in fact,
                      there are no commission costs at all for the Micro and
                      Advantage Plus trading account types, two of our three
                      available account kinds.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="trade-spread-content1  px-5">
                    <h2>Swaps</h2>
                    <p>
                      A swap is an overnight interest you either pay or receive
                      on a deal. There are two different forms of swaps: long
                      swaps (for buy positions) and short swaps (for sell
                      positions). Both are measured in pips per lot, and the
                      amount varies depending on the trading instrument.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-12 col-sm-12">
                  <div className="trade-spread-image">
                    <img
                      src={require("../../assets/images/mani/swap1.png")}
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trade-video-page">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                  <div className="trade-video mt-3 py-5">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        width={560}
                        height={315}
                        src="https://www.youtube.com/embed/unsP8lYPz9I"
                        title="YouTube video player"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 col-sm-12 d-flex align-items-center">
                  <div className="trade-video-content px-5 text-white">
                    <h2>Our spreads, explained in one simple video.</h2>
                    <p>
                      Here’s exactly how we charge for our spreads, depending on
                      your account type and the instrument you’re trading.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trade-comision">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="comision-page1 text-center mt-4">
                    <h2>How our commissions work</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                  <div className="comision-page2 mt-3 py-3">
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      Just as a refresher, commissions are the charges you’ll
                      pay for us to facilitate your trades as your broker. We
                      keep these as low as possible.
                    </p>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      On Advantage MT5 accounts, commission is fixed at $4 per
                      lot and doesn't change based on volume and equity, nor the
                      market you’re trading. So, you’ll pay $2 when you open a
                      position and $2 when you close it, per every 1 lot you
                      trade.
                    </p>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      On our Advantage MT4 account, however, the commission
                      terms are flexible. The higher your equity and the larger
                      your trading volume, the lower the commissions you pay.
                      This is based on a floating structure - so the more your
                      funds and trading volume increase, the less you’ll pay.
                    </p>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>
                      The formula we use to calculate commission is:
                    </p>
                    <p>
                      <i className="altick mx-2">
                        <FaCheck />
                      </i>

                      <small className="fw-bold">
                        (Volume * Contract Size * Open Price) / 1,000,000 *
                        Commission * 2
                      </small>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                  <div className="comision-page2 mt-3">
                    <img
                      src={require("../../assets/images/mani/commit.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trade-stocks">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="trade-stock1 mt-4 py-3 text-center text-white">
                    <h2>Trading Fees With Stocks</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                  <div className="trade-stock2 py-3 text-white d-flex align-items-center">
                    <img
                      src={require("../../assets/images/mani/stock3.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>
                      Trading fees with stocks US stock trading is
                      commission-free and includes free access to real time
                      price data to help you keep an eye on the latest
                      movements.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 col-sm-12">
                  <div className="trade-stock2 py-3 text-white d-flex align-items-center">
                    <img
                      src={require("../../assets/images/mani/stock4.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>
                      Trading stocks on the HKEX exchange in Hong Kong does come
                      with a fee structure based on % per notional per unit. The
                      breakdown of this fee will be published on the website
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trade-price">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 col-sm-12">
                  <div className="trade-price1 text-center mt-4">
                    <h2>Our Pricing In Numbers</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="trade-price2 mt-3 py-3">
                    <h3>0.0</h3>
                    <span>SPREADS</span>
                    <p>
                      Spreads close to zero on major FX pairs, including EURUSD
                      and USDJPY, and as low as zero on Gold, XAUUSD.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="trade-price2 mt-3 py-3">
                    <h3>0.4¢ - $2</h3>
                    <span>COMMISSIONS ON FX AND CFDS</span>
                    <p>
                      Our Advantage MT4 account gives you the best spreads and
                      highly competitive commission rates
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-12 col-sm-12">
                  <div className="trade-price2 mt-3 py-3">
                    <h3>0%</h3>
                    <span>COMMISSION ON US STOCKS</span>
                    <p>
                      Trade the biggest US brands, including Tesla, Apple,
                      Facebook and Microsoft, with no commission.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trade getmt5 mt-4 pb-5">
            <div className="getsmt5">
              <div className="container">
                <h2 className="text-center">Get Started</h2>
                <p class="getapara">Get start trade anywhere, anytime. We have everything you need: friendly customer support, the latest technology, and multiple account types to find the best trades.</p>
                <div className="row mt-5 align-items-center">
                  <div className="col-md-12 col-lg-9">
                    <div className="row">
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images//algoimg/singupicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Signup</h4>
                          <p className="mb-0 font-13">
                            Enter your details and setup an account
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/verifyicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Verify</h4>
                          <p className="mb-0 font-13">
                            Upload your documents and get verified instantly
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/depositicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Deposit</h4>
                          <p className="mb-0 font-13">
                          Deposit Minimum as $100
                          </p>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3">
                        <div className="box1get">
                          <img
                            src={require("../../assets/images/algoimg/tradeicon1.png")}
                            alt="logo"
                            className="img-fluid mb-3"
                          />
                          <h4 className="my-1 mb-3">Trade</h4>
                          <p className="mb-0 font-13">
                            Start trading any instrument of your choice and make
                            profits
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center getsbtn mt-5">
                        <div className="openlivebtn">
                          <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                            <button type="button" className="btn btn-primary">
                              Open Live Account
                            </button>
                          </a>
                        </div>
                        <div className="opendemobtn">
                          <a href="https://crm.milliva.com/opendemoaccount">
                            <button type="button" className="btn btn-primary">
                              Open Demo Account
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-3">
                    <div className="getimg">
                      <img
                        src={require("../../assets/images/algoimg/getstartimg.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </div>
  );
};

export default Tradingfees;
