import React, { useState } from 'react'
import "../../assets/css/Iboffer.css";
import { Tab, Nav } from 'react-bootstrap';
const IBoffer = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <div className='iboffpage'>
            <div className="strikeout-container" >
            <img
                                    src={require("../../assets/images/ibpromotions/sold.png")}
                                    alt="logo"
                                    className="strikeout-image"
                                />
                <section className='Sub-Broker'>
                    <div className='Broker'>
                        <h2 className='px-3'>Target your Volume and Grab Your Rewards!</h2>
                        <p className='px-3'>
                            Don't Miss Out! Limited Time Offer to Reach Your Goals and Seize the Opportunity!
                        </p>
                        <button> <a href='https://crm.milliva.com/client/register/6169896c79fd7'>Claim Now!</a></button>
                    </div>
                </section>
                <section>
                    <div className='claim-now mt-4'>
                        <h2 className='text-center px-3'>
                            Milliva’s IB Program Rewards
                        </h2>
                        <div class="container mt-3">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-6 p-3 ">
                                    <div className='milliva-offer'>
                                        <div class="card mb-0" >
                                            <div class="row g-0">
                                                <div class="col-4 col-md-4 col-lg-4 ">
                                                    <img
                                                        src={require("../../assets/images/ibpage/1.png")}
                                                        alt="logo"
                                                        className="img-fluid  mx-2"
                                                    />
                                                </div>
                                                <div class="col-8 col-md-8 col-lg-8 m-auto">
                                                    <div class="card-body text-center">
                                                        <h5 class="card-title">Smart Watch</h5>
                                                        <p class="card-text">$3000 Business</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6 p-3 ">
                                    <div className='milliva-offer'>
                                        <div class="card mb-0" >
                                            <div class="row g-0">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <img
                                                        src={require("../../assets/images/ibpage/2.png")}
                                                        alt="logo"
                                                        className="img-fluid  mx-2"
                                                    />                                                </div>
                                                <div class="col-8 col-md-8 col-lg-8 m-auto">
                                                    <div class="card-body text-center">
                                                        <h5 class="card-title">Smart Watch + Powerbank</h5>
                                                        <p class="card-text">$5000 Business</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-6 p-3 ">
                                    <div className='milliva-offer'>
                                        <div class="card mb-0" >
                                            <div class="row g-0">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <img
                                                        src={require("../../assets/images/ibpage/5G-PNG-Pic.png")}
                                                        alt="logo"
                                                        className="img-fluid  mx-2"
                                                    />                                                </div>
                                                <div class="col-8 col-md-8 col-lg-8 m-auto">
                                                    <div class="card-body text-center">
                                                        <h5 class="card-title">5G Phone</h5>
                                                        <p class="card-text">$15000 Business</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6 p-3 ">
                                    <div className='milliva-offer'>
                                        <div class="card mb-0" >
                                            <div class="row g-0">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <img
                                                        src={require("../../assets/images/ibpage/4.png")}
                                                        alt="logo"
                                                        className="img-fluid  mx-2"
                                                    />                                                </div>
                                                <div class="col-8 col-md-8 col-lg-8 m-auto">
                                                    <div class="card-body text-center">
                                                        <h5 class="card-title">Tab </h5>
                                                        <p class="card-text">$25000 Business</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-6 p-3 ">
                                    <div className='milliva-offer'>
                                        <div class="card mb-0" >
                                            <div class="row g-0">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <img
                                                        src={require("../../assets/images/ibpage/5.png")}
                                                        alt="logo"
                                                        className="img-fluid  mx-2"
                                                    />                                                </div>
                                                <div class="col-8 col-md-8 col-lg-8 m-auto">
                                                    <div class="card-body text-center">
                                                        <h5 class="card-title">55 Inches TV</h5>
                                                        <p class="card-text">$35000 Business</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6 p-3 ">
                                    <div className='milliva-offer'>
                                        <div class="card mb-0" >
                                            <div class="row g-0">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <img
                                                        src={require("../../assets/images/ibpage/6.png")}
                                                        alt="logo"
                                                        className="img-fluid  mx-2"
                                                    />                                                </div>
                                                <div class="col-8 col-md-8 col-lg-8 m-auto">
                                                    <div class="card-body text-center">
                                                        <h5 class="card-title">Monitor or Laptop</h5>
                                                        <p class="card-text">$50000 Business</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-6 p-3  ">
                                    <div className='milliva-offer'>
                                        <div class="card mb-0" >
                                            <div class="row g-0">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <img
                                                        src={require("../../assets/images/ibpage/7.png")}
                                                        alt="logo"
                                                        className="img-fluid  mx-2"
                                                    />                                                </div>
                                                <div class="col-8 col-md-8 col-lg-8 m-auto">
                                                    <div class="card-body text-center">
                                                        <h5 class="card-title">Electric Scooty</h5>
                                                        <p class="card-text">$80000 Business</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-6 p-3 ">
                                    <div className='milliva-offer'>
                                        <div class="card mb-0" >
                                            <div class="row g-0">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <img
                                                        src={require("../../assets/images/ibpage/8.png")}
                                                        alt="logo"
                                                        className="img-fluid  mx-2"
                                                    />                                                </div>
                                                <div class="col-8 col-md-8 col-lg-8 m-auto">
                                                    <div class="card-body text-center">
                                                        <h5 class="card-title">3 Days Dubai Trip or Mac Laptop</h5>
                                                        <p class="card-text"><small class="text-muted">$100000 Business</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <h2 className='text-center mb-4 mt-5 px-3'>Begin Your Journey to Success Now!
                </h2>
                <section id="features">

                    <div class="container">
                        <div class="row">
                            <div class="col-6  col-lg-3">
                                <div class="card">
                                    <p>Milliva’s Client </p>
                                    <div class="counter-wrapper">
                                        <span><h2 class="count" data-target="1000000">80,000+</h2></span>

                                    </div>
                                </div>
                            </div>
                            <div class="col-6  col-lg-3">
                                <div class="card">
                                    <p>Instruments </p>
                                    <div class="counter-wrapper">
                                        <span><h2 class="count" data-target="11">1200+</h2></span>

                                    </div>
                                </div>
                            </div>
                            <div class="col-6  col-lg-3">
                                <div class="card">
                                    <p>Introducing Brokers </p>
                                    <div class="counter-wrapper">
                                        <span><h2 class="count" data-target="20">3600+</h2></span>

                                    </div>
                                </div>
                            </div>
                            <div class="col-6  col-lg-3">
                                <div class="card">
                                    <p >Offices & Branches </p>
                                    <div class="counter-wrapper">
                                        <span><h2 class="count" data-target="20000">16+</h2></span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='IB-ranking mt-5 mb-3'>
                        <div class="container mt-3">
                            <div className='row'>
                                <div class="col-lg-5 m-auto">
                                    <h2>IB Rewards Commission</h2>
                                    <p> Earn generous rewards with every successful referral. Join now and prosper!
                                    </p>
                                </div>
                                <div class="col-lg-7">
                                    <div className=' commission'>
                                        <Tab.Container defaultActiveKey='first'>
                                            <Nav className=" trading-section nav nav-tabs tab-body-header rounded d-inline-flex mb-4" role="tablist">
                                                <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey='first' data-bs-toggle="tab" href="#Limit" role="tab" aria-selected="false">Premium </Nav.Link></Nav.Item>
                                                <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey='second' data-bs-toggle="tab" href="#Market" role="tab" aria-selected="false">ECN</Nav.Link></Nav.Item>
                                                <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey='four' data-bs-toggle="tab" href="#four" role="tab" aria-selected="false">Standard</Nav.Link></Nav.Item>
                                                <Nav.Item className="nav-item"><Nav.Link className="nav-link" eventKey='third' data-bs-toggle="tab" href="#Stoplimit" role="tab" aria-selected="true">Elite</Nav.Link></Nav.Item>
                                            </Nav>

                                            <Tab.Content className="tab-content">
                                                <Tab.Pane className="tab-pane fade" id="Limit" eventKey='first'>
                                                    <div className='row'>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>10+</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>   <p>Active Client</p></div>

                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'><h2>$5000+</h2></div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Monthly Avg. Commission</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>$1</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Per Lot Commission</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane className="tab-pane fade" id="Market" eventKey='second'>
                                                    <div className='row'>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>15+</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Active Client</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>$8000+</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Monthly Avg. Commission</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>$3</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Per Lot Commission</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane className="tab-pane fade" id="four" eventKey='four'>
                                                    <div className='row'>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>20+</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Active Client</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>$12500+</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Monthly Avg. Commission</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>$5</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Per Lot Commission</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                                <Tab.Pane className="tab-pane fade" id="Stoplimit" eventKey='third'>
                                                    <div className='row'>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>30+</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Active Client</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>$20000+</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Monthly Avg. Commission</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4 p-3 ">
                                                            <div className='row Premium'>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <h2>$25</h2>
                                                                </div>
                                                                <div className='col-sm-12 col-6 col-lg-12'>
                                                                    <p> Per Lot Commission</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>

                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inbro mt-5">
                    <div className="container">
                        <div className="inbrosub">
                            <h2 className="text-center mb-3">
                                Introducing broker / Sub-Broker
                            </h2>
                            <p className="text-center">
                                Milliva offers Introducing Broker, which has a direct working relationship with clients and sub-broker, a middleman between two parties; the customer and the main broker.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="inbroicons">
                    <div className="container">
                        <div className="payouts inbrosubicons pt-4">
                            <div className="row justify-content-center mt-3">
                                <div className="col-md-6 col-lg-3">
                                    <div className="row ib-trade  d-flex align-items-center">
                                        <div className='col-sm-8 col-6 col-lg-8'>
                                            <div className="parainb">
                                                <p>
                                                    Smooth daily <br />
                                                    commission payouts
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-sm-4 col-6 col-lg-4'>
                                            <div className="imginb">
                                                <img
                                                    src={require("../../assets/images/introbroker/tradecommision1.png")}
                                                    alt="logo"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="row ib-trade  d-flex align-items-center">
                                        <div className='col-sm-8 col-6 col-lg-8'>
                                            <div className="parainb">
                                                <p>
                                                    Favourable trading
                                                    <br /> conditions
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-sm-4 col-6 col-lg-4'>
                                            <div className="imginb">
                                                <img
                                                    src={require("../../assets/images/introbroker/tradesearch.png")}
                                                    alt="logo"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="row  ib-trade  d-flex align-items-center">
                                        <div className='col-sm-8 col-6 col-lg-8'>
                                            <div className="parainb">
                                                <p>
                                                    Uncapped
                                                    <br /> commission
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-sm-4 col-6 col-lg-4'>
                                            <div className="imginb">
                                                <img
                                                    src={require("../../assets/images/introbroker/uncappedcomm.png")}
                                                    alt="logo"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <div className="row ib-trade iconintro3 d-flex align-items-center">
                                        <div className='col-sm-8 col-6 col-lg-8'>
                                            <div className="parainb">
                                                <p>
                                                    Transparent program with <br />
                                                    no hidden restrictions
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-sm-4 col-6 col-lg-4'>
                                            <div className="imginb">
                                                <img
                                                    src={require("../../assets/images/introbroker/transparenttrade.png")}
                                                    alt="logo"
                                                    className="img-fluid"
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="becomeib mt-5">
                    <div className="container">
                        <div className="becomeibinsub">
                            <h2 className="text-center">
                                How To Become An Introducing Broker
                            </h2>
                            <div className="row mt-5">
                                <div className=" col-md-4 col-lg-4">
                                    <div className="row sign12 d-flex align-items-center">
                                        <div className='col-6 col-md-6 col-lg-8'>
                                            <div className="becom2 ">
                                                <h5 className="mb-2">Signup</h5>
                                                <p>
                                                    Join our IB program today to take the first step in growing your business.

                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-6 col-md-6 col-lg-4'>
                                        <div className="becom1 mx-3">
                                            <img
                                                src={require("../../assets/images/introbroker/ibsignup.png")}
                                                alt="logo"
                                                className="img-fluid"
                                            />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-4">
                                    <div className="row sign12 d-flex align-items-center">
                                    <div className='col-6 col-md-6 col-lg-8'>
                                        <div className="becom2">
                                            <h5 className="mb-2">Refer Clients</h5>
                                            <p>
                                                Use your personalized tracking links to send visitors to our conversion-optimized landing pages.
                                            </p>
                                        </div>
                                        </div>
                                        <div className='col-6 col-md-6 col-lg-4'>
                                        <div className="becom1 mx-3">
                                            <img
                                                src={require("../../assets/images/introbroker/referib.png")}
                                                alt="logo"
                                                className="img-fluid"
                                            />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-4">
                                    <div className="row sign12 d-flex align-items-center">
                                    <div className='col-6 col-md-6 col-lg-8'>
                                        <div className="becom2">
                                            <h5 className="mb-2">Earn More</h5>
                                            <p>
                                                Earn substantial lifetime rebates on the trading activities of clients you refer
                                            </p>
                                        </div>
                                        </div>
                                        <div className='col-6 col-md-6 col-lg-4'>
                                        <div className="becom1 mx-3">
                                            <img
                                                src={require("../../assets/images/introbroker/earnmoreib.png")}
                                                alt="logo"
                                                className="img-fluid"
                                            />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                                    <button type="button" className="btn btn-become mt-4 mb-5">
                                        Become Ib
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>


                <section>
                    <div className='promotionrules mt-5 mb-5'>
                        <div className='container'>
                            <h2 className='text-center'>Terms & Conditions </h2>


                            <h5>Introduction</h5>
                            <ul className='rulespromo'>
                                <li> <i class="fa fa-check mx-2" aria-hidden="true"></i> IB Mega Loyalty Program is only eligible for the Sub Brokers/ IB Client.
                                </li>
                                <li><i class="fa fa-check mx-2" aria-hidden="true"></i>These IB Mega Loyalty Program are only eligible for sub-brokers with 3 referral clients</li>
                                <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Make sure you use this offer with this limited-time offer (The offer Starts From August 21 – October 5, 2023) ( 45 days)</li>
                                <li><i class="fa fa-check mx-2" aria-hidden="true"></i>All rewards will be calculated by the volume of business done From August 21 – October 5, 2023. (To get your rewards, please maintain your business volume minimum of 3 months)

                                </li>
                            </ul>
                            <div className="block-item-text">
                                <input type="checkbox" hidden className="read-more-state" id="read-more" />
                                <div className="read-more-wrap">

                                    <p className="read-more-target">
                                        <h5>Who can win?</h5>
                                        <p>Every IB/ Sub Brokers will be provided with rewards based on your total volume of business.


                                        </p>
                                        <h5>IB Mega Loyalty Program</h5>
                                        <ul className='winpromo mx-2'>
                                            <p> $3000- Business Volume
                                            </p>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>You'll earn enticing rewards by meeting the set conditions, including a Smart Watch, Participation Certificate, and Milliva's T-Shirt. To qualify, achieve a target of $3000 within 45 days and invite at least 3 new active clients.  </li>




                                        </ul>
                                        <ul className='winpromo mx-2'>
                                            <p>  $5000- Business Volume
                                            </p>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>You'll earn enticing rewards by meeting the set conditions, including a Smart Watch, Power Bank, Participation Certificate, and Milliva's T-Shirt. To qualify, achieve a target of $5000 within 45 days and invite at least 3 new active clients.  </li>

                                        </ul>
                                        <ul className='winpromo mx-2'>
                                            <p>  $15000- Business Volume
                                            </p>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>You'll earn enticing rewards by meeting the set conditions, including a 5G Phone, Participation Certificate, and Milliva's T-Shirt. To qualify, achieve a target of $15000 within 45 days and invite at least 3 new active client </li>

                                        </ul>
                                        <ul className='winpromo mx-2'>
                                            <p> $25000- Business Volume
                                            </p>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>You'll earn enticing rewards by meeting the set conditions, including a Tab, Participation Certificate, and Milliva's T-Shirt. To qualify, achieve a target of $25000 within 45 days and invite at least 3 new active clients. </li>

                                        </ul>
                                        <ul className='winpromo mx-2'>
                                            <p> $35000- Business Volume
                                            </p>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>You'll earn enticing rewards by meeting the set conditions, including a 55 Inches TV, Participation Certificate, and Milliva's T-Shirt. To qualify, achieve a target of $35000 within 45 days and invite at least 3 new active clients. </li>

                                        </ul>
                                        <ul className='winpromo mx-2'>
                                            <p>$50000- Business Volume
                                            </p>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>You'll earn enticing rewards by meeting the set conditions, including a Monitor or Laptop, Participation Certificate, and Milliva's T-Shirt. To qualify, achieve a target of $50000 within 45 days and invite at least 3 new active clients. </li>

                                        </ul>
                                        <ul className='winpromo mx-2'>
                                            <p>$80000- Business Volume
                                            </p>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>You'll earn enticing rewards by meeting the set conditions, including an Electric Scooty, Participation Certificate, and Milliva's T-Shirt. To qualify, achieve a target of $80000 within 45 days and invite at least 3 new active clients.</li>

                                        </ul>
                                        <ul className='winpromo mx-2'>
                                            <p>$100000- Business Volume
                                            </p>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>You'll earn enticing rewards by meeting the set conditions, including a 3 Days Dubai Trip or Mac Laptop, Participation Certificate, and Milliva's T-Shirt. To qualify, achieve a target of $80000 within 45 days and invite at least 3 new active clients.
                                            </li>

                                        </ul>



                                        <p><span>Note:</span> (After this offer ends, you have to  maintain the same volume for the next 3 months)</p>




                                        <p><i class="fa fa-check mx-2" aria-hidden="true"></i>We provide you with Total Invoice and Gift Certificates affiliates.
                                            .</p>

                                        <p><i class="fa fa-check mx-2" aria-hidden="true"></i>We will deliver your gifts within 60 days.

                                        </p>
                                        <p><i class="fa fa-check mx-2" aria-hidden="true"></i>The rewards dispatch process will start after 1 month of promotion completion.
                                        </p>

                                        <h5>Delivery</h5>
                                        <ul className='deliveryli'>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Your gifts will be delivered to your designated address within eight weeks of claiming your gift.

                                            </li>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>The only client who registered for a gift can be the recipient.


                                            </li>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>Provide your official address and contact information while claiming. We are only responsible if the recipient provides the correct delivery address and contact details.

                                            </li>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>If the recipient does not respond to any calls from delivery services, your gifts will be retrieved and cancelled. Your gift will be restored aMilliva. You can retain it when possible.
                                            </li>
                                            <li><i class="fa fa-check mx-2" aria-hidden="true"></i>To claim rewards, you can contact us through mail: <a href="mailto:support@milliva.com" target="_blank" style={{color:"inherit"}}> support@milliva.com</a>. Our Milliva team will respond to you within 48 hours


                                            </li>
                                        </ul>

                                    </p>
                                </div>

                                <label htmlFor="read-more" className="read-more-trigger_closed">Read More</label>
                                <label htmlFor="read-more" className="read-more-trigger_opened">Read Less</label>


                            </div>
                        </div>
                    </div>
                </section>
                </div>
            </div>
        </div>
    )
}

export default IBoffer