import React from "react";
import { useForm } from "react-hook-form";
import { VALIDATION_PATTERN } from "../Signin/Store";
import "../../assets/css/demo.css";
function Demoacc() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const Submit = () => {
    console.log("ee");
  };
  return (
    <div>
      <>
        <section className="demo-page-content ">
          <div className="container">
            <div className="open-account-path1 row ">
              <div className="col-lg-12 col-md-12 col-12 mt-5 ">
                <div className="card open-demo-form p-3 mt-5">
                  <h2>Opening For Milliva Demo Account</h2>
                  <form onSubmit={handleSubmit(Submit)}>
                    <div className="mt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Your Name"
                        {...register("name", {
                          required: {
                            value: true,
                            message: "Please enter your name",
                          },
                        })}
                      />
                      <div>
                        <p className="text-danger">
                          {errors.name && (
                            <span role="alert">Please enter your name</span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="mt-3">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Email"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                          pattern: {
                            value: VALIDATION_PATTERN.EMAIL,
                            message: "Enter Valid Email",
                          },
                        })}
                      />
                      <div>
                        <p className="text-danger">
                          {errors.email && (
                            <span role="alert">Email is required</span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="mt-3">
                      <input
                        type="number"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter Phone Number"
                        {...register("mobile_no", {
                          required: {
                            value: true,
                            message: "Please enter a phone number",
                          },
                        })}
                      />
                      <p className="text-danger">{errors.mobile_no?.message}</p>
                    </div>
                    <select
                      className="form-select mt-3"
                      aria-label="Default select example"
                      name="country"
                      {...register("country", {
                        required: {
                          value: true,
                          message: "Select a country",
                        },
                      })}
                    >
                      <option value="">Select Country</option>
                      <option value={0}>India</option>
                      <option value={1}>One</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option>
                    </select>
                    <p className="text-danger">{errors.country?.message}</p>
                    <select
                      className="form-select mt-3"
                      aria-label="Default select example"
                      {...register("coins", {
                        required: {
                          value: true,
                          message: "Select a coins",
                        },
                      })}
                    >
                      <option value="">Select Coins</option>
                      <option value={0}>USD</option>
                      <option value={1}>EUR</option>
                      <option value={2}>BUSD</option>
                    </select>
                    <p className="text-danger">{errors.coins?.message}</p>
                    <select
                      className="form-select mt-3"
                      aria-label="Default select example"
                      {...register("amount", {
                        required: {
                          value: true,
                          message: "Select a amount",
                        },
                      })}
                    >
                      <option value="">Select Amounts</option>
                      <option value={0}>5000</option>
                      <option value={1}>1000</option>
                      <option value={2}>2000</option>
                      <option value={3}>3000</option>
                    </select>
                    <p className="text-danger">{errors.amount?.message}</p>
                    <div className="open-account mt-3">
                      <button
                        type="submit"
                        className="btn btn-warning w-100 text-white fw-bold"
                      >
                        Open Account
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
}

export default Demoacc;
