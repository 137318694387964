import React, { useEffect } from "react";
import "../../assets/css/aboutus.css";
import {Link} from "react-router-dom";
const Aboutus = () => {
  useEffect(() => {

    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: 'smooth'
      });
    }, 100)
  }, [])

  return (
    <div>
      <div className="About-us">
        <section className="about2">
          <div className="bannercont">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center mt-5">
                  <h1 className="mb-2">About Us</h1>
                  <p className="mb-3">
                    Take your Trading to the Next Level With Milliva
                  </p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="girlimage">
                    <img
                      src={require("../../assets/images/aboutus/building24.png")}
                      alt="logo"
                      className="img-fluid mt-5"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="page_top_text pt-5">
          <div className="container">
            <div className="row align-item-center">
              <div className="col-lg-5">
                <div className="images">
                  <img
                    src={require("../../assets/images/aboutus/wmilliva.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="page_top_text_content">
                  <h2>Who is Milliva?</h2>
                  <p>
                    Milliva is one of the wide range of opportunities and provides an
                    impressive suite of proprietary platforms. We established MILLIVA LTD in
                    gulf countries. We are now building educational institutions and start
                    exploring new, advanced technologies.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="mission">
          <div className="special mt-5">
            <div className="container">
              <div className="services-column row">
                <div className=" col-lg-6 ">
                  <div className="services-block-four how d-flex">
                    <div className="mission-image mx-3">
                      <img
                        src={require("../../assets/images/aboutus/mission.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>Mission</h5>
                    </div>
                    <div className="mission-text">
                      <p>
                        mission is to value the trust you keep in us. We are
                        determined to give you the most reliable and robust
                        environment for security, stability and credibility. Our
                        services centre on client satisfaction, offering the top
                        trading products, within a pure flawless trading
                        environment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" col-lg-6">
                  <div className="services-block-four how d-flex">
                    <div className="vission-image mx-3">
                      <img
                        src={require("../../assets/images/aboutus/vission.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>Vision</h5>
                    </div>
                    <div className="vission-text">
                      <p>
                        We envision a brand image for Milliva that identifies it
                        as the exchange of choice not only for direct clients
                        but also for the general public. Our vision is to step
                        forward, winning more client trust. We are still listed
                        among the top trading brokers for our unique approach
                        and trading solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="regulation-content mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h2 className="mb-2">Legal Documents</h2>
                <p>Milliva Capital Ltd is proud to be regulated by the International Financial Regulation Comoros. Our license number is L15696/MLTD, and our company is registered under number 15696. This regulation ensures that we adhere to the highest standards of financial conduct.</p>
                <div className="license-details">
                  <h5>License Details</h5>
                  <ul className="license p-0">
                    <li>License Number:<span className="mx-2">L15696/MLTD</span></li>
                    <li>Company Number:<span className="mx-2">15696</span></li>
                    <li>Valid Until: :<span className="mx-2">April 20 2025</span></li>
                    <li>Government Notice:<span className="mx-2">005 OF 2005</span></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5">
              <a href={require("../../assets/documents/Milliva Ltd License and IBC Documents.pdf")} target="blank"> 
              <img
                          src={require("../../assets/images/home/banner-regulartory.png")}
                          alt="logo"
                          className="img-fluid bannerimg1"
                        />
                        </a>
              </div>
            </div>
          </div>
        </section>
        <div className="Milliva Values _text mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12 ">
                <div className="Milliva Values _text_content ">
                  <h2>Milliva Values </h2>
                  <p>
                    The key elements of our values are transparency, trust and
                    reliability. Our business has been constructed on the
                    foundation of Trust. We make sure our clients have a
                    user-friendly experience. The key factors of our trading
                    solutions are straightforward with real market order
                    execution while ensuring data, price feed and liquidity are
                    equal and transparent for all our customers. Our values are
                    standard and we stick to our values all the time.
                  </p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="images mt-5">
                
                  <img
                    src={require("../../assets/images/aboutus/aboutvalues.png")}
                    alt="logo"
                    className="img-fluid" 
                  />
                  
              
                 
                
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <section className="flow chart">
    <div className="container">
      <div className="row">
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <div className="chart card">
            <div className="icon">
              <h5>2009</h5>
            </div>
            <img src={require('../../assets/images/aboutus/invester.png')}  alt="logo" className='img-fluid'/>
            <h5>Investor</h5>
            <div className="content">
              We started our journey as an investor in 2009. So, we are no more
              in the infancy of the financial markets.
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <div className="chart card">
            <div className="icon">
              <h5>2012</h5>
            </div>
            <img src={require('../../assets/images/aboutus/affillate.png')}  alt="logo" className='img-fluid'/>
            <h5>Affiliate</h5>
            <div className="content">
              Every time we promote a company’s products, we become affiliate
              marketers by earning commissions, and this is how we build a
              trader's network..
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <div className="chart card">
            <div className="icon">
              <h5>2014</h5>
            </div>
            <img src={require('../../assets/images/aboutus/blockchain.png')}  alt="logo" className='img-fluid'/>
            <h5>BlockChain Technology</h5>
            <div className="content">
              We do realize the need for data security, so we began our new
              milestone towards blockchain technology.
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <div className="chart card">
            <div className="icon">
              <h5>2015</h5>
            </div>
            <img src={require('../../assets/images/aboutus/firm.png')}  alt="logo" className='img-fluid'/>
            <h5>Firm</h5>
            <div className="content">
              We started serving our clients as a firm, as we built the network
              of clients(B-C) and Blockchain Technology for financial partners
              (B-B).
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <div className=" chart card">
            <div className="icon">
              <h5>2019</h5>
            </div>
            <img src={require('../../assets/images/aboutus/wealth.png')}  alt="logo" className='img-fluid'/>
            <h5>Wealth &amp; Technologies</h5>
            <div className="content">
              We expanded our services in Wealth management programs, asset
              management sectors, web technologies, applications and mobile
              developments.
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <div className="chart card">
            <div className="icon">
              <h5>2020</h5>
            </div>
            <img src={require('../../assets/images/aboutus/forex-trading.png')}  alt="logo" className='img-fluid'/>
            <h5>Forex Brokerage</h5>
            <div className="content">
              During the world pandemic era, people were in need of other means
              of income. We started our Forex brokerage business at the right
              time.
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <div className="chart card">
            <div className="icon">
              <h5>2021</h5>
            </div>
            <img src={require('../../assets/images/aboutus/incorporation.png')}  alt="logo" className='img-fluid'/>
            <h5>U.K Incorporation</h5>
            <div className="content">
              We established MILLIVA LTD in UAE, England, Singapore and India.
              We incorporated with the UK in 2021.
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <div className="chart card">
            <div className="icon">
              <h5>2022</h5>
            </div>
            <img src={require('../../assets/images/aboutus/corporate.png')}  alt="logo" className='img-fluid'/>
            <h6>SAINT VINCENT AND THE GRENADINES </h6>
            <div className="content">
              Milliva Ltd is incorporated International Markets with an
              Investment Dealer License bearing license number GB1xxxxxx and
              Fidelis Capital Markets Limited registered by FSA, St. Vincent and
              the Grenadines with Number 26829 BC 2022
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-3 col-md-12 col-sm-12 mt-5">
          <div className="chart card">
            <div className="icon">
              <h5>Now</h5>
            </div>
            <img src={require('../../assets/images/aboutus/education.png')}  alt="logo" className='img-fluid'/>
            <h5>Education</h5>
            <div className="content">
              Now, we are exploring new technologies and building educational
              institutions.
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-md-12 col-sm-12">
          <div className="about_text">
            <h2>About Miliva Partner Program</h2>
            <p>
              Milliva has expanded into one of the most trusted trading
              platforms in the world with the most rewarding partnership
              programs in the industry. We provide robust customer service and
              solutions for institutional traders, banks, brokers, and family
              offices. We rebate our huge network of successful affiliate
              partners and introduce brokers across the world. We are so proud
              to be the best exchange of choice for retail and institutional
              workers. We will go a long way toward building trust with existing
              and prospective partners.
            </p>
            <button type="button" className="btn btn-secondary">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  </section> */}

        <div className="chart2 mt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-12 col-md-12">
                <div className="flow">
                  <div className="face face1">
                    <div className="content">
                      <img
                        src={require("../../assets/images/aboutus/invester.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>2009</h5>
                    </div>
                  </div>
                  <div className="face face2">
                    <div className="content">
                      <h5>Investor</h5>
                      <div className="content">
                        We started our journey as an investor in 2009. So, we
                        are no more in the infancy of the financial markets.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12 col-md-12">
                <div className="flow">
                  <div className="face face1">
                    <div className="content">
                      <img
                        src={require("../../assets/images/aboutus/affillate.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>2012</h5>
                    </div>
                  </div>
                  <div className="face face2">
                    <div className="content">
                      <h5>Affiliate</h5>
                      <div className="content">
                        Every time we promote a company’s products, we become
                        affiliate marketers by earning commissions, and this is
                        how we build a trader's network..
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12 col-md-12">
                <div className="flow">
                  <div className="face face1">
                    <div className="content">
                      <img
                        src={require("../../assets/images/aboutus/blockchain.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>2014</h5>
                    </div>
                  </div>
                  <div className="face face2">
                    <div className="content">
                      <h5>BlockChain Technology</h5>
                      <div className="content">
                        We do realize the need for data security, so we began
                        our new milestone towards blockchain technology.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-12 col-md-12">
                <div className="flow">
                  <div className="face face1">
                    <div className="content">
                      <img
                        src={require("../../assets/images/aboutus/firm.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>2015</h5>
                    </div>
                  </div>
                  <div className="face face2">
                    <div className="content">
                      <h5>Firm</h5>
                      <div className="content">
                        We started serving our clients as a firm, as we built
                        the network of clients(B-C) and Blockchain Technology
                        for financial partners (B-B).
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-12 col-md-12">
                <div className="flow">
                  <div className="face face1">
                    <div className="content">
                      <img
                        src={require("../../assets/images/aboutus/wealth.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>2019</h5>
                    </div>
                  </div>
                  <div className="face face2">
                    <div className="content">
                      <h5>Wealth & Technologies</h5>
                      <div className="content">
                        We expanded our services in Wealth management programs,
                        asset management sectors, web technologies, applications
                        and mobile developments.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-12 col-md-12">
                <div className="flow">
                  <div className="face face1">
                    <div className="content">
                      <img
                        src={require("../../assets/images/aboutus/forex-trading.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>2020</h5>
                    </div>
                  </div>
                  <div className="face face2">
                    <div className="content">
                      <h5>Forex Brokerage</h5>
                      <div className="content">
                        During the world pandemic era, people were in need of
                        other means of income. We started our Forex brokerage
                        business at the right time.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-12 col-md-12">
                <div className="flow">
                  <div className="face face1">
                    <div className="content">
                      <img
                        src={require("../../assets/images/aboutus/incorporation.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>2021</h5>
                    </div>
                  </div>
                  <div className="face face2">
                    <div className="content">
                      <h5>U.K Incorporation</h5>
                      <div className="content">
                        We established MILLIVA LTD in UAE, England, Singapore
                        and India. We incorporated with the UK in 2021.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-3 col-sm-12 col-md-12">
                <div className="flow">
                  <div className="face face1">
                    <div className="content">
                      <img
                        src={require("../../assets/images/aboutus/corporate.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>2022</h5>
                    </div>
                  </div>
                  <div className="face face2">
                    <div className="content">
                      <h5>Saint Vincent And Grenadines</h5>
                      <div className="content">
                        Milliva Ltd is incorporated with an International Markets license and St.Vincent Grenadines Number 26829 BC 2022
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-3 col-sm-12 col-md-12">
                <div className="flow">
                  <div className="face face1">
                    <div className="content">
                      <img
                        src={require("../../assets/images/aboutus/corporate.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                      <h5>2024</h5>
                    </div>
                  </div>
                  <div className="face face2">
                    <div className="content">
                      <h5>Legal Documents</h5>
                      <div className="content">
                      Milliva Capital Ltd is proud to be regulated by the International Financial Regulation Comoros. 
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-sm-12 col-md-12">
                  <div className="now">
                    <div className="flow">
                      <div className="face face1">
                        <div className="content">
                          <img
                            src={require("../../assets/images/aboutus/education.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <h5>Now</h5>
                        </div>
                      </div>
                      <div className="face face2">
                        <div className="content">
                          <h5>Education</h5>
                          <div className="content">
                            Now, we are exploring new technologies and building
                            educational institutions.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-12 col-sm-12 mt-2 pb-5">
                  <div className="about_text">
                    <h2>About Milliva Partner Program</h2>
                    <p>
                      Milliva has expanded into one of the most trusted trading
                      platforms in the world with the most rewarding partnership
                      programs in the industry. We provide robust customer
                      service and solutions for institutional traders, banks,
                      brokers, and family offices. We rebate our huge network of
                      successful affiliate partners and introduce brokers across
                      the world. We are so proud to be the best exchange of
                      choice for retail and institutional workers. We will go a
                      long way toward building trust with existing and
                      prospective partners.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <section className="getmt5 mt-4 pb-5">
          <div className="getsmt5">
            <div className="container">
              <h2 className="text-center">Get Started</h2>
              <div className="row mt-5 align-items-center">
                <div className="col-md-12 col-lg-9">
                  <div className="row">
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                      <img src={require('../../assets/images//algoimg/singupicon1.png')}  alt="logo" className='img-fluid mb-3'/>
                        <h5 className="my-1 mb-3">Signup</h5>
                        <p className="mb-0 font-13">Enter your details and setup an account</p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                      <img src={require('../../assets/images/algoimg/verifyicon1.png')}  alt="logo" className='img-fluid mb-3'/>
                        <h5 className="my-1 mb-3">Verify</h5>
                        <p className="mb-0 font-13">Upload your documents and get verified instantly</p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                      <img src={require('../../assets/images/algoimg/depositicon1.png')}  alt="logo" className='img-fluid mb-3'/>
                        <h5 className="my-1 mb-3">Deposit</h5>
                        <p className="mb-0 font-13">Deposit an amount as low as $50</p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                      <img src={require('../../assets/images/algoimg/tradeicon1.png')}  alt="logo" className='img-fluid mb-3'/>
                        <h5 className="my-1 mb-3">Trade</h5>
                        <p className="mb-0 font-13">Start trading any instrument of your choice and make profits</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center getsbtn mt-5">
                      <div className="openlivebtn">
                        <button type="button" className="btn btn-primary">Open Live Account</button>
                      </div>
                      <div className="opendemobtn">
                        <button type="button" className="btn btn-primary">Open Demo Account</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="getimg">
                  <img src={require('../../assets/images/algoimg/getstartimg.png')}  alt="logo" className='img-fluid'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </div>
  );
};

export default Aboutus;
