import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/ecnomiccal.css";
import { FaBtc } from "react-icons/fa";
import { BsArrowDown } from "react-icons/bs";
const Ecnomiccal = () => {
  // const currencychart = useRef();

  const currencymap = useRef();
  const economiccalender = useRef();

  function addButtonStyleSheet(WidgetConfig) {
    var head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");
    style.innerHTML = `
#${WidgetConfig.ContainerId}-popout-button-container a:hover {
background-color: #CED3D9!important;
}
`;
    head.appendChild(style);
  }

  var currentCalendarUrl = "";
  var isStickyScriptLoaded = false;

  function loadStickyJS(WidgetConfig) {
    var src = WidgetConfig.Url + "/Content/dist/js/sticky.js";
    var scriptEl = document.createElement("script");
    scriptEl.setAttribute("src", src);
    scriptEl.setAttribute("type", "text/javascript");
    scriptEl.addEventListener("load", function () {
      isStickyScriptLoaded = true;
    });
    document.head.appendChild(scriptEl);
  }
  function RemoteCalendar(WidgetConfig) {
    if (WidgetConfig.Url == undefined) {
      WidgetConfig.Url = "https://www.cashbackforex.com";
    }
    loadStickyJS(WidgetConfig);
    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("calendar-id", "ifrm-cbf-" + WidgetConfig.ContainerId); // assign an id
    ifrm.setAttribute("widgetType", "calendar");
    ifrm.setAttribute("scrolling", "no");
    ifrm.setAttribute(
      "style",
      "width:100%; border: 0; overflow: hidden; height:500px;"
    );
    ifrm.setAttribute(
      "onload",
      'iframeLoaded("' + WidgetConfig.ContainerId + '")'
    );
    var frameSrc = WidgetConfig.Url;
    if (WidgetConfig.Lang != undefined) {
      if (
        WidgetConfig.Lang == "page" &&
        this.document.documentElement.lang != ""
      )
        frameSrc += "/" + this.document.documentElement.lang;
      else if (WidgetConfig.Lang != "page") frameSrc += "/" + WidgetConfig.Lang;
    }
    currentCalendarUrl =
      frameSrc + "/tools/" + WidgetConfig.SubPath + "#popout";

    frameSrc += "/widgets/" + WidgetConfig.SubPath;

    if (WidgetConfig.ContainerId !== undefined) {
      frameSrc += "?ContainerId=" + WidgetConfig.ContainerId;

      if (WidgetConfig.DefaultTime !== undefined) {
        frameSrc += "&DefaultTime=" + WidgetConfig.DefaultTime;
      }

      if (WidgetConfig.IsShowEmbedButton !== undefined) {
        frameSrc += "&IsShowEmbedButton=" + WidgetConfig.IsShowEmbedButton;
      }

      if (WidgetConfig.DefaultTheme !== undefined) {
        frameSrc += "&DefaultTheme=" + WidgetConfig.DefaultTheme;
      }
    }

    ifrm.setAttribute("src", frameSrc);

    if (window.addEventListener) {
      window.addEventListener("message", onRemoteCalendarMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", onRemoteCalendarMessage, false);
    }

    var containerDiv = document.getElementById(WidgetConfig.ContainerId);

    containerDiv.setAttribute("id", WidgetConfig.ContainerId + "-container");
    containerDiv.setAttribute(
      "style",
      "display: flex; flex-direction: column; position: relative;"
    );
    containerDiv.appendChild(ifrm, containerDiv);

    document.addEventListener("scroll", onCalendarWidgetScroll);

    const config = { childList: true };

    var stickyDesktopHeaderContainer = document.createElement("div");
    stickyDesktopHeaderContainer.setAttribute(
      "id",
      WidgetConfig.ContainerId + "-desktop-table-header-container-id"
    );
    stickyDesktopHeaderContainer.setAttribute(
      "containerId",
      WidgetConfig.ContainerId
    );
    stickyDesktopHeaderContainer.setAttribute(
      "style",
      "display: none; position: absolute; top: " +
        +"px; width: 100%; height: calc(100% - " +
        +"px);"
    );
    stickyDesktopHeaderContainer.style.pointerEvents = "none";
    stickyDesktopHeaderContainer.setAttribute("class", "stickyContainer");

    var stickyColumn = document.createElement("div");
    stickyColumn.setAttribute(
      "id",
      WidgetConfig.ContainerId + "-sticky-eventDate_table_body"
    );
    stickyColumn.setAttribute("class", "stickyContent");
    stickyColumn.style.position = "absolute";
    stickyColumn.style.top = 0;
    stickyColumn.style.display = "flex";
    stickyColumn.style.flexDirection = "column";
    stickyColumn.style.pointerEvents = "none";
    stickyDesktopHeaderContainer.append(stickyColumn);

    var stickyDesktopHeader = document.createElement("div");
    stickyDesktopHeader.setAttribute(
      "id",
      WidgetConfig.ContainerId + "-desktop-table-header-id"
    );
    stickyDesktopHeader.style.display = "flex";
    stickyDesktopHeader.style.position = "sticky";
    stickyDesktopHeader.style.position = "-webkit-sticky";
    stickyDesktopHeader.style.top = 0;
    stickyDesktopHeader.setAttribute("class", "stickyHeader");
    stickyDesktopHeaderContainer.append(stickyDesktopHeader);

    containerDiv.append(stickyDesktopHeaderContainer);

    var stickyMobileHeaderContainer = document.createElement("div");
    stickyMobileHeaderContainer.setAttribute(
      "id",
      WidgetConfig.ContainerId + "-mobile-table-header-container-id"
    );
    stickyMobileHeaderContainer.setAttribute(
      "containerId",
      WidgetConfig.ContainerId
    );
    stickyMobileHeaderContainer.setAttribute(
      "style",
      "display: none; position: absolute; top: " +
        +"px; width: 100%; height: calc(100% - " +
        +"px);"
    );
    stickyMobileHeaderContainer.setAttribute("class", "stickyContainer");
    stickyMobileHeaderContainer.style.pointerEvents = "none";

    var stickyMobileColumn = document.createElement("div");
    stickyMobileColumn.setAttribute(
      "id",
      WidgetConfig.ContainerId + "-sticky-mobile_eventDate_table_body"
    );
    stickyMobileColumn.setAttribute("class", "stickyContent");
    stickyMobileColumn.style.position = "absolute";
    stickyMobileColumn.style.top = 0;
    stickyMobileColumn.style.display = "flex";
    stickyMobileColumn.style.flexDirection = "column";
    stickyMobileColumn.style.pointerEvents = "none";
    stickyMobileHeaderContainer.append(stickyMobileColumn);

    var stickyMobileHeader = document.createElement("div");
    stickyMobileHeader.setAttribute(
      "id",
      WidgetConfig.ContainerId + "-mobile-table-header-id"
    );
    stickyMobileHeader.style.display = "flex";
    stickyMobileHeader.style.position = "sticky";
    stickyMobileHeader.style.position = "-webkit-sticky";
    stickyMobileHeader.style.top = 0;
    stickyMobileHeader.setAttribute("class", "stickyHeader");
    stickyMobileHeaderContainer.append(stickyMobileHeader);

    containerDiv.append(stickyMobileHeaderContainer);

    var popoutDiv = document.createElement("div");
    popoutDiv.setAttribute(
      "id",
      WidgetConfig.ContainerId + "-popout-button-container"
    );
    popoutDiv.setAttribute(
      "style",
      "position: absolute; top: 1px; right: 1px; display: block;"
    );
    containerDiv.appendChild(popoutDiv);
    addButtonStyleSheet(WidgetConfig);

    ifrm.addEventListener("load", function () {
      ifrm.contentWindow.postMessage(
        {
          method: "calc-log",
          url: window.location.href,
          calc: "economic-calendar",
          host: window.location.host,
        },
        "*"
      );
    });
  }

  function getContainerIdFromEl(el) {
    var containerEl = el.parentElement;
    if (containerEl) {
      var containerId = containerEl.getAttribute("containerId");
      return containerId;
    }
    return "";
  }

  function setAndCheckParent(element) {
    var computedStyles = window.getComputedStyle(element);
    var value = computedStyles.getPropertyValue("overflow");
    if (value === "hidden") {
      element.style["overflow"] = "unset";
    }
    if (element.parentElement) {
      setAndCheckParent(element.parentElement);
    }
  }

  function resizeCalendarWidget(data) {
    var containerId = data.containerId;
    var targetHeight = data.targetHeight;
    var menuHeight = data.menuHeaderHeight;

    var ifrms = document.querySelectorAll(
      "iframe[calendar-id=ifrm-cbf-" + containerId + "]"
    );

    for (var i = 0; i < ifrms.length; i++) {
      var rect = ifrms[i].getBoundingClientRect();
      var offsetLeft = rect.left;
      var offsetTop = rect.top;
      var frameHeight = targetHeight;

      if (targetHeight < window.innerHeight) {
        frameHeight = window.innerHeight - offsetTop - 10;
      }
      ifrms[i].style.height = frameHeight + "px";
      ifrms[i].contentWindow.postMessage(
        {
          type: "onLayoutChange",
          data: {
            width: window.innerWidth,
            height: window.innerHeight,
            frameHeight: frameHeight,
            offsetLeft: offsetLeft,
            offsetTop: offsetTop,
            scrollX: window.scrollX,
            scrollY: window.scrollY,
          },
        },
        "*"
      );

      var headers = document.querySelectorAll(
        "#" + containerId + "-container .stickyContainer"
      );
      for (var i = 0; i < headers.length; i++) {
        var header = headers[i];
        header.style.top = menuHeight + "px";
        var headerHeight = frameHeight - menuHeight;
        header.style.height = headerHeight + "px";

        var headerElems = header.getElementsByClassName("stickyHeader");
        if (headerElems.length === 0) {
          continue;
        }
        var headerElem = headerElems[0];
        var headerHeight = headerElem.offsetHeight;
        var contentElems = header.getElementsByClassName("stickyContent");
        if (contentElems.length === 0) {
          continue;
        }
        var contentElem = contentElems[0];

        if (headerHeight > 0) {
          contentElem.style.top = headerHeight + "px";
        }
      }
    }
  }
  function postMessageToIframe(containerId, data) {
    var ifrms = document.querySelectorAll(
      "iframe[calendar-id=ifrm-cbf-" + containerId + "]"
    );
    for (var i = 0; i < ifrms.length; i++) {
      ifrms[i].contentWindow.postMessage(data, "*");
    }
  }

  function onCalendarWidgetScroll() {
    var ifrms = document.querySelectorAll("iframe[widgetType=calendar]");
    for (var i = 0; i < ifrms.length; i++) {
      var rect = ifrms[i].getBoundingClientRect();
      var offsetLeft = rect.left;
      var offsetTop = rect.top;
      var frameHeight = ifrms[i].clientHeight;
      ifrms[i].contentWindow.postMessage(
        {
          type: "onScroll",
          data: {
            width: window.innerWidth,
            height: window.innerHeight,
            frameHeight: frameHeight,
            offsetLeft: offsetLeft,
            offsetTop: offsetTop,
            scrollX: window.scrollX,
            scrollY: window.scrollY,
          },
        },
        "*"
      );
    }
  }

  function onChangedModalDialogOpenState(data) {
    var containerId = data.containerId;
    if (data.isOpen) {
      hideStickyElements(containerId);
    } else {
      showStickyElements(containerId);
    }
  }
  function showStickyElements(containerId) {
    var desktopElement = document.getElementById(
      containerId + "-desktop-table-header-container-id"
    );
    var mobileElement = document.getElementById(
      containerId + "-mobile-table-header-container-id"
    );
    var popoutContainer = document.getElementById(
      containerId + "-popout-button-container"
    );
    var origin = desktopElement.getAttribute("origin");
    desktopElement.style.display = origin;
    desktopElement.setAttribute("origin", "");
    var mobileOrigin = mobileElement.getAttribute("origin");
    mobileElement.style.display = mobileOrigin;
    mobileElement.setAttribute("origin", "");

    var popoutOrigin = popoutContainer.getAttribute("origin");
    popoutContainer.style.display = popoutOrigin;
    popoutContainer.setAttribute("origin", "");
  }

  function hideStickyElements(containerId) {
    var desktopElement = document.getElementById(
      containerId + "-desktop-table-header-container-id"
    );
    var mobileElement = document.getElementById(
      containerId + "-mobile-table-header-container-id"
    );
    var popoutContainer = document.getElementById(
      containerId + "-popout-button-container"
    );
    desktopElement.setAttribute("origin", desktopElement.style.display);
    desktopElement.style.display = "none";
    mobileElement.setAttribute("origin", mobileElement.style.display);
    mobileElement.style.display = "none";
    popoutContainer.setAttribute("origin", popoutContainer.style.display);
    popoutContainer.style.display = "none";
  }
  function onSetTitle(data) {
    var containerId = data.containerId;
    var title = data.title;
    var popoutTitle = data.popoutTitle;
    var aTagId = "popout-" + containerId;

    var ifrms = document.querySelectorAll(
      "iframe[calendar-id=ifrm-cbf-" + containerId + "]"
    );
    for (var i = 0; i < ifrms.length; i++) {
      ifrms[i].setAttribute("title", title);
    }

    var aTag = document.getElementById(aTagId);
    if (aTag) {
      aTag.setAttribute("title", popoutTitle);
    }
  }
  var hcDesktopHeader, hcMobileHeader;
  var hcCols = {
    desktop: [],
    mobile: [],
  };

  function getHeaderHeight(containerId, id) {
    var headerId =
      id === "eventDate_table_body"
        ? "-desktop-table-header-id"
        : "-mobile-table-header-id";
    var el = document.getElementById(containerId + headerId);
    if (el) {
      return el.getBoundingClientRect().height;
    }
    return 0;
  }

  function destroyStickyHeaders(id) {
    if (id === "desktop-table-header-id") {
      if (hcDesktopHeader !== undefined) {
        hcDesktopHeader.destroy();
        hcDesktopHeader.destroy();
      }
    } else {
      if (hcMobileHeader !== undefined) {
        hcMobileHeader.destroy();
      }
    }
  }
  function destroyStickyCols(id) {
    var colIndex = id === "eventDate_table_body" ? "desktop" : "mobile";
    for (var i = 0; i < hcCols[colIndex].length; i++) {
      hcCols[colIndex][i].destroy();
    }
    hcCols[colIndex] = [];
  }

  function onSetCreatedStickyHeader(data) {
    var containerId = data.containerId;
    var originalId = data.originalId;
    var stickyHeader = document.getElementById(containerId + "-" + originalId);
    destroyStickyHeaders(originalId);
    if (stickyHeader !== null) {
      stickyHeader.innerHTML = data.element;
    }
  }

  function onSetCreatedStickyContent(data) {
    var containerId = data.containerId;
    var originalId = data.originalId;
    var top = data.top;
    var stickyHeader = document.getElementById(
      containerId + "-sticky-" + originalId
    );
    if (stickyHeader) {
      stickyHeader.style.top = top + "px";
      destroyStickyCols(originalId);
      stickyHeader.innerHTML = data.element;
    }
  }
  function onCreatedPopoutButton(data) {
    var containerId = data.containerId;
    var element = data.element;
    var styles = data.styles;
    var popoutContainer = document.getElementById(
      containerId + "-popout-button-container"
    );
    if (popoutContainer !== null) {
      popoutContainer.innerHTML = "";
      popoutContainer.insertAdjacentHTML("beforeend", element);
      var atags = popoutContainer.getElementsByTagName("a");
      if (atags.length > 0) {
        atags[0].href = currentCalendarUrl;
        var imgs = atags[0].getElementsByTagName("img");
        if (imgs.length > 0) {
          imgs[0].style["filter"] = styles["filter"];
        }
      }
    }
  }
  function onRemoteCalendarMessage(event) {
    var data = event.data;
    if (data.type === "resize") {
      resizeCalendarWidget(data.data);
    } else if (data.type === "modalState") {
      onChangedModalDialogOpenState(data.data);
    } else if (data.type === "setTitle") {
      onSetTitle(data.data);
    } else if (data.type === "createdStickyHeader") {
      onSetCreatedStickyHeader(data.data);
    } else if (data.type === "replaceElement") {
      replaceElementContent(data.data);
    } else if (data.type === "setStyle") {
      setElementStyle(data.data);
    } else if (data.type === "setVisibility") {
      setVisibility(data.data);
    } else if (data.type === "createdStickyContent") {
      onSetCreatedStickyContent(data.data);
    } else if (data.type === "createdPopoutButton") {
      onCreatedPopoutButton(data.data);
    } else if (data.type === "showStickyShowHide") {
      if (data.data.show) {
        showStickyElements(data.data.containerId);
      } else {
        hideStickyElements(data.data.containerId);
      }
    }
  }

  function replaceElementContent(data) {
    var id = data.id;
    var element = data.element;
    var containerId = data.containerId;
    var el = document.querySelector("#" + containerId + "-container #" + id);
    if (el !== null) {
      el.innerHTML = "";
      el.insertAdjacentHTML("beforeend", element);
    }
  }

  function setElementStyle(data) {
    var id = data.id;
    var containerId = data.containerId;
    var styles = data.styles;
    var ele = document.getElementById(containerId + "-" + id);
    if (ele) {
      for (var i = 0; i < styles.length; i++) {
        var style = styles[i];
        var key = style.key;
        var value = style.value;
        if (value === "table" && key === "display") {
          ele.parentElement.style[key] = "block";
        } else {
          ele.parentElement.style[key] = value;
        }
      }
    }
  }
  function setVisibility(data) {
    var id = data.id;
    var containerId = data.containerId;
    var styles = data.styles;
    var ele = document.getElementById(containerId + "-" + id);
    if (ele) {
      for (var i = 0; i < styles.length; i++) {
        var style = styles[i];
        var key = style.key;
        var value = style.value;
        ele.style[key] = value;
      }
    }
  }
  function onClickedImpactOnTableHeader(e) {
    var containerId = e.parentElement.parentElement.getAttribute("containerId");
    postMessageToIframe(containerId, {
      type: "runFunc",
      data: {
        name: "onClickedImpactOnTableHeader",
        containerId: containerId,
      },
    });
  }

  function onClickedTimeOnTableHeader(e) {
    var containerId = e.parentElement.parentElement.getAttribute("containerId");
    postMessageToIframe(containerId, {
      type: "runFunc",
      data: {
        name: "onClickedTimeOnTableHeader",
        containerId: containerId,
      },
    });
  }

  function iframeLoaded(iframeContainerWidgetId) {}

  window.RemoteCalendar = RemoteCalendar;

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.cashbackforex.com/Content/remote/remote-calendar-widget.js";
    script.async = false;
    script.innerHTML = JSON.stringify(
      RemoteCalendar({
        DefaultTime: "today",
        DefaultTheme: "plain",
        SubPath: "economic-calendar",
        IsShowEmbedButton: false,
        ContainerId: "economic-calendar-668257",
      })
    );
    economiccalender.current.appendChild(script);
  }, []);

  const exchangerate = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js";

    script.async = false;

    script.innerHTML = JSON.stringify({
      width: "100%",
      height: 400,
      currencies: ["EUR", "USD", "JPY", "GBP", "CHF", "AUD", "CAD", "NZD"],
      isTransparent: false,
      colorTheme: "light",
      locale: "in",
    });
    exchangerate.current.appendChild(script);
  }, []);

  // useEffect(() => {
  // const script = document.createElement("script");
  // script.src =
  // "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
  // script.async = false;
  // script.innerHTML = JSON.stringify({
  // colorTheme: "light",
  // isTransparent: false,
  // width: "100%",
  // height: "600",
  // locale: "in",
  // importanceFilter: "-1,0,1",
  // currencyFilter:
  // "USD,EUR,ITL,NZD,CHF,AUD,FRF,JPY,ZAR,TRL,CAD,DEM,MXN,ESP,GBP",
  // });
  // currencychart.current.appendChild(script);
  // }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.async = false;
    script.innerHTML = JSON.stringify({
      colorTheme: "light",
      dateRange: "12M",
      showChart: true,
      locale: "in",
      largeChartUrl: "",
      isTransparent: false,
      showSymbolLogo: true,
      showFloatingTooltip: false,
      width: "100%",
      height: "660",
      plotLineColorGrowing: "rgba(41, 98, 255, 1)",
      plotLineColorFalling: "rgba(41, 98, 255, 1)",
      gridLineColor: "rgba(240, 243, 250, 0)",
      scaleFontColor: "rgba(106, 109, 120, 1)",
      belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
      belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
      symbolActiveColor: "rgba(41, 98, 255, 0.12)",
      tabs: [
        {
          title: "Forex",
          symbols: [
            {
              s: "FX:EURUSD",
              d: "EUR/USD",
            },
            {
              s: "FX:GBPUSD",
              d: "GBP/USD",
            },
            {
              s: "FX:USDJPY",
              d: "USD/JPY",
            },
            {
              s: "FX:USDCHF",
              d: "USD/CHF",
            },
            {
              s: "FX:AUDUSD",
              d: "AUD/USD",
            },
            {
              s: "FX:USDCAD",
              d: "USD/CAD",
            },
          ],
          originalTitle: "Forex",
        },
      ],
    });

    currencymap.current.appendChild(script);
  }, []);
  return (
    <div>
      <div className="Economic calendar">
        <section className="Economic us">
          <div className="economicbanner">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center mt-5">
                  <h1 className=" mt-5">Economic Calendar</h1>
                  <p className=" mb-3">
                    You could be making more profit than you know
                  </p>
                  <button type="button" className="btn getmeta5 ">
                    Get Started
                  </button>
                </div>
                <div className="col-md-12 col-lg-6 mt-5">
                  <img
                    src={require("../../assets/images/calender/economic-calender.png")}
                    alt="logo"
                    className="img-fluid mt-5"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="choose mt-5">
          <div className="container">
            <div className="row">
              <div className="choose-text text-center">
                <h2>Economic Calendar</h2>
                <p>Economic Calendar For Your Better Forex Trading!!!</p>
                <p className="ecnomicpara">
                  Utilizing Milliva's Economic Calendar makes it simple to stay
                  on top of significant events that could affect your trade.
                  Analyze historical data sets as soon as possible compared to
                  market consensus, and examine volatility for potential trade
                  ideas.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="calendar table mt-5">
          <div className="marketanaly pb-5 mb-5 mt-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-lg-2">
                  <div className="markettab pb-5 mb-5 mt-4">
                    <div className="nav-tabs-menu">
                      <ul
                        className="nav nav-tabs d-flex"
                        id="ProductTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="Currecny-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Currecny"
                            type="button"
                            role="tab"
                            aria-controls="Currecny"
                            aria-selected="true"
                          >
                            Currencies
                          </button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="Crypto-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Crypto"
                            type="button"
                            role="tab"
                            aria-controls="Crypto"
                            aria-selected="false"
                          >
                            Crypto
                          </button>
                        </li> */}
                        {/* <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="Shares-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#Shares"
                            type="button"
                            role="tab"
                            aria-controls="Shares"
                            aria-selected="false"
                          >
                            Shares
                          </button>
                        </li> */}
                      </ul>
                    </div>
                    <div className="tab-content" id="ProductTabContent">
                      <div
                        className="tab-pane fade active show"
                        id="Currecny"
                        role="tabpanel"
                        aria-labelledby="Currecny-tab"
                      >
                        <div className="curre">
                          {" "}
                          <div ref={currencymap}></div>
                          <div className="marketbtn d-block flex-column align-items-center">
                            <a className="optrade btn mb-3" href="#">
                              Open trading account
                            </a>
                            <a className="downtrade btn" href="#">
                              Download trading platform
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="Crypto"
                        role="tabpanel"
                        aria-labelledby="Crypto-tab"
                      >
                        <div className="curre">
                          <img
                            src={require("../../assets/images/calender/marketchart.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">EURUSD</a>
                                  </td>
                                  <td className="val1">
                                    {" "}
                                    <a href="#">1.06</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">0.58</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -0.25%{" "}
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">GBPUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.2062</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.3967</a>
                                  </td>
                                  <td className="val4">
                                    <a href="">
                                      -0.23%{" "}
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDJPY</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">130.15</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.36</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.2%
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCHF</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">0.9237</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.365</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.9%
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCAD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.3560</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.51</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.8%
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">AUDUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.298</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.0013</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.8%
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="marketbtn d-block flex-column align-items-center">
                            <a className="optrade btn mb-3" href="#">
                              Open trading account
                            </a>
                            <a className="downtrade btn" href="#">
                              Download trading platform
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="Shares"
                        role="tabpanel"
                        aria-labelledby="Shares-tab"
                      >
                        <div className="curre">
                          <img
                            src={require("../../assets/images/calender/marketchart.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">EURUSD</a>
                                  </td>
                                  <td className="val1">
                                    {" "}
                                    <a href="#">1.066</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">0.222</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -0.15%
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">GBPUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.2072</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.967</a>
                                  </td>
                                  <td className="val4">
                                    <a href="">
                                      -0.52%{" "}
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDJPY</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.995</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.36</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.2%
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCHF</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">0.9537</a>
                                  </td>
                                  <td className="val2">
                                    <a href="#">-0.465</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.9%
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">USDCAD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.7896</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.011</a>
                                  </td>
                                  <td className="val4">
                                    <a href="#">
                                      -00.5%
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="coinname">
                                    <a href="#">AUDUSD</a>
                                  </td>
                                  <td className="val1">
                                    <a href="#">1.898</a>
                                  </td>
                                  <td className="val3">
                                    <a href="#">-0.0013</a>
                                  </td>
                                  <td className="val5">
                                    <a href="#">
                                      -00.3%
                                      <i className="fa fa-angle-down mx-2" />
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="marketbtn d-block flex-column align-items-center">
                            <a className="optrade btn mb-3" href="#">
                              Open trading account
                            </a>
                            <a className="downtrade btn" href="#">
                              Download trading platform
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div
                    id="economic-calendar-668257"
                    ref={economiccalender}
                  ></div>
                </div>

                <div className="col-md-12 col-lg-2">
                  <div className="marketanlysec3 mt-4">
                    <div className="d-flex exchnage justify-content-between">
                      <div className="exchnage1">
                        <h6>Exchange rates</h6>
                      </div>
                    </div>
                    <div ref={exchangerate}></div>
                    <div className="d-flex exchnage1 justify-content-between">
                      <div className="exchnage1">
                        <h6>Last date</h6>
                      </div>
                      <div className="exchnagedate">
                        {" "}
                        <h6>5.15PM</h6>
                      </div>
                    </div>
                  </div>
                  <div className="tradingins mt-3">
                    <h6 className="text-center">Trading Instruments</h6>
                    <p className="text-center">
                      The best instruments for successful online Forex trading
                    </p>
                    <ul className="traditems d-block justify-content-center align-items-center">
                      <li>
                        <img
                          src={require("../../assets/images/metatradeicon1.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        MetaTrader Trading Platform
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/tcharticon1.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        Charts on-line
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/mtanalyicon1.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        Forex Analysis
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/mtdemoicon1.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        Forex Demo Account
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/tradecalc.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        Trade calculator
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/mtindicatoricon1.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        Fores Indicators
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/mtinstrument.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        Trading Instruments
                      </li>
                      <li>
                        <img
                          src={require("../../assets/images/mtgadget.png")}
                          alt="logo"
                          className="img-fluid"
                        />
                        Insta Forex Gadget
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="calenderadvandage mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="calenderadvandage-text">
                  <h4>About the Economic Calendar</h4>
                  <p>
                    An economic calendar is a list of important news releases
                    and events that could impact the direction of currency
                    exchange rates and the overall financial market.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <h4>Why You Should Use The Economic Calendar</h4>
                <p>
                  The Economic Calendar highlights significant occurrences in
                  domestic and global politics and economics that could
                  considerably impact the price changes of trading instruments.
                  The calendar, which is automatically updated, enables traders
                  to be informed about upcoming events that can affect their
                  trades.
                </p>
              </div>
              <div className="col-lg-6 mt-4">
                <h4>How To Use The Economic Calendar</h4>
                <p>
                  You may select from various currencies and equities from the
                  dropdown option. All will be chosen. Following your choice,
                  you can see the future events in the table below the drop-down
                  option. The number of trading options fluctuates based on
                  incoming news and events volume..
                </p>
                <p />
              </div>
              <div className="col-lg-6 mt-4">
                <h4>How To Read The Economic Calendar</h4>
                <p>
                  After choosing your preferred instruments, you will get a
                  calendar with a list of events. A red indicator is displayed
                  under the "Impact" column to indicate important news that may
                  increase margin requirements. The "Actual" and "Forecast"
                  numbers in the table are essential. A large discrepancy
                  between the two values suggests that there may be some
                  volatility in the connected trading instruments' charts.
                  Typically, volatility increases 15 minutes before an event and
                  decreases 15 minutes after the occurrence.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Ecnomiccal;
