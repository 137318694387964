import React, { useEffect, useState, useMemo } from "react";
import "../../assets/css/promotions.css";
import { useForm, Controller } from "react-hook-form";
import { VALIDATION_PATTERN } from "../Signin/Store";
import { promotion } from "../Helper/Api";
import {
  BsFillEnvelopeFill,
  BsFillTelephoneFill,
  BsFillChatSquareFill,
} from "react-icons/bs";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import countryList from "react-select-country-list";
import Select from "react-select";

const Promotions = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [value, setValues] = useState();
  const options = useMemo(() => countryList().getData(), []);
  const changeHandler = (e) => {
    setValues(e?.label);
  };

  const Submit = (data) => {
    data["country"] = value;
    setLoading(true);
    promotion(data)
      .then((res) => {
        if (res.status) {
          setLoading(false);

          NotificationManager.success(res.data.message, "Success");

          reset();
          setValue("country", "");
        } else {
          NotificationManager.error(res.data.message, "Failed");
          setLoading(false);

          reset();
          setValue("country", "");
        }
      })
      .catch((err) => {
        reset();
        setLoading(false);
        setValue("country", "");
        NotificationManager.error("Some Error Occured", "Failed");
      });
  };
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <div>
      <NotificationContainer />
      <div className="promotions">
        <section className="promitionban">
          <div className="procontbanner">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6">
                  <h1 className=" mb-2">Make Trading Yours With Milliva</h1>
                  <p className="mb-3">
                    No Better Way to Start the Trade than Milliva
                  </p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/promotions/bgimgpromotion1.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="promoforex">
          <div className="container-fluid">
            <div className="tradepromo">
              <h2 className="text-center pt-4">Why trade with Milliva?</h2>
            </div>
          </div>
        </section>
        <div className="promotrade">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-3">
                <div className="promo-box promo-fold">
                  <img
                    src={require("../../assets/images/promotions/promoicon1.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h5>Indomitable trading costs</h5>
                  <p>
                    Take advantage of the narrow spreads and extremely low
                    commissionrates.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-3">
                <div className="promo-box promo-fold">
                  <img
                    src={require("../../assets/images/promotions/promoicon2.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h5>Immediate execution speeds </h5>
                  <p>
                    As a result of your trades being carried out in
                    milliseconds, you will always receive the best market price.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-3">
                <div className="promo-box promo-fold">
                  <img
                    src={require("../../assets/images/promotions/promoicon3.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h5>World’s Best Popular Platform</h5>
                  <p>
                    Free to download and accessible on your preferred PC, Mac,
                    mobile device, or tablet.
                  </p>
                </div>
              </div>
              <div className="col-md-12 col-lg-3">
                <div className="promo-box promo-fold">
                  <img
                    src={require("../../assets/images/promotions/promoicon4.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h5>Level up your trading skills</h5>
                  <p>
                    All levels of experience are welcome. Free instructional
                    resources to aid in the development of your trading
                    abilities.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center ">
                <a
                  className="reg"
                  href="https://crm.milliva.com/client/register/6169896c79fd7"
                >
                  Register to join
                </a>
              </div>
            </div>
          </div>
        </div>
        <section className="bettertrade">
          <div className="container">
            <div className="promotiontrade">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6">
                  <h2 className="mb-3">
                    Encouragement - for even better value{" "}
                  </h2>
                  <p>
                    Everyone enjoys receiving a small bonus on their returns and
                    we take great pleasure in making that happen. We frequently
                    give deals to our devoted consumers as a result. .
                  </p>
                  <p>
                    If you want to participate in any promotion, you must read
                    and agree to the terms and conditions
                  </p>
                  <div className="regbtns my-4">
                    <a
                      className="regbtn"
                      href="https://crm.milliva.com/client/register/6169896c79fd7"
                    >
                      Register to join
                    </a>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/promotions/announcement.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contesttrade mt-5">
          <div className="container">
            <div className="contesttradesec">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/promotions/forexcontest1.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-12 col-lg-6">
                  <h2 className="mb-3">Challenge</h2>
                  <p>
                    Show off your trading skills in one of our fun forex
                    competitions!
                  </p>
                  <p>
                    A little bit of fun along the way Enter our upcoming
                    competition to compete against traders from across the globe
                    for fantastic prizes, prestigious titles, and the
                    opportunity to find new market possibilities as you go. You
                    must read and agree to the terms and conditions of every
                    contest before you can enter.
                  </p>
                  <div className="regbtns my-4">
                    <a
                      className="regbtn"
                      href="https://crm.milliva.com/client/register/6169896c79fd7"
                    >
                      Register to join
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="refer mt-5 pb-5">
          <div className="container">
            <div className="promorefer">
              <div className="card">
                <p className="text-center">
                  IT'S $50 FOR YOU. $50 FOR A FRIEND...
                </p>
                <h2 className="text-center">Refer a Friend Program</h2>
                <p className="text-center mb-3">
                  <small>when you refer them to Milliva !</small>
                </p>
                <div className="startrefer text-center mt-2">
                  <a
                    className="regbtn btn"
                    href="https://crm.milliva.com/client/register/6169896c79fd7"
                  >
                    Register to join
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="cont mt-5 pb-5">
          <div className="container">
            <div className="seccontact">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6">
                  {/* <div class="nav-tabs-menu">
      <ul class="nav nav-tabs d-flex" id="ProductTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="Description-tab" data-bs-toggle="tab" data-bs-target="#Description" type="button" role="tab" aria-controls="Description" aria-selected="true">Registration</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="Reviews-tab" data-bs-toggle="tab" data-bs-target="#Reviews" type="button" role="tab" aria-controls="Reviews" aria-selected="false">Login</button>
          </li>
          
      </ul>
  </div> */}
                  <div className="card mt-4 px-3">
                    <form onSubmit={handleSubmit(Submit)}>
                      <div className="form-outline mt-4 mb-4">
                        <label className="form-label" htmlFor="form6Example1">
                          First name
                        </label>
                        <input
                          type="text"
                          id="form6Example1"
                          className="form-control"
                          {...register("name", {
                            required: {
                              value: true,
                              message: "Please enter first name",
                            },
                            pattern: {
                              value: /^(?=.[a-zA-Z +\s])[a-zA-Z0-9].{1,30}$/i,
                              message: " Enter Valid  Name",
                            },
                          })}
                        />
                        <div>
                          <p className="text-danger">
                            {errors.name && (
                              <span role="alert">Please enter first name</span>
                            )}
                          </p>
                        </div>
                      </div>

                      {/* <div className="col">
                    <div className="form-outline">
                      <label className="form-label" htmlFor="form6Example2">
                        Last name
                      </label>
                      <input
                        type="text"
                        id="form6Example2"
                        className="form-control"
                      />
                    </div>
                  </div> */}

                      {/* Email input */}
                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="form6Example5">
                          Email
                        </label>
                        <input
                          type="email"
                          id="form6Example5"
                          className="form-control"
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required",
                            },
                            pattern: {
                              value: VALIDATION_PATTERN.EMAIL,
                              message: "Enter Valid Email",
                            },
                          })}
                        />
                        <div>
                          <p className="text-danger">
                            {errors.email && (
                              <span role="alert">Email is required</span>
                            )}
                          </p>
                        </div>
                      </div>
                      {/* passwordd */}
                      {/* <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="form6Example5">
                    Password
                  </label>
                  <input
                    type="password"
                    id="form6Example5"
                    className="form-control"
                  />
                </div> */}
                      <label className="form-label" htmlFor="form6Example6">
                        Country
                      </label>

                      <Controller
                        control={control}
                        name="country"
                        rules={{
                          required: {
                            value: true,
                            message: "Select your country",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <Select
                              options={options}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                changeHandler(e);
                              }}
                            />
                          );
                        }}
                      />

                      <p className="text-danger">
                        {errors.country && errors.country.message}
                      </p>

                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="form6Example6">
                          Phone
                        </label>
                        <input
                          type="number"
                          id="form6Example6"
                          className="form-control"
                          {...register("phone", {
                            required: {
                              value: true,
                              message: "Please enter a number",
                            },
                          })}
                          onKeyPress={(event) => {
                            if (
                              !/[0-9 ]+$/.test(event.key) ||
                              event.key === " "
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <p className="text-danger">
                          {errors.phone && (
                            <span role="alert">Please enter a number</span>
                          )}
                        </p>
                      </div>

                      {/* Checkbox */}
                      <div className="form-check d-flex justify-content-center mb-4">
                        <input
                          className={`form-check-input  ${
                            errors.checkbox ? "border-danger" : ""
                          }`}
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckDefault"
                          {...register("checkbox", {
                            required: { value: true },
                          })}
                        />
                        <label
                          className={`form-check-label mx-2 ${
                            errors.checkbox ? "text-danger" : ""
                          }`}
                        >
                          By clicking here I give my consent for Milliva to
                          contact me for marketing purposes. You can opt out at
                          any time. For further details please see our Marketing
                          and Communication Policy Statement.
                        </label>
                      </div>
                      {/* Submit button */}
                      {loading ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-primary btn-block mb-4 m-auto"
                          >
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-primary btn-block mb-4 m-auto"
                          >
                            Register now
                          </button>
                        </>
                      )}
                    </form>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="contsecform mx-5 my-5">
                    <h2 className="mb-3">
                      Get even more exciting trading opportunities with Milliva.
                    </h2>
                    <p className="mb-3">
                      Any questions about our contests or promotions?
                    </p>
                    <p>
                      <small>
                        Contact our Customer Support team who will be more than
                        happy to answer your questions.
                      </small>
                    </p>
                    <div className="socialcont d-flex justify-content-start mt-5">
                      <div className="email">
                        <a href="mailto:support@milliva.com" target="_blank">
                          <i class="fa fa-envelope" aria-hidden="true"></i>
                        </a>
                      </div>

                      <div className="chat">
                        <a href="#" className="mess1">
                          <i class="fa fa-comment"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Promotions;
