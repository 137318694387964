import React, { useEffect, useState, useMemo } from "react";
import "../../assets/css/whitelabel.css";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { VALIDATION_PATTERN } from "../Signin/Store";
import { Link } from "react-router-dom";
import { Requestcall } from "../Helper/Api";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import countryList from "react-select-country-list";

const Whitelabel = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 50,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  const [value, setValues] = useState();
  const options = useMemo(() => countryList().getData(), []);
  const changeHandler = (e) => {
    setValues(e?.label);
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const submit = (data) => {
    data["country"] = value;

    setLoading(true);
    Requestcall(data)
      .then((res) => {
        if (res.data.status == 1) {
          setLoading(false);

          NotificationManager.success(res.data.message, "Success");
          reset();
          setValue("country", "");
        } else {
          NotificationManager.error(res.data.message, "Failed");
          setLoading(false);
          reset();
          setValue("country", "");
        }
      })
      .catch((err) => {
        reset();
        setLoading(false);
        setValue("country", "");
        NotificationManager.error("Some Error Occured", "Failed");
      });
  };

  return (
    <div>
      <NotificationContainer />
      <div className="white-lable">
        <section id="white-lable">
          <section className="white-banner">
            <div className="white-lable-banner">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                    <h1 className=" mb-2">White Label Solution</h1>
                    <p className=" mb-3">
                      Milliva offers a complete marketing and trading solution
                      to help you set up your brokerage business
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/whitelabel/whitelabel-banner.png")}
                      alt="logo"
                      className="img-fluid whit-banner-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        {/* ================================what is white lable solution============================ */}
        <div className="what-is-white mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img
                  src={require("../../assets/images/whitelabel/white-img8.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="white-what">
                  <h2 className="mb-3">What is White Label Solutions?</h2>
                  <p>
                    Because of the ever-changing technologies involved in
                    trading, the Forex trading landscape is growing more complex
                    and challenging.{" "}
                  </p>
                  <p>
                    White Label Solutions was created to supplement and enhance
                    trading for established trading houses and brokerages.
                    Milliva has created industry-leading turnkey solutions to
                    capitalize on unconstrained growth potential.
                  </p>
                  <p>
                    White Label Solutions is the go-to trading platform and
                    support system for growing your core trading business.
                  </p>

                  <p>
                    {" "}
                    We have extensive experience supplying you with creative and
                    personalized solutions specifically tailored to your client,
                    hence increasing your profits. We provide unrivaled
                    solutions to help you offer value-added offerings to your
                    clients and improve their trading experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="white-partnership">
          <div className="partnership">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <h2 className="mb-3">
                    How does a White Label Partnership Program work?
                  </h2>
                  <p>
                    The Milliva White Label Partnership Program is a one-stop
                    shop for clients who want to launch their trading platform.{" "}
                  </p>
                  <p>
                    Establishing a forex and CFDs brokerage is a time-consuming
                    and expensive procedure.{" "}
                  </p>
                  <p>
                    Through our White Label Partnership Program, Milliva
                    accelerates your success by giving you access to
                    cutting-edge technology, full-service support, branded
                    trading platforms, and worldwide markets such as Forex,
                    Indices, Commodities, Share CFDs, and more.
                  </p>
                  <p className="mb-4">
                    We will be there for you, offering our extensive knowledge
                    in risk management, liquidity provision, marketing, and
                    client relationship management so that you can launch your
                    White Label Brokerage at the lowest possible cost.
                  </p>
                  <Link to="/contact">
                  <button type="button" className="btn getmeta5 ">
                    CONTACT US
                  </button>
                 </Link>
                  
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className="image-partner">
                    <img
                      src={require("../../assets/images/whitelabel/partner2.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="white-benifits ">
          <div className="container">
            <div className="benifits mt-5">
              <h2>Forex White Label Benefits</h2>
              <div className="card-line1">
                <div className="row mt-5">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="flip-box">
                      <div className="flip-box-inner">
                        <div className="flip-box-front">
                          <img
                            src={require("../../assets/images/whitelabel/white-lable-1.png")}
                            alt="logo"
                            style={{ width: "60", height: "60" }}
                            className="img-fluid mb-3"
                          />
                          <h3>Custom made service</h3>
                        </div>
                        <div className="flip-box-back">
                          <p>
                            Our tailored solutions assist you in staying ahead
                            of the marketplace and remaining relevant in all
                            trading settings.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="flip-box">
                      <div className="flip-box-inner">
                        <div className="flip-box-front">
                          <img
                            src={require("../../assets/images/whitelabel/white-lable-2.png")}
                            alt="logo"
                            style={{ width: "60", height: "60" }}
                            className="img-fluid mb-3"
                          />
                           <h3>Assurance</h3>
                        </div>
                        <div className="flip-box-back">
                          <p>
                            {" "}
                            We guarantee deep liquidity and excellent spreads
                            starting at 0.0 pips.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="flip-box">
                      <div className="flip-box-inner">
                        <div className="flip-box-front">
                          <img
                            src={require("../../assets/images/whitelabel/liquidity-3.png")}
                            alt="logo"
                            style={{ width: "60", height: "60" }}
                            className="img-fluid mb-3"
                          />
                          <h3>Aggregated pricing</h3>
                        </div>
                        <div className="flip-box-back">
                          <p>
                            We guarantee aggregated pricing feeds from reputable
                            financial companies and institutions.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-line2">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="flip-box">
                      <div className="flip-box-inner">
                        <div className="flip-box-front">
                          <img
                            src={require("../../assets/images/whitelabel/aggregate-4.png")}
                            alt="logo"
                            style={{ width: "60", height: "60" }}
                            className="img-fluid mb-3"
                          />
                          <h3>Real-Time Execution</h3>
                        </div>
                        <div className="flip-box-back">
                          <p>
                            We provide real-time execution, several liquidity
                            options, and highly competitive prices.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="flip-box">
                      <div className="flip-box-inner">
                        <div className="flip-box-front">
                          <img
                            src={require("../../assets/images/whitelabel/delivar-5.png")}
                            alt="logo"
                            style={{ width: "60", height: "60" }}
                            className="img-fluid mb-3"
                          />
                           <h3>Brand Developing</h3>
                        </div>
                        <div className="flip-box-back">
                          <p>We assist you in developing your brand.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4">
                    <div className="flip-box">
                      <div className="flip-box-inner">
                        <div className="flip-box-front">
                          <img
                            src={require("../../assets/images/whitelabel/brand-6.png")}
                            alt="logo"
                            style={{ width: "60", height: "60" }}
                            className="img-fluid mb-3"
                          />
                             <h3>Built Strategy</h3>
                        </div>
                        <div className="flip-box-back">
                          <p> We help build your brand.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="partnership-program" className="mt-5">
          <div className="container">
            <h2 className="pt-4">
              Key Features of Vantage White Label Partnership Program
            </h2>
            <div className="white-program">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="line-one">
                    <h5>Branded</h5>
                    <span>
                      {" "}
                      <strong>MT4/MT5</strong>
                    </span>
                    <h5>Trading Platforms</h5>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="line-one">
                    <h5>Customisable</h5>
                    <span>
                      <strong>Spreads</strong>
                    </span>
                    <h5>and Commissions</h5>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="line-one">
                    <h5>Risk management</h5>
                    <span>
                      <strong>Tools</strong>
                    </span>
                    <h5>and Reports</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="line-two">
                    <h5>Offer Clients</h5>
                    <span>
                      <strong>1200+</strong>
                    </span>
                    <h5>Tradable Instruments</h5>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="line-two">
                    <h5>Ultra-Fast Trade</h5>
                    <span>
                      <strong>Execution</strong>
                    </span>
                    <h5>and Deep Liquidity</h5>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4">
                  <div className="line-two">
                    <h5>Dedicated</h5>
                    <span>
                      <strong>Customer</strong>
                    </span>
                    <h5>Support</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="getmt5 mt-5 pb-5">
          <div className="getsmt5">
            <div className="container">
              <h2 className="text-center">Get Started</h2>
              <div className="row mt-4 align-items-center">
                <div className="col-md-12 col-lg-9">
                  <div className="row">
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                      <img src={require('../../assets/images//algoimg/singupicon1.png')}  alt="logo" className='img-fluid mb-3'/>
                        <h5 className="my-1 mb-3">Signup</h5>
                        <p className="mb-0 font-13">Enter your details and setup an account</p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                      <img src={require('../../assets/images/algoimg/verifyicon1.png')}  alt="logo" className='img-fluid mb-3'/>
                        <h5 className="my-1 mb-3">Verify</h5>
                        <p className="mb-0 font-13">Upload your documents and get verified instantly</p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                      <img src={require('../../assets/images/algoimg/depositicon1.png')}  alt="logo" className='img-fluid mb-3'/>
                        <h5 className="my-1 mb-3">Deposit</h5>
                        <p className="mb-0 font-13">Deposit an amount as low as $50</p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                      <img src={require('../../assets/images/algoimg/tradeicon1.png')}  alt="logo" className='img-fluid mb-3'/>
                        <h5 className="my-1 mb-3">Trade</h5>
                        <p className="mb-0 font-13">Start trading any instrument of your choice and make profits</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center getsbtn mt-5">
                      <div className="openlivebtn">
                        <button type="button" className="btn btn-primary">Open Live Account</button>
                      </div>
                      <div className="opendemobtn">
                        <button type="button" className="btn btn-primary">Open Demo Account</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="getimg">
                  <img src={require('../../assets/images/algoimg/getstartimg.png')}  alt="logo" className='img-fluid'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="call-back">
          <div className="container">
            <section className="request">
              <h2>REQUEST A CALL BACK</h2>
              <p>
                <small>
                  Milliva forex broker rapidly gaining global recognition. If
                  you require any further information regarding our company and
                  products, please do not hesitate to contact us and request a
                  call back. Simply fill in the form below.
                </small>
              </p>
            </section>
            <section className="input-filed mb-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg 4 col-md-12 col-sm-4">
                    <div className="call-back-man">
                      <img
                        src={require("../../assets/images/whitelabel/bg-3.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12 col-sm-8">
                    <form>
                      {" "}
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="name@example.com"
                          {...register("name", {
                            required: {
                              value: true,
                              message: "Enter a name",
                            },
                            pattern: {
                              value: /^(?=.[a-zA-Z +\s])[a-zA-Z0-9].{1,30}$/i,
                              message: " Enter Valid  Name",
                            },
                          })}
                        />
                        <p className="text-danger">
                          {errors.name && errors.name.message}
                        </p>
                        <label htmlFor="floatingInput">Name</label>
                      </div>
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="floatingEmail"
                          placeholder="Email"
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required",
                            },
                            pattern: {
                              value: VALIDATION_PATTERN.EMAIL,
                              message: "Enter Valid Email",
                            },
                          })}
                        />
                        <div>
                          <p className="text-danger">
                            {errors.email && (
                              <span role="alert">Email is required</span>
                            )}
                          </p>
                        </div>
                        <label htmlFor="floatingPassword">Email-Id</label>
                      </div>
                      <div className="form-floating">
                        <Controller
                          control={control}
                          name="country"
                          rules={{
                            required: {
                              value: true,
                              message: "Select your country",
                            },
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Select
                                options={options}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  changeHandler(e);
                                }}
                              />
                            );
                          }}
                        />

                        <p className="text-danger">
                          {errors.country && errors.country.message}
                        </p>
                      </div>
                      <div className="form-floating">
                        <input
                          type="input"
                          className="form-control"
                          id="floatingInput"
                          placeholder="input"
                          {...register("phone", {
                            required: {
                              value: true,
                              message: "Please enter a phone number",
                            },
                          })}
                          onKeyPress={(event) => {
                            if (
                              !/[0-9 ]+$/.test(event.key) ||
                              event.key === " "
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                        <p className="text-danger">{errors.phone?.message}</p>
                        <label htmlFor="floatingPassword">Phone</label>
                      </div>
                      <div className="form-floating mb-3">
                        <textarea
                          className="form-control"
                          placeholder="Leave a comment here"
                          id="floatingTextarea"
                          defaultValue={""}
                          {...register("msg", {
                            required: {
                              value: true,
                              message: "Please enter a message",
                            },
                          })}
                        />
                        <p className="text-danger">{errors.msg?.message}</p>
                        <label htmlFor="floatingTextarea">Message</label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          className={`form-check-input mx-2  ${
                            errors.checkbox ? "border-danger" : ""
                          }`}
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckDefault"
                          {...register("checkbox", {
                            required: { value: true },
                          })}
                        />
                        <label
                          className={`form-check-label ${
                            errors.checkbox ? "text-danger" : ""
                          }`}
                          htmlFor="flexCheckDefault"
                        >
                          Email me free educational trading material to start
                          trading currencies. Valid email required
                        </label>
                      </div>
                      {loading ? (
                        <>
                          {" "}
                          <button
                            type="button"
                            className="btn btn-requestcall mb-5"
                          >
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            type="submit"
                            className="btn btn-requestcall mb-5"
                            onClick={handleSubmit(submit)}
                          >
                            SUBMIT
                          </button>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Whitelabel;
