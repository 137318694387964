import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/pipcalc.css";
import { useParams, useNavigate } from "react-router-dom";
import Lotsize from "../../Components/Pipcalc/Lotsize";
import Profitcalc from "../../Components/Pipcalc/Profitcalc";
const Pipcalc = () => {
  const forexcalcc = useParams();

  let path = window.location.pathname;

  const navigate = useNavigate();

  const currencychart = useRef();

  function RemoteCalc(WidgetConfig) {
    // if (WidgetConfig.Url == undefined) {
    //   WidgetConfig.Url = "https://www.cashbackforex.com";
    // }
    var ifrm = document.createElement("iframe");
    ifrm.setAttribute("calc-id", "ifrm-cbf-" + WidgetConfig.ContainerId); // assign an id
    ifrm.setAttribute("scrolling", "no");
    ifrm.setAttribute("widgetType", "calculator");
    ifrm.setAttribute(
      "style",
      "width:100%; border: 0;  height:100%; min-height:500px"
    );

    var frameSrc = WidgetConfig.Url;
    if (WidgetConfig.Lang != undefined) {
      if (
        WidgetConfig.Lang == "page" &&
        this.document.documentElement.lang != ""
      )
        frameSrc += "/" + this.document.documentElement.lang;
      else if (WidgetConfig.Lang != "page") frameSrc += "/" + WidgetConfig.Lang;
    }

    frameSrc += "/widgets/" + WidgetConfig.Calculator;

    if (WidgetConfig.IsDisplayTitle === true) {
      frameSrc += "?IsDisplayTitle=true";
    } else {
      frameSrc += "?IsDisplayTitle=false";
    }
    if (WidgetConfig.IsShowChartLinks !== undefined) {
      frameSrc += "&ShowChartLinks=" + WidgetConfig.IsShowChartLinks;
    }
    if (WidgetConfig.TopPaneStyle != undefined) {
      frameSrc += "&TopPaneStyle=" + WidgetConfig.TopPaneStyle;
    }
    if (WidgetConfig.BottomPaneStyle != undefined) {
      frameSrc += "&BottomPaneStyle=" + WidgetConfig.BottomPaneStyle;
    }
    if (WidgetConfig.ButtonStyle != undefined) {
      frameSrc += "&ButtonStyle=" + WidgetConfig.ButtonStyle;
    }
    if (WidgetConfig.TitleStyle != undefined) {
      frameSrc += "&TitleStyle=" + WidgetConfig.TitleStyle;
    }
    if (WidgetConfig.TextboxStyle != undefined) {
      frameSrc += "&TextboxStyle=" + WidgetConfig.TextboxStyle;
    }
    var width = "100%";
    if (WidgetConfig.ContainerWidth != undefined) {
      frameSrc += "&MaxWidth=" + WidgetConfig.ContainerWidth;
      if (
        WidgetConfig.ContainerWidth.endsWith("px") ||
        /^[0-9]*\.?[0-9]*$/.test(WidgetConfig.ContainerWidth)
      ) {
        const parsed = parseFloat(WidgetConfig.ContainerWidth, 10);
        if (isNaN(parsed)) {
          width = WidgetConfig.ContainerWidth + "px";
        } else {
          width = parsed + 1 + "px";
        }
      } else if (WidgetConfig.ContainerWidth.endsWith("%")) {
        width = WidgetConfig.ContainerWidth;
      }
    }
    if (WidgetConfig.ContainerId != undefined) {
      frameSrc += "&ContainerId=" + WidgetConfig.ContainerId;
    }
    if (
      WidgetConfig.AffiliateId != undefined &&
      WidgetConfig.AffiliateId != null
    ) {
      frameSrc += "&AffiliateId=" + WidgetConfig.AffiliateId;
    }
    if (WidgetConfig.CompactType) {
      frameSrc += "&CompactType=" + WidgetConfig.CompactType;
    }
    if (WidgetConfig.DefaultCurrencyFrom) {
      frameSrc += "&DefaultCurrencyFrom=" + WidgetConfig.DefaultCurrencyFrom;
    }
    if (WidgetConfig.DefaultCurrencyTo) {
      frameSrc += "&DefaultCurrencyTo=" + WidgetConfig.DefaultCurrencyTo;
    }
    if (WidgetConfig.HighlightColor) {
      frameSrc +=
        "&HighlightColor=" + encodeURIComponent(WidgetConfig.HighlightColor);
    }
    if (WidgetConfig.DefaultInstrument) {
      frameSrc +=
        "&DefaultInstrument=" +
        encodeURIComponent(WidgetConfig.DefaultInstrument);
    }
    if (WidgetConfig.DefaultExchange) {
      frameSrc +=
        "&DefaultExchange=" + encodeURIComponent(WidgetConfig.DefaultExchange);
    }

    if (WidgetConfig.DefaultBalance) {
      frameSrc += "&DefaultBalance=" + WidgetConfig.DefaultBalance;
    }

    if (WidgetConfig.DefaultPeriod) {
      frameSrc += "&DefaultPeriod=" + WidgetConfig.DefaultPeriod;
    }

    if (WidgetConfig.DefaultGain) {
      frameSrc += "&DefaultGain=" + WidgetConfig.DefaultGain;
    }

    if (WidgetConfig.IsShowEmbedButton !== undefined) {
      frameSrc += "&IsShowEmbedButton=" + WidgetConfig.IsShowEmbedButton;
    }
    if (WidgetConfig.IsShowBuyCryptoButton !== undefined) {
      frameSrc +=
        "&IsShowBuyCryptoButton=" + WidgetConfig.IsShowBuyCryptoButton;
    }
    ifrm.setAttribute("src", frameSrc);

    if (window.addEventListener) {
      window.addEventListener("message", onRemoteCalcMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", onRemoteCalcMessage, false);
    }

    var containerDiv = document.getElementById(WidgetConfig.ContainerId);
    containerDiv.style = "position: relative; max-width: " + width + ";";
    containerDiv.innerHTML = "";
    containerDiv.appendChild(ifrm);

    ifrm.addEventListener("load", function () {
      ifrm.contentWindow.postMessage(
        {
          method: "calc-log",
          url: window.location.href,
          calc: WidgetConfig.Calculator,
          host: window.location.host,
        },
        "*"
      );
    });
  }

  function onRemoteCalcMessage(event) {
    // Check sender origin to be trusted
    //if (RebateWidgetConfig.Url != "" && event.origin !== RebateWidgetConfig.Url) return;

    var data = event.data;

    if (typeof window[data.func] == "function") {
      window[data.func].call(null, data);
    }
  }

  window.RemoteCalc = RemoteCalc;

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://www.cashbackforex.com/Content/remote/remote-widgets.js";
    script.async = false;
    script.innerHTML = JSON.stringify(
      RemoteCalc({
        Url: "https://www.cashbackforex.com",
        TopPaneStyle:
          "YmFja2dyb3VuZDogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggYmxhY2s7IGJvcmRlci1ib3R0b206IG5vbmU7IA==",
        BottomPaneStyle:
          "YmFja2dyb3VuZDogd2hpdGU7IGJvcmRlcjogc29saWQgMXB4IGJsYWNrOyBjb2xvcjogYmxhY2s7",
        ButtonStyle:
          "YmFja2dyb3VuZDogb3JhbmdlOyBjb2xvcjogd2hpdGU7IGJvcmRlci1yYWRpdXM6IDIwcHg7",

        TitleStyle:
          "dGV4dC1hbGlnbjogbGVmdDsgZm9udC1zaXplOiA0MHB4OyBmb250LXdlaWdodDogNTAwOw==",
        TextboxStyle:
          "YmFja2dyb3VuZC1jb2xvcjogd2hpdGU7IGNvbG9yOiBibGFjazsgYm9yZGVyOiBzb2xpZCAxcHggI2FhYWFhYQ==",
        ContainerWidth: "900",
        DefaultInstrument: "EUR-USD",
        HighlightColor: "#ffff00",
        IsDisplayTitle: true,
        IsShowChartLinks: false,
        IsShowEmbedButton: false,
        CompactType: "large",
        Calculator: "pip-value-calculator",
        ContainerId: "pip-value-calculator-397910",
      })
    );
    currencychart.current.appendChild(script);
  }, []);

  const lotcalc = () => {
    navigate(`/pipcalc/lotsize`);
  };
  const pipcalcu = () => {
    navigate(`/pipcalc`);
  };
  const profitcalu = () => {
    navigate(`/pipcalc/profitcalc`);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <div>
      <div className="pipcalculator">
        <section className="pipacal">
          <div className="pipcaltrade">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                  <h1 className="px-3 mb-2">All-In-One Trader Calculator</h1>
                  <p className="px-3 mb-3">
                    Global Forex Calculator automatically calculates the
                    required margin, pip, and profit for trading and a value of
                    points for any position
                  </p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/pipcalc/pipcalc6.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* mainsec */}
        <section className="fpipcalc mt-5 pb-5">
          <div className="container-fluid">
            <div className="forexcal">
              <div className="row">
                <div className="col-md-12 col-lg-2">
                  <div className="imgad1">
                    <img
                      src={require("../../assets/images/pipcalc/adbannerpip.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-8">
                  <div className="pipcalsacc">
                    <div className="row">
                      <div className="col-md-12 col-lg-4">
                        <div className="forexcalcc">
                          <div className="card">
                            <div className="card-title px-4 my-3">
                              <h4>Forex Calculators</h4>
                            </div>
                            <div className="card-body pt-0 mt-0">
                              <div className="nav-tabs-menu mt-0">
                                <ul
                                  className="nav nav-tabs d-block"
                                  id="ProductTab"
                                  role="tablist"
                                >
                                  <li
                                    className="nav-item d-flex"
                                    role="presentation"
                                  >
                                    <button
                                      className="nav-link active"
                                      id="Pip-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#Pip"
                                      type="button"
                                      role="tab"
                                      aria-controls="Pip"
                                      aria-selected="true"
                                      onClick={pipcalcu}
                                    >
                                      Pip Calculator
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className="nav-link"
                                      id="Lotsize-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#Lotsize"
                                      type="button"
                                      role="tab"
                                      aria-controls="Lotsize"
                                      aria-selected="false"
                                      onClick={lotcalc}
                                    >
                                      Lot Size Calculator
                                    </button>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <button
                                      className="nav-link"
                                      id="Profit-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#Profit"
                                      type="button"
                                      role="tab"
                                      aria-controls="Profit"
                                      aria-selected="false"
                                      onClick={profitcalu}
                                    >
                                      Profit Calculator
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-8">
                        <div className="pipc">
                          <div className="tab-content" id="ProductTabContent">
                            <div
                              className="tab-pane fade active show"
                              id="Pip"
                              role="tabpanel"
                              aria-labelledby="Pip-tab"
                            >
                              <div
                                id="pip-value-calculator-397910"
                                ref={currencychart}
                              ></div>
                            </div>

                            <div
                              className="tab-pane fade"
                              id="Lotsize"
                              role="tabpanel"
                              aria-labelledby="Lotsize-tab"
                            >
                              {" "}
                              <h2 className="mb-3">Lot Size Calculator</h2>
                              <Lotsize />
                            </div>
                            <div
                              className="tab-pane fade"
                              id="Profit"
                              role="tabpanel"
                              aria-labelledby="Profit-tab"
                            >
                              {" "}
                              <Profitcalc />
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="pips">
                        {" "}
                        For all transactions, these are available at your
                        disposal. You can use this helpful calculator to
                        determine the volume for all instruments and the long
                        and short swaps, margin, and benefits points that are
                        all part of your trading positions.
                      </p>
                    </div>
                    {path === "/pipcalc" ? (
                      <>
                        <div className="row align-items-center">
                          <div className="col-md-12 col-lg-4">
                            {" "}
                            <img
                              src={require("../../assets/images/pipcalc/profit.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="col-md-12 col-lg-8">
                            <p>
                              A pip, or "pipette," is the slightest possible
                              price movement for a currency pair in the forex
                              world.
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {forexcalcc.lotsize === "lotsize" ? (
                      <>
                        <div className="row align-items-center">
                          <div className="col-md-12 col-lg-4">
                            <img
                              src={require("../../assets/images/pipcalc/lot.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="col-md-12 col-lg-8">
                            <p className="">
                              A "Lot" in forex designates the trade size or the
                              quantity of currency units purchased or sold in a
                              transaction. One hundred thousand units of the
                              base currency make up a standard lot. Most brokers
                              also provide trading with fractional lot sizes,
                              often as standard lot 1.00, Mini lots (0.10),
                              micro lots (0.01), and lots are the three
                              categories for fractional lot sizes (0.001).
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {forexcalcc.lotsize === "profitcalc" ? (
                      <>
                        <div className="row align-items-center">
                          <div className="col-md-12 col-lg-4">
                          <img
                              src={require("../../assets/images/pipcalc/profitcalc.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="col-md-12 col-lg-8">
                            <p className="">
                              A forex profit calculator helps simulate the
                              amount of money and pip the position will
                              represent. It operates by emulating a trading
                              position that is opened and closed at
                              predetermined prices; the possible profit or loss
                              outcomes are shown in dollars and Point in
                              Percentage (pips). Calculation of a Pip Value for
                              CFD’s: The pip value calculator uses real-time
                              market quotations, the account base currency, the
                              lot size, and the traded pair to precisely
                              determine the pip value for currency pairings,
                              indices, digital currencies, and more.
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-md-12 col-lg-2">
                  <div className="imgad2">
                    <img
                      src={require("../../assets/images/pipcalc/adbannerpip2.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Pipcalc;
