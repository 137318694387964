import React, { useEffect } from "react";
import "../../assets/css/pamm.css";
const Pamm = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <div>
      <section className="Pamm-trade">
        <section id="Pamm">
          <section className="Pamm-banner">
            <div className="Pamm-trade-banner">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                    <h1 className=" mb-3">
                      Percentage Allocation Money Management (PAMM)
                    </h1>
                    <p className="px-0">PAMM Account For Additional Income</p>
                    <p className="px-0">
                      Get Additional Income With PAMM Accounts
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/pamm/pamm2.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        {/* ============================what is pamm============================= */}
        <div className="what-is-pamm mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="pamm-text">
                  <h2 className="mb-3">What is a PAMM account?</h2>
                  <p>
                    Percentage Allocation Money Management is shortly referred
                    to as PAMM. This kind of trading setup lets users allocate
                    funds to a qualified trader or money manager they pick, who
                    in turn controls these pooled funds across various trading
                    accounts. By using Milliva’s PAMM solutions you will be
                    traded in the best platform for trading and which are ideal
                    for money managers searching to manage funds across numerous
                    client accounts using one single Master Account and
                    interface.
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-12 col-sm-12">
        <img src={require('../../assets/images/pamm/what-is-pamm.png')}  alt="logo" className='img-fluid'/>
        </div> */}
            </div>
          </div>
        </div>
        {/* ====================================how does============================ */}
        <div className="how-does mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img
                  src={require("../../assets/images/pamm/how-workpamm.png")}
                  alt="logo"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h2 className="mb-3">How does a PAMM account work?</h2>
                <p>
                  When investors trade independently, they frequently face
                  difficulties since their capital might need more for a
                  particular trading strategy. With a PAMM account, the
                  Investment Manager will pool funds from different investors
                  based on some trading strategy to overcome this. A best
                  brokerage Milliva supports fund managers in aggregating
                  investments from various clients into a particular trading
                  account.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* =============================why choose============================== */}
        <div className="why-pamm mt-5">
          <div className="container">
            <h2 className="text-center">Why choose a PAMM account?</h2>
            <div className="row mt-4">
              <div className="col-lg-3 col-md-12 col-sm-12 mb-3">
                <div className="why-choose card">
                  <div className="d-flex ">
                    <div className="Pamm-image mt-2">
                      <img
                        src={require("../../assets/images/pamm/one-pamm.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="pamm-text">
                      <h5>Return</h5>
                      <p>
                        <small>
                          Income has no limitations. Profits depend on the PAMM
                          trader’s efficiency
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 mb-3">
                <div className="why-choose card">
                  <div className=" d-flex">
                    <div className="Pamm-image mt-2">
                      <img
                        src={require("../../assets/images/pamm/two-pamm.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="pamm-text">
                      <h5>Security</h5>
                      <p>
                        <small>
                          The PAMM trader can conduct transactions but cannot
                          withdraw your funds
                        </small>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 mb-3">
                <div className="why-choose card">
                  <div className="d-flex">
                    <div className="Pamm-image mt-2">
                      <img
                        src={require("../../assets/images/pamm/three-pamm.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="pamm-text">
                      <h5>Flexibility</h5>
                      <p>
                        <small>
                          You can withdraw profit/funds or make an additional
                          investment at any time
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 mb-3">
                <div className="why-choose card">
                  <div className="d-flex">
                    <div className="Pamm-image mt-2">
                      <img
                        src={require("../../assets/images/pamm/four-pamm.png")}
                        alt="logo"
                        className="img-fluid"
                      />
                    </div>
                    <div className="pamm-text">
                      <h5>Transparency</h5>
                      <p>
                        <small>
                          You can monitor the trading history of the PAMM trader
                          in your Client Area
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============================reasons============================== */}
        <div className="reasons">
          <div className="container">
            <h2 className="text-center mt-5">
              {" "}
              Reasons to Start Using PAMM account
            </h2>
            <div className="row mt-4">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="reasons card d-flex">
                  <div className="reasons-image">
                    <img
                      src={require("../../assets/images/pamm/pamm9-1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="resons-text">
                    <p>
                      <small>Exact entry and exit prices </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="reasons card d-flex">
                  <div className="reasons-image">
                    <img
                      src={require("../../assets/images/pamm/pamm9-2.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="resons-text">
                    <p>
                      <small>Perfect in performance fee and commission</small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 col-sm-12">
                <div className="reasons card d-flex">
                  <div className="reasons-image">
                    <img
                      src={require("../../assets/images/pamm/pamm9-3.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="resons-text">
                    <p>
                      <small>Motorised onboarding process</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-4 col-sm-12">
                <div className="reasons card d-flex">
                  <div className="reasons-image">
                    <img
                      src={require("../../assets/images/pamm/pamm9-4.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="resons-text">
                    <p>
                      <small>Numerous investor admission into funds </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 col-sm-12">
                <div className="reasons card d-flex">
                  <div className="reasons-image">
                    <img
                      src={require("../../assets/images/pamm/pamm9-5.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="resons-text">
                    <p>
                      <small>No issues in investor accounts </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="reasons card d-flex">
                  <div className="reasons-image">
                    <img
                      src={require("../../assets/images/pamm/pamm9-6.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="resons-text">
                    <p>
                      <small>
                        Automated payment with large pool accounting{" "}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-4 col-sm-12">
                <div className="reasons card">
                  <div className="reasons-image">
                    <img
                      src={require("../../assets/images/pamm/pamm9-7.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="resons-text">
                    <p>
                      <small>Access to web-based interface </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 col-sm-12">
                <div className="reasons card">
                  <div className="reasons-image">
                    <img
                      src={require("../../assets/images/pamm/pamm9-8.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="resons-text">
                    <p>
                      <small>Account management</small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 col-sm-12">
                <div className="reasons card">
                  <div className="reasons-image">
                    <img
                      src={require("../../assets/images/pamm/pamm9-9.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="resons-text">
                    <p>
                      <small>
                        Power over trading decision for fund manager{" "}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =====================================join pamm===================================== */}
        <div className="join-pamm mt-5">
          <div className="container">
            <h2 className="text-center">Join our PAMM program</h2>
            <p className="text-center">
              PAMM provides more flexibility and attracts more traders. Milliva
              supports money managers. Earn more with us.
            </p>
            <div className="row mt-4">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img
                  src={require("../../assets/images/introbroker/ibsignup.png")}
                  alt="logo"
                  className="img-fluid"
                />
                <div className="join-our-pamm">
                  <h5>Sign Up</h5>
                  <p>
                    Join hands with one of the best trading broker and choose
                    any of our flexible programs
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img
                  src={require("../../assets/images/introbroker/referib.png")}
                  alt="logo"
                  className="img-fluid"
                />
                <div className="join-our-pamm">
                  <h5>Refer Clients</h5>
                  <p>
                    Cite with new clients to Milliva and develop your referral
                    or affiliate business
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <img
                  src={require("../../assets/images/introbroker/earnmoreib.png")}
                  alt="logo"
                  className="img-fluid"
                />
                <div className="join-our-pamm">
                  <h5>Earn More</h5>
                  <p>
                    Make profits above-industry level Certified Public Account
                    and generate revenue from every referred account and the
                    trades that takes place
                  </p>
                </div>
              </div>
            </div>
            <a href="https://crm.milliva.com/client/register/6169896c79fd7">
              <button type="button" className="btn getmeta5 ">
                JOIN NOW
              </button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Pamm;
