import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Form, Button } from "react-bootstrap";
import "../../assets/css/legaldoc.css";
import { VALIDATION_PATTERN } from "../Signin/Store";
import { Subscribe } from "../Helper/Api";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const Refundpolicy = () => {




    useEffect(() => {
        setTimeout(() => {
            window.scroll({
                top: 50,

                behavior: "smooth",
            });
        }, 100);
    }, []);
    useEffect(() => {
        setTimeout(() => {
            window.scroll({
                top: 0,

                behavior: "smooth",
            });
        }, 100);
    }, []);
    return (
        <div>
            <NotificationContainer />


            <div className="document">
                <section className="legaldocument">
                    <div className="documentbanner">
                        <div className="container">
                            <div className="row align-items-center pt-5">
                                <div className="col-md-12 col-lg-6">
                                    <h1 className=" mt-5">Return and Refund Policy</h1>
                                    <p className=" mb-3">Trading drives Everything We Do.</p>
                                </div>
                                <div className="col-md-12 col-lg-6 ">
                                    <img
                                        src={require("../../assets/images/legal/document.png")}
                                        alt="logo"
                                        className="img-fluid mt-5"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="legal">
                    <div className="container">
                        <div className="row">

                            <div className="services">
                                <p className="inves parah"><br></br>
                                    <h5>Milliva Refund Policy</h5>
                                </p>
                                <ul className="invesment">
                                    <li>Refunds are applicable within 30 days from the initial payment or purchase of
                                        products or services from Milliva.</li>
                                    <li>
                                        To qualify for a refund, clients must demonstrate that the integration has not been
                                        successfully implemented or is not functioning as expected.
                                    </li>
                                    <li>Refunds for static, dynamic website development and app development may be
                                        granted if the website does not meet the agreed-upon specifications outlined in the
                                        project scope.</li>
                                    <li>Refunds for Digital Marketing services will be approved if the clients prove that the
                                        campaign objectives must be qualified for a refund.</li>
                                    <li>Our management team will review the refund request to assess eligibility.</li>
                                    <li>Approved refunds will be processed within 14 business days via the original payment
                                        method.</li>
                                    <li>Any services fully or partially delivered at the time of the refund request are non-
                                        refundable..</li>
                                    <li> Depending on the stage of the project, cancellation fees may apply to cover incurred
                                        expenses and work completed up to the cancellation date.</li>
                                    <li>Certain expenses, such as third-party licensing fees or domain registration costs, are
                                        non-refundable.</li>
                                    <li> The refund policy is subject to periodic review and updates at Milliva&#39;s discretion.</li>
                                    <li>Any changes to the refund policy will be communicated to clients through our website
                                        or other official channels.</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </section>

            </div>
        </div>
    );
};

export default Refundpolicy;
