import React, { useEffect } from "react";
import "../../assets/css/mt5.css";
import { FcCandleSticks } from "react-icons/fc";
import { Link } from "react-router-dom";
const Mt5trade = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <div>
      <div className="mt5trader">
        <section className="mt5trade">
          <div className="mt5cont">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 ">
                  <h1 className=" mb-3">MetaTrader 5</h1>
                  <p className=" mb-3">
                    A multi-asset trading platform like MetaTrader 5 allows you
                    to trade forex, stocks, futures, and other instruments in
                    the best broker for forex in the world
                  </p>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/mt5/mt5bbimg.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Hero */}
        <section className="desk">
          <div className="mt5desk mt-5">
            <div className="container">
              <h1 className="text-center">Meta Trader5</h1>
              <div className="row  align-items-center mt-5">
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/mt5/metatrade5img1.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-12 col-lg-6">
                  <h2 className="mb-3">Windows </h2>
                  <p className="mb-3">
                    MetaTrader 5 is the latest addition to the MetaQuotes
                    trading platform range and a multi-asset platform that
                    provides users in all over the world with an exceptional
                    Forex trading experience. The Milliva Ltd MT5 platform for
                    Windows is an all-in-one platform that allows trading Forex,
                    Shares, Indices, Metals, and Commodities.
                  </p>
                  <a
                    className="dropdown-item"
                    href="https://download.mql5.com/cdn/web/19448/mt5/milliva5setup.exe"
                    download
                  >
                    <button type="button" className="btn deskdownload">
                      Download
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mobile">
          <div className="mt5mobileapp mt-5">
            <div className="container">
              <div className="row  align-items-center">
                <div className="col-md-12 col-lg-6">
                  <h2 className="mb-3"> Android</h2>
                  <p className="mb-3">
                    MetaTrader 5 is the latest addition to the MetaQuotes
                    trading platform range and a multi-asset platform that
                    provides users in all over the world with an exceptional
                    Forex trading experience. The Milliva Ltd MT5 platform for
                    Windows is an all-in-one platform that allows trading Forex,
                    Shares, Indices, Metals, and Commodities.
                  </p>
                  <a
                    href="https://download.mql5.com/cdn/mobile/mt5/android?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes"
                    className="dropdown-item"
                    // onClick={handleCaptureClick}
                  >
                    <button type="button" className="btn deskdownload">
                      Download
                    </button>
                  </a>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="">
                    <img
                      src={require("../../assets/images/mt5/metatrade5android.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ios">
          <div className="mt5ios mt-2">
            <div className="container">
              <div className="row  align-items-center">
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/mt5/mt5mac.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="col-md-12 col-lg-6">
                  <h2 className="mb-3"> IOS </h2>
                  <p className="mb-3">
                    MetaTrader 5 is the latest addition to the MetaQuotes
                    trading platform range and a multi-asset platform that
                    provides users in all over the world with an exceptional
                    Forex trading experience. The Milliva Ltd MT5 platform for
                    Windows is an all-in-one platform that allows trading Forex,
                    Shares, Indices, Metals, and Commodities.
                  </p>
                  <a
                    className="dropdown-item"
                    href="https://download.mql5.com/cdn/mobile/mt5/ios?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes
                            "
                  >
                    <button type="button" className="btn deskdownload">
                      Download
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mac">
          <div className="mt5mac mt-2">
            <div className="container">
              <div className="row  align-items-center">
                <div className="col-md-12 col-lg-6">
                  <h2 className="mb-3"> MAC</h2>
                  <p className="mb-3">
                    MetaTrader 5 is the latest addition to the MetaQuotes
                    trading platform range and a multi-asset platform that
                    provides users in all over the world with an exceptional
                    Forex trading experience. The Milliva Ltd MT5 platform for
                    Windows is an all-in-one platform that allows trading Forex,
                    Shares, Indices, Metals, and Commodities.
                  </p>
                  <a
                    className="dropdown-item"
                    href="https://download.mql5.com/cdn/mobile/mt5/ios?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes
                            "
                  >
                    <button type="button" className="btn deskdownload">
                      Download
                    </button>
                  </a>
                </div>
                <div className="col-md-12 col-lg-6">
                  <img
                    src={require("../../assets/images/mt5/metatradeios.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="feature">
          <div className="mt5fea mt-5 pb-5">
            <div className="container">
              <h2 className="text-center">Features of Meta Trader5</h2>
              <div className="row felistitem mt-4">
                <div className="col-md-12 col-lg-4">
                  <ul className="fealist">
                    <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      9 time frames
                    </li>
                    <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      A great variety of tools for technical analysis
                    </li>
                    <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      Online financial news
                    </li>
                  </ul>
                </div>
                <div className="col-md-12 col-lg-4">
                  <ul className="fealist">
                    <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      Automated trading
                    </li>
                    {/* <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      Locked positions
                    </li>  */}
                    <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      Trailing stop
                    </li>
                    <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      A wide choice of free indicators and advisors
                    </li>
                  </ul>
                </div>
                <div className="col-md-12 col-lg-4">
                  <ul className="fealist">
                    <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      A possibility to create your own indicators and advisors
                      (MQL5)
                    </li>
                    <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      High security ensured by 128-bit encoding
                    </li>
                    <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      One click trading
                    </li>
                    {/* <li>
                      <i className=" candlestick mx-2">
                        <FcCandleSticks />
                      </i>
                      Trailing stop
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12 col-lg-3">
                  <div className="service-box service-fold">
                    <img
                      src={require("../../assets/images/mt5/mt5multitask.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <h5>MultiTasking</h5>
                    <p>
                      MT5 allows a trader to send 2 market orders and 4 pending
                      orders. It features 2 execution modes, 2 stop orders and a
                      trailing stop
                    </p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="service-box service-fold">
                    <img
                      src={require("../../assets/images/mt5/trademt5.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <h5>Toolkit</h5>
                    <p>
                      MT5 has an extensive toolkit for charting. You can edit
                      colors and styles, load preset templates, as well as
                      delete unnecessary features. With all these instruments
                      you will easily read charts
                    </p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="service-box service-fold">
                    <img
                      src={require("../../assets/images/mt5/mt5language.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <h5>Multilingualism</h5>
                    <p>
                      Traders from all over the world can use the MT5 platform
                      in their native language
                    </p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="service-box service-fold">
                    <img
                      src={require("../../assets/images/mt5/mt5trademethod.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <h5>Different trading methods</h5>
                    <p>
                      The platform supports different types of trading methods
                      such as hedging and LIFO
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="getmt5 mt-4 pb-5">
          <div className="getsmt5">
            <div className="container">
              <h2 className="text-center mb-3">Get Started</h2>
              <p className="getapara">
                Get start trade anywhere, anytime. We have everything you need:
                friendly customer support, the latest technology, and multiple
                account types to find the best trades.
              </p>
              <div className="row mt-5 align-items-center">
                <div className="col-md-12 col-lg-9">
                  <div className="row">
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/signupicon2.png")}
                          alt="logo"
                          className="img-fluid mb-3"
                        />
                        <h5 className="my-1 mb-3">Signup</h5>
                        <p className="mb-0 font-13">
                          Enter your details and setup an account
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/verifyicon2.png")}
                          alt="logo"
                          className="img-fluid  mb-3"
                        />
                        <h5 className="my-1 mb-3">Verify</h5>
                        <p className="mb-0 font-13">
                          Upload your documents and get verified instantly
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/depositicon1.png")}
                          alt="logo"
                          className="img-fluid  mb-3"
                        />
                        <h5 className="my-1 mb-3">Deposit</h5>
                        <p className="mb-0 font-13">Deposit Minimum as $100</p>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3">
                      <div className="box1get">
                        <img
                          src={require("../../assets/images/tradeicon1.png")}
                          alt="logo"
                          className="img-fluid  mb-3"
                        />
                        <h5 className="my-1 mb-3">Trade</h5>
                        <p className="mb-0 font-13">
                          Start trading any instrument of your choice and make
                          profits
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center getsbtn mt-5">
                      <div className="openlivebtn">
                        <Link to="https://crm.milliva.com/client/register/6169896c79fd7">
                          <button type="button" className="btn btn-primary">
                            Open Live Account
                          </button>
                        </Link>
                      </div>
                      <div className="opendemobtn">
                        <Link to="https://crm.milliva.com/client/register/6169896c79fd7">
                          <button type="button" className="btn btn-primary">
                            Open Demo Account
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-3">
                  <div className="getimg">
                    <img
                      src={require("../../assets/images/getstartimg.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Mt5trade;
