import React, { useEffect } from "react";
import "../../assets/css/mam.css";

const Mam = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll({
        top: 0,

        behavior: "smooth",
      });
    }, 100);
  }, []);
  return (
    <div>
      <>
        {/* Hello world */}
        <section className="Mam-trade">
          <section id="Mam">
            <section className="Mam-banner">
              <div className="Mam-trade-banner">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                      <h1 className=" mb-3">Multi-Account Manager (MAM)</h1>
                      <p className="px-0">Profit more with your MAM Account</p>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <img
                        src={require("../../assets/images/mam/mam-banner.png")}
                        alt="logo"
                        className="img-fluid mambb"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
          {/* ==============================mam-account======================= */}
          <div className="mam-account mt-3">
            <div className="container">
              <h2 className="text-center mb-3">What is a MAM account?</h2>
              <p>
                Multi-Account Manager is also known as MAM. By using the best
                brokerage account Milliva’s MAM account lets a fund manager to
                trade currency, shares, commodities and more by employing
                Contract for Differences on behalf of their customers. MAM are
                rewarded from the commissions on their customer’s trades,
                management fee and performance fee. With this design, customers
                may profit from having their accounts controlled by
                Milliva-authorised traders.
              </p>
            </div>
          </div>
          {/* ===========================mam-account work========================= */}
          <div className="account-work mt-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <img
                    src={require("../../assets/images/mam/mammanager8.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="mam-account-work">
                    <h2 className="mb-3">How does MAM account work?</h2>
                    <p>
                      MAM account permits prorated profit-sharing and MAM
                      accounts tend to be sophisticated in fund allotment for
                      different trading strategies. This MAM account is ideal
                      for seasoned traders who choose to actively take part in
                      every investment decision and fund allotment for different
                      trading strategies The best online trading platform
                      Milliva provides better infrastructure for money managers
                      who understand the importance of a partnership with an
                      established award-winning broker. The fame of the Milliva
                      MAM platform is an arrangement to its stellar reputation
                      among money managers across the world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ===========================why choose a Mam================================= */}
          <div className="why-choose-mam mt-5">
            <div className="container">
              <div className="why-Mam">
                <h2 className="text-center mb-3">
                  Choose MAM For a Better Change
                </h2>
                <div className="row mt-5">
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="why-choose card">
                      <div className="d-flex ">
                        <div className="mam-image mt-2">
                          <img
                            src={require("../../assets/images/mam/one-pamm.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="mam-text">
                          <h5>Return</h5>
                          <p>MAM account is one of the most famous ones</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="why-choose card">
                      <div className=" d-flex ">
                        <div className="mam-image mt-2">
                          <img
                            src={require("../../assets/images/mam/two-pamm.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="mam-text">
                          <h5>Security</h5>
                          <p>
                            Brokers offer MAM as a part of integrated service
                            with their MT5 platforms
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="why-choose card">
                      <div className="d-flex ">
                        <div className="mam-image mt-2">
                          <img
                            src={require("../../assets/images/mam/three-pamm.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="mam-text">
                          <h5>Flexibility</h5>
                          <p>
                            MAM accounts are secured as long as you open a
                            trading account with a safe and regulated broker.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12">
                    <div className="why-choose card">
                      <div className="d-flex ">
                        <div className="mam-image mt-2">
                          <img
                            src={require("../../assets/images/mam/four-pamm.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="mam-text">
                          <h5>Transparency</h5>
                          <p className="transp">
                            Thus, you should be careful and While the best
                            brokerage firm Milliva is a legitimate trading
                            platform where you can invest without fear and can
                            yield an expected revenue.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* =========================================8 reasons========================== */}
          <div className="mam-resons mt-5">
            <div className="container">
              <h2 className="text-center pt-4">
                8 Reasons to Start Using MAM Account
              </h2>
              <div className="row mt-4">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="mam-reason d-flex ">
                    <img
                      src={require("../../assets/images/mam/mam8-1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>
                      {" "}
                      The low trade volume is employed to ensure optimal trade
                      allotment
                    </p>
                  </div>

                  <div className="mam-reason d-flex">
                    <img
                      src={require("../../assets/images/mam/mam8-2.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>
                      Equity is allocated through a standardised allocation
                      method{" "}
                    </p>
                  </div>
                  <div className="mam-reason d-flex">
                    <img
                      src={require("../../assets/images/mam/mam8-3.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>
                      Experienced accounts possess access to Straight-through
                      processing and rapid allocation
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="mam-reason d-flex">
                    <img
                      src={require("../../assets/images/mam/mam8-5.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>
                      {" "}
                      Trading styles like scalping and hedging also allowed
                    </p>
                  </div>
                  <div className="mam-reason d-flex">
                    <img
                      src={require("../../assets/images/mam/mam8-6.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p> Customizable rewards, fee and commissions</p>
                  </div>
                  <div className="mam-reason d-flex">
                    <img
                      src={require("../../assets/images/mam/mam8-7.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>
                      {" "}
                      Real-time order management, even instant addition and
                      removal of funds
                    </p>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-12 col-sm-12">
                  <div className="mam-reason text-center d-flex">
                    <img
                      src={require("../../assets/images/mam/mam8-4.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    <p>
                      {" "}
                      Master accounts possess partial order close, pending order
                      functionality, full Stop Loss and Take Profit
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ======================================milliva mam=================================== */}
          <div className="milliva-mam mt-5">
            <div className="container">
              <div className="Milliva-Mam">
                <h2 className="text-center mb-3">Using Milliva MAM Account</h2>
                <p>
                  By using a Milliva MAM account you can employ your master
                  accounts to place block orders, make orders for numerous
                  clients, can develop several sub-groups, access all MT5 order
                  types, use several types of allocations and plan your trading
                  strategy based on your clientele. Here your investments are
                  completely managed by professional traders with successful
                  trading history. Here your investments are completely managed
                  by professional traders with successful trading history.
                  Simply, Milliva is the best broker for long-term investing.
                </p>
              </div>
            </div>
          </div>
          {/* =====================================join mam===================================== */}
          <div className="join-mam">
            <div className="container">
              <h2 className="text-center">Join our MAM program</h2>
              <p className="text-center">MAM provides more flexibility and attracts more traders. Milliva supports money managers. Earn more with us.</p>
              <div className="row mt-4">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <img
                    src={require("../../assets/images/introbroker/ibsignup.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <div className="join-our-mam">
                    <h5>Sign Up</h5>
                    <p>
                      Supports you to manage numerous clients from a single
                      platform, and also you can trade seamlessly with MT5.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <img
                    src={require("../../assets/images/introbroker/referib.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <div className="join-our-mam">
                    <h5>Refer Clients</h5>
                    <p>
                      We allow our clients to control their trade progress in
                      real-time, letting you to control all the slave accounts
                      from a single master account
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <img
                    src={require("../../assets/images/introbroker/earnmoreib.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <div className="join-our-mam">
                    <h5>Earn More</h5>
                    <p>
                      Profits and losses are divided among investors, based on
                      their shares in the money manager’s account.
                    </p>
                  </div>
                </div>
              </div>
              <a href="https://crm.milliva.com/client/register/6169896c79fd7">
                <button type="button" className="btn getmeta5 ">
                  JOIN NOW
                </button>
              </a>
            </div>
          </div>
        </section>
      </>
    </div>
  );
};

export default Mam;
