import React,{useEffect} from "react";
import "../../assets/css/copytrade.css";
const Copytrade = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
            top: 0,
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <section className="Copy-trade">
        <section id="copy">
          <section className="copy-banner">
            <div className="copy-trade-banner">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                    <h1 className="mb-3">
                      Trade on World’s leading Copy Trading Platform
                    </h1>
                    <p className="px-0">
                      Copytrade and Grow your revenue on the Milliva Platform. It’s Your World. Trade It
                    </p>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <img
                      src={require("../../assets/images/copytrade/copy-trade-banner.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                    {/* <div className="banner-buttons pb-3">
                      <button type="button" className="btn getmeta5 ">
                        REGISTER AS FOLLOWER
                      </button>
                      <button type="button" className="btn getmeta5 ">
                        REGISTER AS MASTER TRADER
                      </button>
                      <button type="button" className="btn getmeta5 ">
                        STRATEGY MANAGER RANKING
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        {/* =============================banner-next============================= */}
        <div className="Trade-market mt-5">
          <div className="container text-center">
            <h2 className="mb-3">Trade the global currency market your way</h2>
            <p>
              The concept of copy trading is to employ technology to copy the
              real-time currency trades of other live investors you wish to
              follow.
            </p>
            <p>
             
                By trading every time on this method, you can copy their trades
                into your trade account.Thus, Magnificent results can be yielded
                easily by experience and knowledge of the community of
                professional traders at minimum risk. Simply a copy trading
                service allows trading procedures to be copied automatically in
                real-time from one Master trading account to the next, which is
                available from the biggest stock broker Milliva website.
             
            </p>
          </div>
        </div>
        {/* ==============================how can start========================== */}
        <div className="How-start mt-5">
          <div className="container">
            <h2 className="text-center mb-3">How can I Start?</h2>
            <div className="row mt-5 pb-5">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="service-box service-fold mt-2">
                  <img
                    src={require("../../assets/images/copytrade/copy-live.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h5>Open a Live Account &amp; Deposit</h5>
                  <p>
                    First, sign up and open a live account if you wish to trade
                    with the best online trading platform like Milliva; later
                    make a deposit using different payment methods.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="service-box service-fold mt-2">
                  <img
                    src={require("../../assets/images/copytrade/copy-analysis.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h5>Analyse &amp; Follow</h5>
                  <p>
                    {" "}
                    Several local payment techniques are also available and your
                    live account will be ready to be traded anywhere and any
                    time. You will address countless traders in the list with
                    their performance analytics and risk appetite over the
                    period of time.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="service-box service-fold mt-2 ">
                  <img
                    src={require("../../assets/images/copytrade/copy-risk.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h5>Manage your Risk</h5>
                  <p>
                    You can select wisely according to your personal risk
                    appetite and follow single or various professional traders.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =========================start-copy============================= */}
        <div className="start-copy mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <h2 className="mb-3">Start Copying Professional Traders!</h2>
                <p>
                  Do search available trading strategies and performances of our
                  best brokerage providers which Milliva possess.
                </p>
                <p>
                  Select your favourite traders and annex them to your trading
                  portfolio.{" "}
                </p>
                <p>
                  {" "}
                  You still have absolute control over the process at any given
                  moment and can alter/pause copying trades.
                </p>
                <p>
                  {" "}
                  You can also check detailed trading statistics for copied
                  Master Traders in your copier area.{" "}
                </p>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="Professional-image">
                  <img
                    src={require("../../assets/images/copytrade/copytrading1.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============================why-join========================== */}
        <div className="why-join mt-5">
          <div className="container">
            <h2 className="text-center mb-3">Why join Copy Trading?</h2>
            <p className="text-center">
              Copy trading is like any investment in the sense that you will
              either lose or make money. Your success rate is determined by
              which traders you stick to and the timing of when you copy their
              trades. Both these factors will affect any resulting losses or
              profits. Copy trading can support you to diversify your existing
              portfolio when appropriately used. The best copy-trade and best
              international trading platform like Milliva will provide tools to
              help you maintain your account.{" "}
            </p>
            <div className="row mt-5">
              <div className="col-lg-4 col-md-12 col-sm-12 mt-4">
                <div className="service-box service-fold">
                  <img
                    src={require("../../assets/images/copytrade/copy-simple.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h5>Simple &amp; Easy</h5>
                  <p>
                    Milliva copy trading is a simple method to make profits in
                    the financial markets. It increases trading opportunities
                    whether you are an expert or have yet to experience secure
                    profitable trading. Just invest in copy trading and allow
                    the professionals to do their job.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 mt-4">
                <div className="service-box service-fold">
                  <img
                    src={require("../../assets/images/copytrade/copy-profes.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h5>
                    Multiple Choice of leading<br></br> Professional Traders
                  </h5>
                  <p>
                    {" "}
                    Milliva copy trading connects trades across the world into a
                    single investment network. With the help of the knowledge
                    and experience of the community of market experts, you can
                    attain the results you could only dream about through the
                    biggest trading broker Milliva.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 mt-4">
                <div className="service-box service-fold">
                  <img
                    src={require("../../assets/images/copytrade/copy-drisk.png")}
                    alt="logo"
                    className="img-fluid"
                  />
                  <h5>Diversified Risk Management </h5>
                  <p>
                    Diversify and manage your risk by copying different
                    strategies from experienced traders, setting defined risk
                    management parameters, and copying functionalities to
                    control your risk with each master trader. Learning how
                    social copy trading networks calculate trading performance
                    is an important aspect of successful copy trading, the
                    fastest trading platform Milliva offers it efficiently for
                    you.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============================Key features=============================== */}
        <div className="Key-features mt-5 pb-5">
          <div className="container">
            <h2 className="text-center mb-3">Key Features</h2>
            <div className="row mt-4">
              <div className="col-lg-3 col-md-12 col-sm-12 mt-4">
                <div className="service-box service-fold">
                  <div className="key-img">
                    <img
                      src={require("../../assets/images/copytrade/copy-strategies.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="key-text">
                    <h5> Copy endless strategies </h5>
                    <p>
                      <small>
                        No monthly subscription or assets management fee in the
                        best trading platform Milliva. Only a performance fee is
                        charged on % of the profits earned. Each provider has a
                        different % fee; you can find that under the ‘fee’
                        section of the trader’s profile.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 mt-4">
                <div className="service-box service-fold">
                  <div className="key-img">
                    <img
                      src={require("../../assets/images/copytrade/copy-withdraw.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="key-text">
                    <h5>Withdraw ANYTIME</h5>
                    <p>
                      <small>
                        There are no long-term obligations. Connect or
                        disconnect from trading strategies at any time on your
                        decision. Limit investing and withdraw all your funds at
                        any moment.{" "}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 mt-4">
                <div className="service-box service-fold">
                  <div className="key-img">
                    <img
                      src={require("../../assets/images/copytrade/copy-unlimited.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="key-text">
                    <h5>Copy UNLIMITED Strategies</h5>
                    <p>
                      <small>
                        The chance to copy an endless number of trading
                        strategies and trade manually at the same brokerage
                        account.{" "}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 mt-4">
                <div className="service-box service-fold">
                  <div className="key-img">
                    <img
                      src={require("../../assets/images/copytrade/copy-usd.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="key-text">
                    <h5>Starting 10 USD</h5>
                    <p>
                      <small>
                        {" "}
                        You need at least $100 for the account connection.
                        Whereas the investment sum depends on the trading
                        strategies, you need to link to. Every trading strategy
                        provides its recommended minimum.{" "}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =========================get-started============================= */}
        <div className="join-copy-trade mt-5">
          <div className="container">
            <h2 className="text-center">Join our Copy program</h2>
            <p className="text-center">Using copy trading correctly is an effective way to diversify your existing portfolio.</p>
            <div className="row mt-5">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <img
                  src={require("../../assets/images/mam/sign-up.png")}
                  alt="logo"
                  className="img-fluid"
                />
                <div className="join-our-copy">
                  <h5>Sign in</h5>
                  <p>Enter your details and setup an account</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <img
                  src={require("../../assets/images/copytrade/depositicon1.png")}
                  alt="logo"
                  className="img-fluid"
                />
                <div className="join-our-copy">
                  <h5>Deposit</h5>
                  <p>Deposit minimum of $500</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <img
                  src={require("../../assets/images/copytrade/copytrade.png")}
                  alt="logo"
                  className="img-fluid"
                />
                <div className="join-our-copy">
                  <h5>Copytrade</h5>
                  <p>Connect your copy Manager</p>
                </div>
              </div>
            </div>
            <a href="https://crm.milliva.com/client/register/6169896c79fd7">
              <button type="button" className="btn getmeta5 mt-3">
                JOIN NOW
              </button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Copytrade;
