import React,{useEffect} from "react";
import "../../assets/css/whychoose.css";
const Whychoose = () => {
  useEffect(()=>{

    setTimeout(()=>{
        window.scroll({
            top:0 ,
           
            behavior: 'smooth'
        });
    },100)
  },[])
  return (
    <div>
      <div className="why choose us">
        <section className="choose us">
          <div className="choosebanner">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6 d-flex flex-column justify-content-center">
                  <h1 className=" mb-2">Reasons to Count On Us</h1>
                  <p className=" mb-3">Your Financial Advisor For Success Stories</p>
                  
                </div>
                <div className="col-md-12 col-lg-6 why-banner-img">
                  <img
                    src={require("../../assets/images/whychoose/why-choose-us.png")}
                    alt="logo"
                    className="img-fluid mt-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="choose mt-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="choose-text text-center">
                <h2>Why Choose Us</h2>
                <p>
                  Milliva has been in the currency trading industry since 2009
                  and has established itself in the top place.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="Benefits">
          <div className="container">
            <div className="Benefits text-center mt-4">
              <div className="fadeInUp" style={{ animationDelay: "2s" }}>
                <h2>Benefits</h2>
              </div>
            </div>
            <div className="pamm mt-4">
              <div className="container">
                <div className="fadeInUp" style={{ animationDelay: "2s" }}>
                  <div className="row">
                    <div className="col-lg-4 ">
                      <div className="pamm d-flex">
                        <div className="pamm-image ">
                          <img
                            src={require("../../assets/images/whychoose/pamm-system.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="Fidelis-text mt-3">
                          <h5>PAMM System</h5>
                        </div>
                      </div>
                      <p>
                        This PAMM system is known for its efficiency, apart from its user- friendly experience. This PAMM system of Milliva brings the utmost comfort to traders and investors. This innovative service helps traders manage the funds of many investors.

                      </p>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="pamm d-flex">
                        <div className="market-image ">
                          <img
                            src={require("../../assets/images/whychoose/market-analysis2.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="market-text mt-3">
                          <h5>Market Analysis</h5>
                        </div>
                      </div>
                      <p>
                        With proper market analysis, trading can be profitable.
                        Our market analysis section contains predicts and
                        reviews: technical, expert outlooks, and fundamental
                        analysis. The data compiled by Milliva will keep you
                        updated. Always travel with the market trends.
                      </p>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="pamm d-flex">
                        <div className="tradecopy -image ">
                          <img
                            src={require("../../assets/images/whychoose/tradecopy.png")}
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                        <div className="tradecopy -text mt-3">
                          <h5>TradeCopy System</h5>
                        </div>
                      </div>
                      <p>
                        Make experienced successful traders for your benefit
                        with Milliva. With this TradeCopy system, you can copy
                        deals of experts and learn. It allows you to choose a
                        trader with 20+ criteria and automatically copy his
                        deals. Your deposit stays in your account, and you can
                        cancel any deal anytime.
                      </p>
                    </div>
                    <div className="fadeInUp" style={{ animationDelay: "2s" }}>
                      <div className="row">
                        <div className="col-lg-4 ">
                          <div className="pamm d-flex">
                            <div className="Contests-image ">
                              <img
                                src={require("../../assets/images/whychoose/contest2.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="Contests-text mt-3">
                              <h5>Contests</h5>
                            </div>
                          </div>
                          <p>
                            Milliva is an award-winning forex broker in terms of
                            the prize pool for its customers. We organize
                            regular contests for both beginners as well as for
                            professionals. This site section has information
                            about the contests and campaigns by the company.
                          </p>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="pamm d-flex">
                            <div className="Leverage-image ">
                              <img
                                src={require("../../assets/images/whychoose/levarage2.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="Leverage-text mt-3">
                              <h5>Leverage</h5>
                            </div>
                          </div>
                          <p>
                            Milliva provides high-leverage trading and helps
                            traders earn profit. Depending on the market
                            strategy, you can choose the most accessible and
                            comfortable leverage. So make sure you use this
                            trading mechanism to see soaring profits.{" "}
                          </p>
                        </div>
                        <div className="col-lg-4 ">
                          <div className="pamm d-flex">
                            <div className="Deposit and Withdrawal-image ">
                              <img
                                src={require("../../assets/images/whychoose/Deposit-and-Withdrawal2.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="Deposit and Withdrawal-text mt-3">
                              <h5>Deposit and Withdrawal</h5>
                            </div>
                          </div>
                          <p>
                            Milliva ensures rights conditions for deposits and
                            withdrawals and lets you enjoy a broad array of
                            options for deposits and withdrawals. We guarantee
                            transparency regardless of any payment way you
                            choose.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="support mt-3">
          <div className="container">
            <div className="row">
              <div className="multilanguage text-center mt-4">
                <h2>Why trade with us</h2>
              </div>
              <div className="customer mt-3">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4 ">
                      <div className="hovercard">
                        <div className="fidlies d-flex">
                          <div className="Fidelis-image ">
                            <img
                              src={require("../../assets/images/whychoose/fidlies.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="Fidelis-text mt-3 mx-3">
                            <h5>Why go with Milliva??</h5>
                          </div>
                        </div>
                        <p>
                          The choice is obvious. Milliva focuses on “Benefit for Traders” and offers the lowest spreads, instant execution; no requotes, and no dealing desk.

                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="hovercard">
                        <div className="fidlies d-flex">
                          <div className="Fidelis-image ">
                            <img
                              src={require("../../assets/images/whychoose/lowest-spreds.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="Fidelis-text mt-3 mx-3">
                            <h5>Lowest Spreads</h5>
                          </div>
                        </div>
                        <p>
                          Milliva offers the tightest spreads in the forex
                          Industry. With our ECN trading, enjoy 0.0 spreads on
                          EURUSD and EURGBP.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 ">
                      <div className="hovercard">
                        <div className="fidlies d-flex">
                          <div className="Fidelis-image ">
                            <img
                              src={require("../../assets/images/whychoose/lighting.png")}
                              alt="logo"
                              className="img-fluid"
                            />
                          </div>
                          <div className="Fidelis-text mt-1 mx-3">
                            <h5>Lightning Fast Execution</h5>
                          </div>
                        </div>
                        <p>
                          Our lightning-speed executions help you to have
                          successful trades every time. Superior technology
                          Milliva is determined to deliver secure, advanced, and
                          reliable technologies at your fingertips.
                        </p>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-4 ">
                        <div className="hovercard">
                          <div className="fidlies d-flex">
                            <div className="Fidelis-image ">
                              <img
                                src={require("../../assets/images/whychoose/supprior.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="Fidelis-text mt-1 mx-3">
                              <h5>Superior Technology</h5>
                            </div>
                          </div>
                          <p>
                            Milliva is committed to deliver advanced, dependable
                            and secure technologies at your fingertips.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <div className="hovercard">
                          <div className="fidlies d-flex">
                            <div className="Fidelis-image ">
                              <img
                                src={require("../../assets/images/whychoose/signal.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="Fidelis-text mt-1 mx-3">
                              <h5>Free Real Time Signals</h5>
                            </div>
                          </div>
                          <p>
                            Our real-time signals improve the quality and
                            quantity of your currency trading opportunities and
                            keep you current.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 ">
                        <div className="hovercard">
                          <div className="fidlies d-flex">
                            <div className="Fidelis-image ">
                              <img
                                src={require("../../assets/images/whychoose/un-parrelal2.png")}
                                alt="logo"
                                className="img-fluid"
                              />
                            </div>
                            <div className="Fidelis-text mt-1 mx-3">
                              <h5>Un-parallel Customer Service</h5>
                            </div>
                          </div>
                          <p>
                            We are always around the clock to answer your
                            queries related to currency trading.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="advantage mt-4">
            <div className="why-choose-first">
              <h2>Milliva Advantage</h2>
              <p>
                We are proud of our global reputation for reliability,
                dependability, customer service, and customer satisfaction. We
                constantly benchmark ourselves against the competition to ensure
                you get the best value for your business.
              </p>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="card advan1">
                    <div className="image">
                      <img
                        src={require("../../assets/images/home/13icon.png")}
                        alt="logo"
                        className="img-fluid vert-move"
                      />
                      <h2>REGULATIONS AND SECURITY OF FUNDS</h2>
                      <p>
                        Milliva Group is comprised of several entities that are
                        regulated by 11+ regulators across 5 continents. 
                      </p>
                    </div>
                    <div className="details">
                      <div className="center">
                        <ul className="desc-list">
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              22+ awards, including ‘Best Global Broker 2021
                              (Financial Magnates)’.
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Client accounts are completely segregated.
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Settlement facility for corporate clients,
                              brokers, and banks
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Within 24 hours, you can withdraw your funds
                            </span>{" "}
                          </li>
                          <li>
                            <span className="desc-list-item">
                            Milliva Group is comprised of several entities that are
                              regulated by 11+ regulators across 5 continents. 
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="card advan2">
                    <div className="image">
                      <img
                        src={require("../../assets/images/home/14icon.png")}
                        alt="logo"
                        className="img-fluid vert-move"
                      />
                      <h2>STATE-OF-THE-ART PRODUCTS</h2>
                      <p>
                        Trade over 1200+ instruments, including forex, metals, shares, indices, commodities, and cryptocurrencies

                      </p>
                    </div>

                    <div className="details">
                      <div className="center">
                        <ul className="desc-list">
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Trade over 1200+ instruments including forex,
                              metals, shares, indices, commodities &amp;
                              cryptocurrencies
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Tightest spreads starting from 0.0 pips on FX and
                              2 cents on Gold{" "}
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Up to 500:1 leverage
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Zero commission trading
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Social trading options
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Free MAM, PAMM, EA, and Copy Trading
                            </span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="card advan3">
                    <div className="image">
                      <img
                        src={require("../../assets/images/home/15icon.png")}
                        alt="logo"
                        className="img-fluid vert-move"
                      />
                      <h2>CUTTING-EDGE TRADING PLATFORMS</h2>
                      <p>
                        Instant nanosecond execution on MT5 desktop and mobile
                        platforms
                      </p>
                    </div>

                    <div className="details">
                      <div className="center">
                        <ul className="desc-list">
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Instant nanosecond execution on MT5 desktop and
                              mobile platforms
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Non-dealing desk ECN processing with direct access
                              to trade prices between more than 2 banks without
                              conflict.
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              State-of-the-art technology provides a stable
                              platform and fast processing.
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Up to 5 levels of market depth
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Negative balance protection
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              EA has no edits, rejections, or restrictions.
                            </span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="card advan4">
                    <div className="image">
                      <img
                        src={require("../../assets/images/home/16icon.png")}
                        alt="logo"
                        className="img-fluid vert-move"
                      />
                      <h2>UNPARALLELED FINANCIAL SERVICES</h2>
                      <p>Trading Credit Lines.</p>
                    </div>

                    <div className="details">
                      <div className="center">
                        <ul className="desc-list">
                          {/* <li> <span class="desc-list-item">Dedicated multilingual 24/7 customer service</span> </li> */}
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Trading Credit Lines.
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Regulated in 5+ Jurisdictions with an unblemished
                              record.
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Withdrawals within 24 hours with various payment
                              systems.
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Unparalleled franchise, partnership, and
                              introducing broker offers.
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Industry-leading 50% Deposit bonus offers.
                            </span>{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="desc-list-item">
                              Swap Free Trading{" "}
                            </span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <section className="award">
          <div className="award winning">
            <div className="container">
              <div className="award text text-center mt-4">
                <h2>Award Winning Broker</h2>
                <p>
                  We humbly thank our clients, for without their continuous
                  support and trust in us, we would not have been the award
                  winning forex broker that we are today.
                </p>
              </div>
              <div className="row">
              <div className="col-lg-3 ">
                  <div className="winningimage">
                    <img
                      src={require("../../assets/images/whychoose/award2.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-3 ">
                  <div className="winningimage">
                    <img
                      src={require("../../assets/images/whychoose/award1.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
             
                <div className="col-lg-3 ">
                  <div className="winningimage">
                    <img
                      src={require("../../assets/images/whychoose/award3.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-3 ">
                  <div className="winningimage">
                    <img
                      src={require("../../assets/images/whychoose/award4.png")}
                      alt="logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
 
  );
};

export default Whychoose;
